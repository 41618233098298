import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Upload } from '@progress/kendo-react-upload';
import { Button } from '@progress/kendo-react-buttons';
import { MultiSelect, ComboBox, DropDownList } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Input } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { filterBy } from '@progress/kendo-data-query';
import axios from 'axios';

class UploadForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: this.props.user,
            clients: [],
            selectAll: false,
            description: '',
            files: null,
            altFiles: '',
            title: '',
            expiration: '',
            clientsSelected: '',
            gridPreview: false,
            bulkPreview: '',
            bulkProcessed: '',
            bulkUnprocessed: '',
            categorySelected: "",
            catFilter: null,
            titleChanged: false,
            expChanged: false,
            platformSelected: "",
            platFilter: null,
            initialLoad: true,
            descriptionsResult: [],
            descriptionsResultOriginal: [],

            useHouseholdFolders: true,
            notifyUsers: false,

            makePrivateCheckbox: false,

            templates: [],
            tempSelected: '',
            tempDefault: { title: 'Default Template' },


            styleSettings: {},


        }

        this.onWebRepStatusChange = this.onWebRepStatusChange.bind(this);
        this.onStaticContentChange = this.onStaticContentChange.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onProgress = this.onProgress.bind(this);
        this.onStaticContentProgress = this.onStaticContentProgress.bind(this);
        this.onClientsChange = this.onClientsChange.bind(this);
        this.clearState = this.clearState.bind(this);
        this.formatList = this.formatList.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.onBulkUpload = this.onBulkUpload.bind(this);
        this.getMailTemplates = this.getMailTemplates.bind(this);
        this.getStyleSettings = this.getStyleSettings.bind(this);


    }

    getMailTemplates() {
        var defaultTemplate = this.emailTemplateDefault();
        if (this.props.type === "user" || this.props.type === "household" || this.props.type === "advisor" || this.props.type === "admin") {
            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
            axios.get("api/mailtemplate?company=" + this.props.company, { headers }).then(response => {
                //filter out the default template if it exists
                var filtered = response.data.filter(
                    function (e) {
                        return e.title !== defaultTemplate;
                    }
                );
                //this.setState({templates:response.data});
                function getDefault() {
                    if (filtered.length !== response.data.length) {
                        return defaultTemplate;
                    } if (filtered.length === 0) {
                        return "Default Template";
                    } else {
                        return filtered[0].title;
                    }
                }
                //means we filtered something
                this.setState({ tempDefault: { title: getDefault() }},() => {
                    this.setState({ 
                        tempSelected: this.state.tempDefault.title,
                        templates: filtered.filter(item => item.title !== this.state.tempDefault.title),
                    });
                })

            }).catch(err => console.log(err));
        }
    }

    onTemplateChange = (e) => {
        // if (e.target.value.title === 'Default Template') {
        //     this.setState({ tempSelected: '' });
        // }
        // else {
        this.setState({ tempSelected: e.target.value.title });
        // }
        this.forceUpdate();
    }

    componentDidMount() {
        this.setDescriptions();
        if (this.props.setCallback != null) {
            this.props.setCallback(this.setDescriptions);
        }
    }

    setDescriptions = () => {
        let descriptions = [];
        this.props.files.forEach(file => {
            if (file.items != null) {
                file.items.forEach(subfile => {
                    if (subfile.description != null && subfile.description !== '' && descriptions.findIndex(item => item === subfile.description) === -1) descriptions.push(subfile.description);
                })
            }
            if (file.description != null && file.description !== '' && descriptions.findIndex(item => item === file.description) === -1) descriptions.push(file.description);
        })
        this.setState({
            descriptionsResult: descriptions,
            descriptionsResultOriginal: descriptions
        },() => {
            this.getClients();
            this.getMailTemplates();
            this.getStyleSettings();
        })
    }

    getStyleSettings() {
        var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        var styleNames = ['RIAEmailReceiving','RIAFirmName']

        axios(`api/getStyleSettings`, {
            method: 'GET',
            params: { company: this.props.company, styleNames: styleNames },
            headers: headers
        })
            .then(response => {
                this.setState({ styleSettings: response.data.styleSettings })
            })
            .catch(err => {
                console.error(err);
            })
    }


    getClients() {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        if (this.props.uploadUser.role === 'admin') {
            axios.get("api/users?company=" + this.props.company+"&userlevel="+this.props.user.userLevel, { headers }).then(response => {
                if (response.data.code === 200) {
                    this.setState({ clients: this.formatList(response.data.users) });
                }
                else {
                    alert("An error occured while fetching the user list.");
                }
            }).catch(error => console.log(error));
        }
        else {
            axios.get("api/clients?advisor=" + this.props.user + "&company=" + this.props.company, { headers })
                .then((response) => {
                    this.setState({ clients: response.data });
                }).catch((e) => { console.log(e); });
        }
    }

    sendBulkPreview = (e) => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var payload = {
            preview: this.state.bulkPreview,
            processed: this.state.bulkProcessed,
            unprocessed: this.state.bulkUnprocessed,
            company: this.props.company
        }
        axios.post("api/sendBulkPreview", payload, { headers })
            .then((response) => {
                if (response.data.code === 200)
                    alert("Email with results has been sent.")
            }).catch((e) => { console.log(e); alert("An error occured while sending the email.") });
    }

    formatList(userList) {
        var arr = [];
        for (let i = 0; i < userList.length; i++) {
            if (userList[i].email !== this.props.uploadUser.user)
                arr.push({ client: userList[i].email });
        }
        return arr;
    }

    onFieldChange(e, field) {
        if (field === 'title') {
            this.setState({ title: e.target.value != null ? e.target.value : '', titleChanged: true });
        }
        else if (field === 'category') {
            this.setState({ categorySelected: e.target.value != null ? e.target.value : '' });
        }
        else if (field === 'platform') {
            this.setState({ platformSelected: e.target.value  != null ? e.target.value : '' });
        }
        else if (field === 'expiration') {
            this.setState({ expiration: e.target.value != null ? e.target.value : '', expChange: true });
        }
        else {
            this.setState({ description: e.target.value != null ? e.target.value : ''});
        }
        this.setState({ initialLoad: false });
    }

    onClientsChange = (e) => {
        var arr = [];

        if (Array.isArray(e.target.value)) {
            arr = e.target.value;
        }
        else {
            arr.push(e.target.value);
        }

        this.setState({ clientsSelected: arr, selectAll: arr.length === this.state.clients.length });
    }

    onCatFilterChange = (e) => {
        this.setState({ catFilter: e.filter });
    }

    onPlatFilterChange = (e) => {
        this.setState({ platFilter: e.filter });
    }

    onAdd(event, isAlt) {
        if (isAlt) {
            this.setState({
                altFiles: event.newState,
            });
        }
        else {
            this.setState({
                files: event.newState,
                bulkPreview: '',
                gridPreview: false
            });
        }
    }

    onRemove(event, isAlt) {
        if (isAlt) {
            this.setState({
                altFiles: event.newState,
            });
        }
        else {
            this.setState({
                files: event.newState,
                bulkPreview: '',
                gridPreview: false
            });
        }
    }

    onProgress(event, isAlt) {
        if (isAlt) {
            this.setState({
                altFiles: event.newState
            });
        }
        else {
            this.setState({
                files: event.newState
            });
        }
    }

    onStaticContentProgress(event, isAlt) {
        event.newState[0].progress = 100;
        event.newState[0].status = 4;
        if (isAlt) {
            this.setState({
                altFiles: event.newState
            });
        }
        else {
            this.setState({
                files: event.newState
            });
        }
    }

    onBeforeUpload = (event) => {
        event.additionalData.username = this.props.user;
        event.additionalData.folder = this.state.categorySelected.path;
        event.additionalData.company = this.props.company;
        event.additionalData.description = this.state.description;
        event.additionalData.uploadedBy = this.props.uploadUser.user;
        event.additionalData.type = this.props.type;
        event.additionalData.sharedTab = this.props.sharedTab;
        event.additionalData.useHouseholdFolders = this.state.useHouseholdFolders;
        event.additionalData.household = this.props.household;
        event.additionalData.RIAFirmName = this.state.styleSettings.RIAFirmName;

        if (this.props.type === "household" && this.state.useHouseholdFolders && this.state.categorySelected && typeof this.state.categorySelected === 'string') {
            event.additionalData.folder = 'households/' + this.props.household.toLowerCase() + "/" ;
            if (this.state.categorySelected!=="") {
                event.additionalData.folder = 'households/' + this.props.household.toLowerCase() + "/" + this.state.categorySelected + '/';
            }
            
        }

        if (this.props.type === "household")//just turn off HH for now
            //event.additionalData.notifyUsers = false;
            event.additionalData.notifyUsers = this.state.notifyUsers;
        else
            event.additionalData.notifyUsers = this.state.notifyUsers;

        if (this.props.type === "user") {
            event.additionalData.nickname = this.props.nickname;
            event.additionalData.viewingUserDocs = this.props.viewingUserDocs;

            event.additionalData.folder = this.props.user + "/";
            if( this.state.categorySelected && typeof this.state.categorySelected === 'string')
            {
                if (this.state.categorySelected!=="") {
                    event.additionalData.folder = this.props.user + "/" + (this.state.categorySelected ? this.state.categorySelected + "/" : "");
                }
            }


            event.additionalData.RIAEmailReceiving = this.state.styleSettings.RIAEmailReceiving
        }
        
        if (this.props.type === "admin") {
            event.additionalData.folder = "shared/Tab"+this.props.sharedTab+"/";

            if( this.state.categorySelected && typeof this.state.categorySelected === 'string')
            {
                if (this.state.categorySelected!=="") {
                    event.additionalData.folder = "shared/Tab"+this.props.sharedTab+"/"+(this.state.categorySelected ? this.state.categorySelected + "/" : "");
                }
            }


        }
        var clients = [];

        for (let i = 0; i < this.state.clientsSelected.length; i++) {
            clients.push(this.state.clientsSelected[i].client);
        }
        event.additionalData.sharedWith = clients;

        //add email template field
        if (this.state.tempSelected === '' && this.state.templates.length > 0) {
            event.additionalData.emailTemplate = this.emailTemplateDefault();
        }
        else {
            event.additionalData.emailTemplate = this.state.tempSelected;
        }
        if (!this.state.notifyUsers) event.additionalData.emailTemplate = '';
        

        event.additionalData.makePrivateCheckbox = this.state.makePrivateCheckbox;


    }

    onStatusChange(event) {
        if (event.response !== undefined) {
            if (event.response.response.code === "200") {
                this.props.uploadMethod();
                this.setState({ files: [], categorySelected: [] });
                alert("File(s) were successfully uploaded!");
            }
        }
        else if (event.response === undefined) {
            this.props.uploadMethod();
            this.setState({ files: [], categorySelected: [] });
            //alert("File(s) were successfully uploaded!");
        }
        else if (event.response !== undefined) {
            alert("Files could not be uploaded successfully. Please try again.")
        }
    }

    onBulkStatusChange = (e) => {
        this.setState({
            files: e.newState
        });

        if (e.response.response.code === 200) {
            this.setState({
                bulkPreview: e.response.response.files,
                bulkProcessed: e.response.response.processed,
                bulkUnprocessed: e.response.response.unprocessed
            });
        }
    }

    privateCheckboxChange = () => {
        this.setState({
            makePrivateCheckbox: !this.state.makePrivateCheckbox,
            notifyUsers: false
        });

    };


    onWebRepStatusChange(e, isAlt) {
        //on proxy error set progress to complete
        //progress is actually meaningless just there for user
        if (e.response === undefined || e.response.response === undefined) {
            e.newState[0].progress = 100;
            e.newState[0].status = 4;
            if (isAlt) {
                this.setState({
                    altFiles: e.newState
                });
            }
            else {
                this.setState({
                    files: e.newState
                });
            }
        }
        else {
            if (isAlt) {
                this.setState({
                    altFiles: e.newState
                });
            }
            else {
                this.setState({
                    files: e.newState
                });
            }
        }
    }

    onStaticContentChange(e, isAlt) {
        console.log(e)
        //on proxy error set progress to complete
        //progress is actually meaningless just there for user
        e.newState[0].progress = 100;
        e.newState[0].status = 4;
        if (isAlt) {
            this.setState({
                altFiles: e.newState
            });
        }
        else {
            this.setState({
                files: e.newState
            });
        }
        // if (e.newState && e.newState.length > 0 && e.newState[0].progress){
        //   e.newState[0].progress=100;
        //   e.newState[0].status=4;
        //   if(isAlt){
        //     this.setState({
        //       altFiles: e.newState
        //     }, console.log(this.state));
        //   }
        //   else{
        //     this.setState({
        //       files: e.newState
        //     });
        //   }
        // }
        // else{
        //   if(isAlt){
        //     this.setState({
        //       altFiles: e.newState
        //     });
        //   }
        //   else{
        //     this.setState({
        //       files: e.newState
        //     });
        //   }
        // }
    }

    onWebRepUpload(e, callback) {
        if (this.state.title === '' && !this.state.titleChanged)
            alert("Please enter a title.");
        else if (this.state.expiration === '' && !this.state.expChanged)
            alert("Please select an expiration date.");
        else if ((this.state.files === undefined || this.state.files === '') && !this.props.repEdit)
            alert('Please select a report file to upload.');
        else {
            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
            var formData = new FormData();

            formData.append("company", this.props.company);
            formData.append("type", this.props.type);
            formData.append("title", this.state.title);
            if (this.state.categorySelected !== null)
                formData.append('folder', this.state.categorySelected.text);
            else
                formData.append('folder', '');
            formData.append('description', this.state.description);
            formData.append('expiration', this.state.expiration);
            if (this.state.platformSelected !== null)
                formData.append('platform', this.state.platformSelected.text);
            else
                formData.append('platform', '');

            if (this.props.repEdit) {
                formData.append('repEdit', JSON.stringify(this.props.repEdit));
            }

            if (this.state.files !== undefined && this.state.files.length > 0) {
                var rawRep = this.state.files[0].getRawFile();
                formData.append("report", rawRep);
            }
            if (this.state.altFiles !== undefined && this.state.altFiles.length > 0) {
                var rawSample = this.state.altFiles[0].getRawFile();
                formData.append("sample", rawSample);
            }

            axios
                .post("api/webrep?company="+this.props.company, formData, { headers })
                .then((response) => {
                    if (response.status === 200) {
                        this.props.uploadMethod();
                        alert("Report was successfully uploaded.");
                        if (callback) {
                            callback();
                        }
                    }
                    else {
                        alert("Error: Report could not be uploaded.");
                    }
                })
                .catch((e) => {
                    alert("Error: Report could not be uploaded.");
                    console.log(e);
                });
        }
    }


    onStaticContentUpload(e, callback) {

        if (this.state.files === undefined || (this.state.files === '' && !this.props.repEdit))
            alert('Please select a file to upload.');
        else {
            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

            var formData = new FormData();

            formData.append("company", this.props.company);
            formData.append("type", this.props.type);
            formData.append('title', this.state.title);
            formData.append('description', this.state.description);

            if (this.state.files !== undefined && this.state.files.length > 0) {
                var rawContent = this.state.files[0].getRawFile();
                formData.append("content", rawContent);
            }


            if (this.props.repEdit) {



                axios(`api/pdfTermsCheckIfTitleExists`, {
                    method: 'GET',
                    params: { company: this.props.user.company, newTitle: this.state.title, originalTitle: this.props.repEdit.title },
                    headers: headers,
                })
                    .then(response => {
                        if (response.data.code === 200) {
                            if (response.data.data.length === 0) {

                                //editing file
                                if (this.state.files !== null && this.state.files !== undefined && this.state.files.length > 0) {
                                    formData.append("fileEdit", true);
                                    formData.append("originalTitle", this.props.repEdit.title);
                                    formData.append("action", "Upload PDF Terms");

                                    axios
                                        .post("api/pdfTerms?company="+this.props.user.company, formData, { headers })
                                        .then((response2) => {
                                            if (response2.status === 200) {
                                                this.props.uploadMethod();
                                                alert("Saved successfully. Any references to the file have been updated.");
                                                if (callback) {
                                                    callback();
                                                }
                                            }
                                            else {
                                                alert("Error: File could not be uploaded.");
                                            }
                                        })
                                        .catch((e) => {
                                            alert("Error: File could not be uploaded.");
                                            console.log(e);
                                        });
                                }
                                //not editing file
                                else {
                                    var payload = {
                                        company: this.props.company,
                                        type: this.props.type,
                                        title: this.state.title,
                                        description: this.state.description,
                                        originalTitle: this.props.repEdit.title,
                                        contentName: this.props.repEdit.contentName,
                                        contentPath: this.props.repEdit.contentPath,
                                        action: "Update PDF Terms"
                                    }
                                    axios
                                        .post("api/pdfTermsEdit?company="+this.props.company, payload, { headers })
                                        .then((response2) => {
                                            if (response2.status === 200) {
                                                this.props.uploadMethod();
                                                alert("Saved successfully. Any references to the file have been updated.");
                                                if (callback) {
                                                    callback();
                                                }
                                            }
                                            else {
                                                alert("Error: Details could not be saved.");
                                            }
                                        })
                                        .catch((e) => {
                                            alert("Error: Details could not be saved.");
                                            console.log(e);
                                        });
                                }
                            }
                            else {
                                alert("Another file with that title already exists");
                            }
                        }
                        else {
                            alert("asdError: Details could not be saved.");
                        }
                    })
                    .catch(err => {
                        alert("Error: Details could not be saved.");
                        console.log(err);
                    })
            
            
            }
            else {
                axios(`api/reportStaticContentCheckIfTitleExists`, {
                    method: 'GET',
                    params: { company: this.props.user.company, newTitle: this.state.title, originalTitle: '' },
                    headers: headers,
                })
                    .then(response => {
                        if (response.data.code === 200) {
                            if (response.data.data.length === 0) {
                                axios
                                    .post("api/reportStaticContent?company="+this.props.user.company, formData, { headers })
                                    .then((response2) => {
                                        if (response2.status === 200) {
                                            this.props.uploadMethod();
                                            alert("File was successfully uploaded.");
                                            if (callback) {
                                                callback();
                                            }
                                        }
                                        else {
                                            alert("Error: File could not be uploaded.");
                                        }
                                    })
                                    .catch((e) => {
                                        alert("Error: File could not be uploaded.");
                                        console.log(e);
                                    });
                            }
                            else {
                                alert("Another file with that title already exists");
                            }
                        }
                        else {
                            alert("Error: File could not be uploaded.");
                        }
                    })
                    .catch((e) => {
                        alert("Error: File could not be uploaded.");
                        console.log(e);
                    });
            }
        }
    }

    onPdfTermsUpload(e, callback) {

        if (this.state.files === undefined || (this.state.files === '' && !this.props.repEdit))
            alert('Please select a file to upload.');
        else {
            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

            var formData = new FormData();

            formData.append("company", this.props.company);
            formData.append("type", this.props.type);
            formData.append('title', this.state.title);
            formData.append('description', this.state.description);

            if (this.state.files !== null && this.state.files !== undefined && this.state.files.length > 0) {
                var rawContent = this.state.files[0].getRawFile();
                formData.append("content", rawContent);
                formData.append("contentName", this.state.files[0].name);
            }



            if (this.props.repEdit) {



                axios(`api/pdfTermsCheckIfTitleExists`, {
                    method: 'GET',
                    params: { company: this.props.user.company, newTitle: this.state.title, originalTitle: this.props.repEdit.title },
                    headers: headers,
                })
                    .then(response => {
                        if (response.data.code === 200) {
                            if (response.data.data.length === 0) {

                                //editing file
                                if (this.state.files !== null && this.state.files !== undefined && this.state.files.length > 0) {
                                    formData.append("fileEdit", true);
                                    formData.append("originalTitle", this.props.repEdit.title);
                                    formData.append("contentName", this.state.files[0].name);
                                    formData.append("action", "Update PDF Terms");

                                    axios
                                        .post("api/pdfTerms?company="+this.props.user.company, formData, { headers })
                                        .then((response2) => {
                                            if (response2.status === 200) {
                                                this.props.uploadMethod();
                                                alert("Saved successfully. Any references to the file have been updated.");
                                                if (callback) {
                                                    callback();
                                                }
                                            }
                                            else {
                                                alert("Error: File could not be uploaded.");
                                            }
                                        })
                                        .catch((e) => {
                                            alert("Error: File could not be uploaded.");
                                            console.log(e);
                                        });
                                }
                                //not editing file
                                else {
                                    var payload = {
                                        company: this.props.company,
                                        type: this.props.type,
                                        title: this.state.title,
                                        description: this.state.description,
                                        originalTitle: this.props.repEdit.title,
                                        contentName: this.props.repEdit.contentName,
                                        contentPath: this.props.repEdit.contentPath,
                                        action: "Update PDF Terms"
                                    }
                                    axios
                                        .post("api/pdfTermsEdit?company="+this.props.company, payload, { headers })
                                        .then((response2) => {
                                            if (response2.status === 200) {
                                                this.props.uploadMethod();
                                                alert("Saved successfully. Any references to the file have been updated.");
                                                if (callback) {
                                                    callback();
                                                }
                                            }
                                            else {
                                                alert("Error: Details could not be saved.");
                                            }
                                        })
                                        .catch((e) => {
                                            alert("Error: Details could not be saved.");
                                            console.log(e);
                                        });
                                }
                            }
                            else {
                                alert("Another file with that title already exists");
                            }
                        }
                        else {
                            alert("asdError: Details could not be saved.");
                        }

                    })
                    .catch(err => {
                        alert("Error: Details could not be saved.");
                        console.log(err);
                    })


            }
            else {
                axios(`api/pdfTermsCheckIfTitleExists`, {
                    method: 'GET',
                    params: { company: this.props.user.company, newTitle: this.state.title, originalTitle: '' },
                    headers: headers,
                })
                    .then(response => {
                        if (response.data.code === 200) {
                            if (response.data.data.length === 0) {
                                axios
                                    .post("api/pdfTerms?company="+this.props.user.company, formData, { headers })
                                    .then((response2) => {
                                        if (response2.status === 200) {
                                            this.props.uploadMethod();
                                            alert("File was successfully uploaded.");
                                            if (callback) {
                                                callback();
                                            }
                                        }
                                        else {
                                            alert("Error: File could not be uploaded.");
                                        }
                                    })
                                    .catch((e) => {
                                        alert("Error: File could not be uploaded.");
                                        console.log(e);
                                    });
                            }
                            else {
                                alert("Another file with that title already exists");
                            }
                        }
                        else {
                            alert("Error: File could not be uploaded.");
                        }
                    })
                    .catch((e) => {
                        alert("Error: File could not be uploaded.");
                        console.log(e);
                    });
            }
        }
    }

    onBulkUpload(e, callback) {

        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var formData = new FormData();

        formData.append("company", this.props.company);
        formData.append("type", this.props.type);
        formData.append("useHouseholdFolders", this.state.useHouseholdFolders);
        formData.append("notifyUsers", this.state.notifyUsers);

        if (this.state.categorySelected.path)
            formData.append("folder", this.state.categorySelected.path);

        formData.append("description", this.state.description);

        for (let i = 0; i < this.state.files.length; i++) {
            formData.append("files", this.state.files[i].getRawFile());
        }

        axios
            .post("api/bulkupload", formData, { headers })
            .then((response) => {
                if (response.data.code === 200) {
                    alert("All mapped files were uploaded successfully.");
                }
                else {
                    alert("Error: one or more of the mapped files did not upload successfully.");
                }
                //this.props.uploadMethod();
                callback();

            })
            .catch((e) => {
                alert("Error: Some files may not have been uploaded.");
                //this.props.uploadMethod();
                callback();

            });
    }

    onClientSelectAllChange = (e) => {
        var clientSlice = this.state.clients.slice();
        if (this.state.selectAll) {
            this.setState({ selectAll: false, clientsSelected: [] });
        }
        else {
            this.setState({ selectAll: true, clientsSelected: clientSlice });
        }
    }

    clearState = (e) => {
        this.setState({
            title: '',
            altFiles: '',
            expiration: '',
            description: '',
            files: null,
            clientsSelected: '',
            selectAll: false,
            gridPreview: false,
            bulkPreview: '',
            bulkProcessed: '',
            bulkUnprocessed: '',
            bulkFolder: '',
            bulkDesc: '',
            categorySelected: "",
            platformSelected: "",
            useHouseholdFolders: true,
            notifyUsers: false,
            initialLoad: true,
            tempSelected: '',
            tempDefault: { title: 'Default Template' },
            makePrivateCheckbox: false,
        });
    }

    getEditField(field) {
        var retVal = '';

        if (this.props.repEdit && this.props.repEdit[field]) {
            if (field === 'category') {
                if (this.state.categorySelected === "") {
                    this.setState({ categorySelected: { text: this.props.repEdit[field], path: this.props.repEdit[field] } });
                }
                retVal = { text: this.props.repEdit[field], path: this.props.repEdit[field] };
            }
            else if (field === 'platform') {
                if (this.state.platformSelected === "" || this.state.platformSelected === "undefined") {
                    this.setState({ platformSelected: { text: this.props.repEdit[field] } });
                }
                retVal = { text: this.props.repEdit[field] };
            }
            else if (field === 'reportName' || field === 'sampleName' || field === 'contentName') {
                retVal = [{ name: this.props.repEdit[field] }];
            }
            else if (this.state.initialLoad) {
                this.setState({ [field]: this.props.repEdit[field] });
                retVal = this.props.repEdit[field];
            }
        }
        else {
            retVal = '';
        }

        return retVal;
    }

    removeDupCategories() {
        var dedupedCats = this.props.folders.slice();
        if (this.props.repEdit && this.props.repEdit.category) {

            var checkText = this.props.repEdit.category;

            var catInd = dedupedCats.findIndex(cat => {
                return cat.text === checkText;
            });

            if (catInd !== -1) {
                dedupedCats.splice(catInd, 1);
            }
            return dedupedCats;
        }
        else {
            return this.props.folders;
        }
    }

    removeDupPlatforms() {
        var dedupedPlats = this.props.platforms.slice();
        if (this.props.repEdit && this.props.repEdit.platform) {

            var checkText = this.props.repEdit.platform;

            var platInd = dedupedPlats.findIndex(plat => {
                return plat.text === checkText;
            });

            if (platInd !== -1) {
                dedupedPlats.splice(platInd, 1);
            }
            return dedupedPlats;
        }
        else {
            return this.props.platforms;
        }
    }

    getEditFiles() {
        if (this.props.repEdit) {
            return ([{ name: this.props.repEdit.reportName }]);
        }
        else {
            return '';
        }
    }

    getEditAltFiles() {
        if (this.props.repEdit) {
            return ([{ name: this.props.repEdit.sampleName }]);
        }
        else {
            return '';
        }
    }

    bulkUserRow(trElement, props) {
        const valid = props.dataItem.user.indexOf("ERROR:") < 0;
        const green = { backgroundColor: "rgb(55, 180, 0,0.32)" };
        const red = { backgroundColor: "rgb(243, 23, 0, 0.32)" };
        const trProps = { style: valid ? green : red };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    }

    onClick_UseHH = () => {
        this.setState({ useHouseholdFolders: !this.state.useHouseholdFolders });
    };
    onClick_Notify = () => {
        this.setState({ notifyUsers: !this.state.notifyUsers });
    };

    emailTemplateDefault() {
        switch (this.props.type) {
            case 'user':
                return 'Document Notification';
            case 'household':
                return 'Document Notification';
            case 'admin':
                return 'Shared Document Notification';
            case 'advisor':
                return 'Advisor Document Notification';
            default:
                return 'Default Template';
        }
    }

    render() {

        const stylePrivateToolTipCells = {
            paddingTop: "0px",
            paddingBottom: "0px",
            fontSize: "10px",
        };
        const stylePrivateCheckHead = {
            paddingBottom: "1px",
        };
        const styleNormalPadding = {
            paddingBottom: "5px",
        };

        if (this.props.type === "advisor") {
            return (
                <span className='uploadForm'>
                    <Popup closeOnDocumentClick={false} onClose={this.clearState} trigger={this.props.button} overlayStyle={{ zIndex: 5 }} modal>
                        {close => (
                            <div className="modal">
                                <Button className="close" icon="close" onClick={(e) => { close(); this.clearState(); }} />


                                <div className="header" align="left">
                                    Upload Document - {this.props.title}
                                </div>

                                <div className="content">

                                    <table style={{ width: '100%' }}>
                                        <colgroup>
                                            <col span="1" style={{ width: '30%' }} />
                                            <col span="1" style={{ width: '70%' }} />
                                        </colgroup>

                                        <tbody>

                                            {/* <tr>
                                                <td><strong>Folder:</strong></td>
                                                <td style={styleNormalPadding}>
                                                    <DropDownList
                                                        style={{ width: '400px' }}
                                                        data={[''].concat(this.props.folders.map(folder => {
                                                            return String(folder.text).endsWith('/') ? String(folder.text).slice(0, -1) : folder.text
                                                        }))}
                                                        onChange={(event) => this.onFieldChange(event, 'category')}
                                                        value={this.state.categorySelected}
                                                        // filterable={true}
                                                        // allowCustom={true}
                                                    // onFilterChange={(e) => {
                                                    //     this.setState({ vaultFolderListResult: filterBy(this.props.vaultFolderList, e.filter) });
                                                    // }}
                                                    />
                                                </td>
                                            </tr> */}
                                            <tr>
                                                <td><strong>Description:</strong></td>
                                                <td style={styleNormalPadding}>
                                                    {/* <input style={styleInputBox} type="text" name="Description" placeholder="Enter a description" onBlur={(e)=>this.onFieldChange(e, 'description')}/> */}
                                                    {this.state.descriptionsResultOriginal.length > 0 && 1 === 0 ? 
                                                    <ComboBox
                                                        style={{ width: '400px' }}
                                                        data={this.state.descriptionsResult || this.props.descriptions}//uses filtered list if exists, otherwise uses original props
                                                        onChange={(e) => this.onFieldChange(e, 'description')}
                                                        value={this.state.description}
                                                        filterable={true}
                                                        listNoDataRender={() => {return <></>}}
                                                        allowCustom={true}
                                                        onFilterChange={(e) => {
                                                            this.setState({ descriptionsResult: filterBy(this.state.descriptionsResultOriginal, e.filter) });
                                                        }}
                                                    />
                                                    :
                                                    <Input
                                                        style={{ width: '400px' }}
                                                        onChange={(e) => this.onFieldChange(e, 'description')}
                                                        value={this.state.description}
                                                    />}
                                                </td>
                                            </tr>

                                            <tr><td><strong>Notify Users Via Email:</strong></td>
                                                <td style={styleNormalPadding}><input type="checkbox" checked={this.state.notifyUsers} onChange={this.onClick_Notify} /></td>
                                            </tr>

                                            {this.state.notifyUsers &&
                                                <>
                                                    <td><strong>Notify Email Template:</strong></td>
                                                    <td style={styleNormalPadding}>
                                                        {this.state.templates.length > 0 ? <DropDownList
                                                            style={{ width: '400px' }}
                                                            data={this.state.templates}
                                                            textField='title'
                                                            defaultItem={{ title: this.state.tempDefault.title }}
                                                            onChange={this.onTemplateChange}
                                                            popupSettings={{ className: "top", }} /> : this.state.tempDefault.title}
                                                    </td>

                                                </>

                                            }

                                        </tbody>
                                    </table>


                                    <Upload batch={true}
                                        autoUpload={false}
                                        multiple={true}
                                        files={this.state.files}
                                        onAdd={(e) => this.onAdd(e)}
                                        onRemove={(e) => this.onRemove(e)}
                                        onBeforeUpload={this.onBeforeUpload}
                                        onStatusChange={(event) => {
                                            var msg = false; if (event.response !== undefined && event.response.response.code === 200) { close(); this.props.uploadMethod(); this.setState({ files: [], categorySelected: [], clientsSelected: [] }); if (!msg) { alert("File(s) were successfully uploaded!"); msg = true; }; }
                                            else if (event.response !== undefined && event.response.response.code === 401) { alert("Please select clients to share with."); }
                                            else if (event.response !== undefined) { alert("Files could not be uploaded successfully. Please try again."); }
                                        }}

                                        saveUrl={"api/sharedUpload?company="+this.props.company}
                                        saveHeaders={{ 'authorization': 'Bearer '.concat(this.props.idToken) }}
                                    />
                                    <div><label>List of Clients:</label></div>
                                    <div className='indent'><input type='checkbox' checked={this.state.selectAll} onChange={this.onClientSelectAllChange} /><label>Select All</label></div>
                                    <MultiSelect
                                        data={this.state.clients}
                                        //   disabled={this.state.selectAll}
                                        textField="client"
                                        value={this.state.clientsSelected}
                                        onChange={this.onClientsChange}
                                    />
                                </div>

                            </div>
                        )}
                    </Popup>
                </span>
            );

        }
        else if (this.props.type === "household") {
            return (
                <span className='uploadForm'>
                    <Popup closeOnDocumentClick={false} onClose={this.clearState} trigger={this.props.button} overlayStyle={{ zIndex: 5 }} modal>
                        {close => (
                            <div className="modal">
                                <Button className="close" icon="close" onClick={(e) => { close(); this.clearState(); }} />


                                <div className="header" align="left">
                                    Upload Document - {this.props.title}
                                </div>

                                <div className="content">

                                    <table style={{ width: '100%' }}>

                                        <colgroup>
                                            <col span="1" style={{ width: '30%' }} />
                                            <col span="1" style={{ width: '70%' }} />
                                        </colgroup>

                                        <tbody>

                                            <tr>
                                                <td><strong>Folder:</strong></td>
                                                <td style={styleNormalPadding} >
                                                    {this.props.vaultFolderList.length > 0 ?
                                                    <ComboBox
                                                        style={{ width: '400px' }}
                                                        data={(this.state.vaultFolderListResult || this.props.vaultFolderList).map(folder => String(folder).endsWith('/') ? (String(folder).slice(0, -1)) : folder)}//uses filtered list if exists, otherwise uses original props
                                                        onChange={(event) => this.onFieldChange(event, 'category')}
                                                        value={this.state.categorySelected}
                                                        filterable={true}
                                                        listNoDataRender={() => {return <></>}}
                                                        allowCustom={true}
                                                        onFilterChange={(e) => {
                                                            this.setState({ vaultFolderListResult: filterBy((this.props.vaultFolderList).map(folder => String(folder).endsWith('/') ? (String(folder).slice(0, -1)) : folder), e.filter) });
                                                        }}
                                                    />
                                                    :
                                                    <Input
                                                        style={{ width: '400px' }}
                                                        onChange={(e) => this.onFieldChange(e, 'category')}
                                                        value={this.state.categorySelected}
                                                    />
                                                    }
                                                </td>

                                            </tr>

                                            <tr>
                                                <td><strong>Description:</strong></td>
                                                <td>
                                                    {this.state.descriptionsResultOriginal.length > 0 ? 
                                                    <ComboBox
                                                        style={{ width: '400px' }}
                                                        data={this.state.descriptionsResult || this.props.descriptions}//uses filtered list if exists, otherwise uses original props
                                                        onChange={(e) => this.onFieldChange(e, 'description')}
                                                        value={this.state.description}
                                                        filterable={true}
                                                        listNoDataRender={() => {return <></>}}
                                                        allowCustom={true}
                                                        onFilterChange={(e) => {
                                                            this.setState({ descriptionsResult: filterBy(this.state.descriptionsResultOriginal, e.filter) });
                                                        }}
                                                    />
                                                    :
                                                    <Input
                                                        style={{ width: '400px' }}
                                                        onChange={(e) => this.onFieldChange(e, 'description')}
                                                        value={this.state.description}
                                                    />}
                                                </td>

                                            </tr>




                                            {this.props.uploadUser.role !== 'user' &&
                                                <>
                                                    <br />
                                                    <tr>
                                                        <td style={stylePrivateCheckHead}><strong>Mark Documents as Private:</strong></td>
                                                        <td style={stylePrivateCheckHead}>
                                                            <input type="checkbox" checked={this.state.makePrivateCheckbox} onChange={this.privateCheckboxChange} label={'Yes'} />
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td style={stylePrivateToolTipCells}>(Users will NOT see these documents;</td>
                                                        <td style={stylePrivateToolTipCells}></td>
                                                    </tr>

                                                    <tr>
                                                        <td style={stylePrivateToolTipCells}>only visible to Admins and Advisors)</td>
                                                        <td style={stylePrivateToolTipCells}></td>
                                                    </tr>




                                                </>
                                            }

                                            {(this.props.uploadUser.role !== 'user' && !this.state.makePrivateCheckbox) &&
                                                <>

                                                    <tr><td><strong>Notify Users Via Email:</strong></td>
                                                        <td style={styleNormalPadding}><input type="checkbox" checked={this.state.notifyUsers} onChange={this.onClick_Notify} /></td>
                                                    </tr>

                                                </>
                                            }


                                            {this.state.notifyUsers&& this.props.uploadUser.role !== 'user' &&
                                                <>
                                                    <td><strong>Notify Email Template:</strong></td>
                                                    <td style={styleNormalPadding}>
                                                        {this.state.templates.length > 0 ? <DropDownList
                                                                style={{ width: '400px' }}
                                                                data={this.state.templates}
                                                                textField='title'
                                                                defaultItem={{ title: this.state.tempDefault.title }}
                                                                onChange={this.onTemplateChange}
                                                                popupSettings={{ className: "top", }} /> : this.state.tempDefault.title}
                                                    </td>

                                                </>

                                            }

                                        </tbody>
                                    </table>

                                    <Upload batch={true}
                                        autoUpload={false}
                                        multiple={true}
                                        files={this.state.files}
                                        onAdd={(e) => this.onAdd(e)}
                                        onRemove={(e) => this.onRemove(e)}
                                        onBeforeUpload={this.onBeforeUpload}
                                        onStatusChange={(e) => { this.onStatusChange(e); close(); }}
                                        saveUrl={"api/fileupload?company="+this.props.company}
                                        saveHeaders={{ 'authorization': 'Bearer '.concat(this.props.idToken) }}
                                    />




                                </div>
                            </div>
                        )}
                    </Popup>
                </span>
            );
        }
        else if (this.props.type === "user") {
            return (
                <span className='uploadForm'>
                    <Popup closeOnDocumentClick={false} onClose={this.clearState} trigger={this.props.button} overlayStyle={{ zIndex: 5 }} modal>
                        {close => (
                            <div className="modal">
                                <Button className="close" icon="close" onClick={(e) => { close(); this.clearState(); }} />


                                <div className="header" align="left">
                                    Upload Document - {this.props.title}
                                </div>

                                <div className="content">

                                    <table style={{ width: '100%' }}>

                                        <colgroup>
                                            <col span="1" style={{ width: '30%' }} />
                                            <col span="1" style={{ width: '70%' }} />
                                        </colgroup>

                                        <tbody>

                                            <tr><td><strong>Folder:</strong></td>
                                                <td>
                                                    {/* <input type='text' placeholder="Enter a Folder Name" value={this.state.categorySelected} onChange={(e)=>{this.setState({categorySelected:e.target.value})}}/> */}

                                                {this.props.folders.length > 0 ?
                                                    <ComboBox
                                                        style={{ width: '400px' }}
                                                        data={this.state.vaultFolderListResult || this.props.folders.map(folder => {
                                                            return String(folder.text).endsWith('/') ? String(folder.text).slice(0, -1) : folder.text
                                                        })}
                                                        onChange={(event) => this.onFieldChange(event, 'category')}
                                                        value={this.state.categorySelected}
                                                        filterable={true}
                                                        listNoDataRender={() => {return <></>}}
                                                        allowCustom={true}
                                                        onFilterChange={(e) => {
                                                            this.setState({ vaultFolderListResult: filterBy(this.props.folders.map(folder => {
                                                                return String(folder.text).endsWith('/') ? String(folder.text).slice(0, -1) : folder.text
                                                            }), e.filter) });
                                                        }}
                                                    />
                                                    :
                                                    <Input
                                                        style={{ width: '400px' }}
                                                        onChange={(e) => this.onFieldChange(e, 'category')}
                                                        value={this.state.categorySelected}
                                                    />
                                                    }


                                                </td>
                                            </tr>

                                            <tr><td><strong>Description:</strong></td>
                                                <td>
                                                    {/* <input type='text'value={this.state.description} placeholder="Enter a description" onChange={(e)=>{this.setState({description:e.target.value})}}/> */}
                                                    {this.state.descriptionsResultOriginal.length > 0 ? 
                                                    <ComboBox
                                                        style={{ width: '400px' }}
                                                        data={this.state.descriptionsResult || this.props.descriptions}//uses filtered list if exists, otherwise uses original props
                                                        onChange={(e) => this.onFieldChange(e, 'description')}
                                                        value={this.state.description}
                                                        filterable={true}
                                                        listNoDataRender={() => {return <></>}}
                                                        allowCustom={true}
                                                        onFilterChange={(e) => {
                                                            this.setState({ descriptionsResult: filterBy(this.state.descriptionsResultOriginal, e.filter) });
                                                        }}
                                                    />
                                                    :
                                                    <Input
                                                        style={{ width: '400px' }}
                                                        onChange={(e) => this.onFieldChange(e, 'description')}
                                                        value={this.state.description}
                                                    />}
                                                </td>
                                            </tr>




                                            {this.props.uploadUser.role !== 'user' &&
                                                <>
                                                    <br />
                                                    <tr>
                                                        <td style={stylePrivateCheckHead}><strong>Mark Documents as Private:</strong></td>
                                                        <td style={stylePrivateCheckHead}>
                                                            <input type="checkbox" checked={this.state.makePrivateCheckbox} onChange={this.privateCheckboxChange} label={'Yes'} />
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td style={stylePrivateToolTipCells}>(Users will NOT see these documents;</td>
                                                        <td style={stylePrivateToolTipCells}></td>
                                                    </tr>

                                                    <tr>
                                                        <td style={stylePrivateToolTipCells}>only visible to Admins and Advisors)</td>
                                                        <td style={stylePrivateToolTipCells}></td>
                                                    </tr>




                                                </>
                                            }

                                            {(this.props.uploadUser.role !== 'user' && !this.state.makePrivateCheckbox) &&
                                                <>

                                                    <tr><td><strong>Notify Users Via Email:</strong></td>
                                                        <td style={styleNormalPadding}><input type="checkbox" checked={this.state.notifyUsers} onChange={this.onClick_Notify} /></td>
                                                    </tr>

                                                </>
                                            }



                                            {this.state.notifyUsers && this.props.uploadUser.role !== 'user' &&
                                                <>

                                                    <tr>
                                                        <td><strong>Notify Email Template:</strong></td>
                                                        <td style={styleNormalPadding}>
                                                            { this.state.templates.length > 0 ? 
                                                            <DropDownList
                                                                style={{ width: '400px' }}
                                                                data={this.state.templates}
                                                                textField='title'
                                                                defaultItem={{ title: this.state.tempDefault.title }}
                                                                onChange={this.onTemplateChange}
                                                                popupSettings={{ className: "top", }} /> : this.state.tempDefault.title}
                                                        </td>
                                                    </tr>
                                                </>

                                            }



                                        </tbody>
                                    </table>

                                    <Upload batch={true}
                                        autoUpload={false}
                                        multiple={true}
                                        files={this.state.files}
                                        onAdd={(e) => this.onAdd(e)}
                                        onRemove={(e) => this.onRemove(e)}
                                        onBeforeUpload={this.onBeforeUpload}
                                        onStatusChange={(e) => { this.onStatusChange(e); close(); }}
                                        saveUrl={"api/fileupload?company="+this.props.company}
                                        saveHeaders={{ 'authorization': 'Bearer '.concat(this.props.idToken) }}
                                    />




                                </div>
                            </div>
                        )}
                    </Popup>
                </span>
            );
        }

        else if (this.props.type === "admin") {
            return (
                <span className='uploadForm'>
                    <Popup closeOnDocumentClick={false} onClose={this.clearState} trigger={this.props.button} overlayStyle={{ zIndex: 5 }} modal>
                        {close => (
                            <div className="modal">
                                <Button className="close" icon="close" onClick={(e) => { close(); this.clearState(); }} />


                                <div className="header" align="left">
                                    Upload Document - {this.props.title}
                                </div>

                                <div className="content">


                                    <table style={{ width: '100%' }}>

                                        <colgroup>
                                            <col span="1" style={{ width: '30%' }} />
                                            <col span="1" style={{ width: '70%' }} />
                                        </colgroup>

                                        <tbody>

                                            <tr>
                                                <td><strong>Folder:</strong></td>
                                                <td style={styleNormalPadding}>
                                                    {this.props.folders.length > 0 ?
                                                    <ComboBox
                                                        style={{ width: '400px' }}
                                                        data={this.state.vaultFolderListResult || this.props.folders.map(folder => {
                                                            return String(folder.text).endsWith('/') ? String(folder.text).slice(0, -1) : folder.text
                                                        })}
                                                        onChange={(event) => this.onFieldChange(event, 'category')}
                                                        value={this.state.categorySelected}
                                                        filterable={true}
                                                        listNoDataRender={() => {return <></>}}
                                                        allowCustom={true}
                                                        onFilterChange={(e) => {
                                                            this.setState({ vaultFolderListResult: filterBy(this.props.folders.map(folder => {
                                                                return String(folder.text).endsWith('/') ? String(folder.text).slice(0, -1) : folder.text
                                                            }), e.filter) });
                                                        }}
                                                    />
                                                    :
                                                    <Input
                                                        style={{ width: '400px' }}
                                                        onChange={(e) => this.onFieldChange(e, 'category')}
                                                        value={this.state.categorySelected}
                                                    />
                                                    }   
                                                </td>
                                            </tr>

                                            <tr>
                                                <td><strong>Description:</strong></td>
                                                <td style={styleNormalPadding}>
                                                    {/* <input type="text" name="Description" placeholder="Enter a description" onBlur={(e)=>this.onFieldChange(e, 'description')}/> */}
                                                    {this.state.descriptionsResultOriginal.length > 0 ? 
                                                    <ComboBox
                                                        style={{ width: '400px' }}
                                                        data={this.state.descriptionsResult || this.props.descriptions}//uses filtered list if exists, otherwise uses original props
                                                        onChange={(e) => this.onFieldChange(e, 'description')}
                                                        value={this.state.description}
                                                        filterable={this.state.descriptionsResultOriginal.length > 0}
                                                        listNoDataRender={() => {return <></>}}
                                                        allowCustom={true}
                                                        onFilterChange={(e) => {
                                                            this.setState({ descriptionsResult: filterBy(this.state.descriptionsResultOriginal, e.filter) });
                                                        }}
                                                    />
                                                    :
                                                    <Input
                                                        style={{ width: '400px' }}
                                                        onChange={(e) => this.onFieldChange(e, 'description')}
                                                        value={this.state.description}
                                                    />}
                                                </td>
                                            </tr>



                                            <tr>
                                                <td style={stylePrivateCheckHead}><strong>Mark Documents as Private:</strong></td>
                                                <td style={stylePrivateCheckHead}>
                                                    <input type="checkbox" checked={this.state.makePrivateCheckbox} onChange={this.privateCheckboxChange} label={'Yes'} />
                                                </td>

                                            </tr>
                                            <tr>
                                                <td style={stylePrivateToolTipCells}>(Users will NOT see these documents;</td>
                                                <td style={stylePrivateToolTipCells}></td>
                                            </tr>
                                            <tr>
                                                <td style={stylePrivateToolTipCells}>only visible to Admins and Advisors)</td>
                                                <td style={stylePrivateToolTipCells}></td>
                                            </tr>




                                            {!this.state.makePrivateCheckbox &&
                                                <>
                                                    <tr>
                                                        <td><strong>Notify Users Via Email:</strong></td>
                                                        <td style={styleNormalPadding}><input type="checkbox" checked={this.state.notifyUsers} onChange={this.onClick_Notify} /></td>
                                                    </tr>

                                                </>

                                            }
                                            {this.state.notifyUsers &&
                                                <>
                                                    <tr>
                                                        <td><strong>Notify Email Template:</strong></td>
                                                        <td style={styleNormalPadding}>
                                                            {this.state.templates.length > 0 ? 
                                                            <DropDownList
                                                            style={{ width: '400px' }}
                                                            data={this.state.templates}
                                                            textField='title'
                                                            defaultItem={{ title: this.state.tempDefault.title }}
                                                            onChange={this.onTemplateChange}
                                                            popupSettings={{ className: "top", }} /> : this.state.tempDefault.title}
                                                            </td>

                                                    </tr>
                                                </>}







                                        </tbody>
                                    </table>



                                    <Upload batch={true}
                                        autoUpload={false}
                                        multiple={true}
                                        files={this.state.files}
                                        onAdd={(e) => this.onAdd(e)}
                                        onRemove={(e) => this.onRemove(e)}
                                        onBeforeUpload={(e) => {
                                            if (!window.confirm("You are uploading to a shared document area, which is accessible by multiple users. Press OK to confirm.")) {
                                                return;
                                            }
                                            this.onBeforeUpload(e);
                                        }}

                                        onStatusChange={(event) => {
                                            var msg = false; if (event.response !== undefined && event.response.response.code === "200") { close(); this.props.uploadMethod(); this.setState({ files: [], categorySelected: [] }); if (!msg) { alert("File(s) were successfully uploaded!"); msg = true; }; }
                                            else if (event.response !== undefined) { alert("Files could not be uploaded successfully. Please try again."); }
                                        }}
                                        saveUrl={"api/fileuploadPublic?company="+this.props.company}
                                        saveHeaders={{ 'authorization': 'Bearer '.concat(this.props.idToken) }}
                                    />
                                </div>
                            </div>
                        )}
                    </Popup>
                </span>
            );
        }

        else if (this.props.type === 'bulk') {
            return (
                <span className='uploadForm'>
                    <Popup closeOnDocumentClick={false} onClose={this.clearState} trigger={this.props.button} overlayStyle={{ zIndex: 5 }} modal>
                        {close => (
                            <div className="modal">
                                <Button className="close" icon="close" onClick={(e) => { close(); this.clearState(); }} />


                                <div className="header" align="left">
                                    Bulk Upload
                                </div>

                                <div className="content">
                                    <h3>Instructions:<br /> Upload a zip file containing files where the file name starts with the account followed by an "_".
                                        There can be any number of subfolders in the zip file.<br />
                                        Select "Preview" to view how the files will be mapped before uploading.</h3>
                                    <div>Please select a .zip file to upload:</div>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td><strong>Folder:</strong></td>
                                                <td>
                                                {this.props.folders.length > 0 ?
                                                    <ComboBox
                                                        style={{ width: '400px' }}
                                                        data={this.props.folders.map(folder => {
                                                            return String(folder.text).endsWith('/') ? String(folder.text).slice(0, -1) : folder.text
                                                        })}
                                                        onChange={(event) => this.onFieldChange(event, 'category')}
                                                        value={this.state.categorySelected.path}
                                                        filterable={false}
                                                        allowCustom={true}
                                                        // onFilterChange={(e) => {
                                                        //     this.setState({ vaultFolderListResult: filterBy(this.props.vaultFolderList, e.filter) });
                                                        // }}
                                                    />
                                                    :
                                                    <Input
                                                        style={{ width: '400px' }}
                                                        onChange={(e) => this.onFieldChange(e, 'category')}
                                                        value={this.state.categorySelected.path}
                                                    />
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Description:</strong></td>
                                                <td>
                                                    {/* <input type='text'value={this.state.description} onChange={(e)=>{this.setState({description:e.target.value})}}/> */}
                                                    {this.state.descriptionsResultOriginal.length > 0 ? 
                                                    <ComboBox
                                                        style={{ width: '400px' }}
                                                        data={this.state.descriptionsResult || this.props.descriptions}//uses filtered list if exists, otherwise uses original props
                                                        onChange={(e) => this.onFieldChange(e, 'description')}
                                                        value={this.state.description}
                                                        filterable={this.state.descriptionsResultOriginal.length > 0}
                                                        listNoDataRender={() => {return <></>}}
                                                        allowCustom={true}
                                                        onFilterChange={(e) => {
                                                            this.setState({ descriptionsResult: filterBy(this.state.descriptionsResultOriginal, e.filter) });
                                                        }}
                                                    />
                                                    :
                                                    <Input
                                                        style={{ width: '400px' }}
                                                        onChange={(e) => this.onFieldChange(e, 'description')}
                                                        value={this.state.description}
                                                    />}
                                                </td>
                                            </tr>
                                            <tr><td><strong>Import into Household Folders:</strong><input type="checkbox" checked={this.state.useHouseholdFolders} onChange={this.onClick_UseHH} /></td></tr>
                                            <tr><td><strong>Notify Users Via Email:</strong><input type="checkbox" checked={this.state.notifyUsers} onChange={this.onClick_Notify} /></td></tr>

                                        </tbody>
                                    </table>
                                    <Upload autoUpload={true}
                                        multiple={false}
                                        restrictions={{ allowedExtensions: ['.zip'] }}
                                        files={this.state.files}
                                        onAdd={(e) => this.onAdd(e)}
                                        onRemove={(e) => this.onRemove(e)}
                                        onProgress={(e) => this.onProgress(e)}
                                        onStatusChange={this.onBulkStatusChange}
                                        onBeforeUpload={this.onBeforeUpload}
                                        saveUrl={"api/bulk?company="+this.props.company}
                                        saveHeaders={{ 'authorization': 'Bearer '.concat(this.props.idToken) }}
                                    />
                                    {this.state.gridPreview && (<Grid data={this.state.bulkPreview} className='bulkPreview' rowRender={this.bulkUserRow}>
                                        <GridColumn title='File' field='file' />
                                        {this.state.useHouseholdFolders && <GridColumn title='Household' field='user' />}
                                        {!this.state.useHouseholdFolders && <GridColumn title='User' field='user' />}
                                    </Grid>)}
                                </div>
                                <div className='actions'>
                                    {!this.state.gridPreview && this.state.bulkPreview && (<Button disabled={!this.state.bulkPreview} className='button' onClick={(e) => this.setState({ gridPreview: true })}>Preview</Button>)}
                                    {this.state.gridPreview && <Button className='button' onClick={this.sendBulkPreview}>Email Results</Button>}
                                    {this.state.bulkPreview && (<Button className='upload' onClick={(e) => { this.onBulkUpload(e, close); }}>Confirm Upload</Button>)}
                                </div>
                            </div>
                        )}
                    </Popup>
                </span>
            );
        }

        else if (this.props.type === 'webrep') {
            return (
                <span className='uploadForm'>
                    <Popup closeOnDocumentClick={false} onClose={this.clearState} trigger={this.props.button} overlayStyle={{ zIndex: 5 }} modal>
                        {close => (
                            <div className="modal">
                                <Button className="close" icon="close" onClick={(e) => { close(); this.clearState(); }} />


                                <div className="header" align="left">
                                    Web Report Upload
                                </div>
                                <br />

                                <div className="content">
                                    <label>Report Title: </label><input type="text" name="Title" value={this.state.title || this.getEditField('title')} placeholder={"Title"} onChange={(e) => this.onFieldChange(e, 'title')} /><br /><br />
                                    <label>Platform: </label>
                                    <ComboBox data={filterBy(this.removeDupPlatforms(), this.state.platFilter)}
                                        textField='text'
                                        defaultValue={this.getEditField('platform')}
                                        onChange={(e) => this.onFieldChange(e, 'platform')}
                                        allowCustom={true}
                                        filterable={true}
                                        onFilterChange={this.onPlatFilterChange} />
                                    <br />
                                    <br />
                                    <label>Category: </label>
                                    <ComboBox data={filterBy(this.removeDupCategories(), this.state.catFilter)}
                                        textField='text'
                                        defaultValue={this.getEditField('category')}
                                        onChange={(e) => this.onFieldChange(e, 'category')}
                                        allowCustom={true}
                                        filterable={true}
                                        onFilterChange={this.onCatFilterChange} />
                                    <div>Please select a web report to upload:</div>
                                    <Upload autoUpload={true}
                                        multiple={false}
                                        files={this.state.files || this.getEditField('reportName')}
                                        onAdd={(e) => this.onAdd(e)}
                                        onRemove={(e) => this.onRemove(e)}
                                        onProgress={(e) => this.onProgress(e)}
                                        onStatusChange={(e) => this.onWebRepStatusChange(e)}
                                        onBeforeUpload={this.onBeforeUpload}
                                        saveUrl={"api/preupload?company="+this.props.company}
                                        saveHeaders={{ 'authorization': 'Bearer '.concat(this.props.idToken) }}
                                    />
                                    <div>Description:</div>
                                    <textarea className='textbox' placeholder='Enter a description' value={this.state.description || this.getEditField('description')} onChange={(e) => this.onFieldChange(e, 'description')} /> <br /><br />
                                    <div>Sample report (Optional):</div>
                                    <Upload autoUpload={true}
                                        multiple={false}
                                        files={this.state.altFiles || this.getEditField('sampleName')}
                                        onAdd={(e) => this.onAdd(e, true)}
                                        onRemove={(e) => this.onRemove(e, true)}
                                        onProgress={(e) => this.onProgress(e, true)}
                                        onStatusChange={(e) => this.onWebRepStatusChange(e, true)}
                                        onBeforeUpload={this.onBeforeUpload}
                                        saveUrl={"api/preupload?company="+this.props.company}
                                        saveHeaders={{ 'authorization': 'Bearer '.concat(this.props.idToken) }}
                                    />
                                    <br />
                                    <label>Expiration Date:</label><DatePicker value={this.state.expiration || this.getEditField('expiration')} max={new Date(3001, 12, 31)} onChange={(e) => this.onFieldChange(e, 'expiration')} />
                                </div>
                                <div className='actions'>
                                    <Button className='button' onClick={(e) => { close(); this.clearState(); }}>Cancel</Button>
                                    <Button className='upload' disabled={!this.props.repEdit && !this.state.files} onClick={(e) => { this.onWebRepUpload(e, close) }}>Confirm Upload</Button>
                                </div>
                            </div>
                        )}
                    </Popup>
                </span>
            );
        }

        else if (this.props.type === 'reportStaticContent') {
            return (
                <span className='uploadForm'>
                    <Popup closeOnDocumentClick={false} onClose={this.clearState} trigger={this.props.button} overlayStyle={{ zIndex: 5 }} modal>
                        {close => (
                            <div className="modal">
                                <Button className="close" icon="close" onClick={(e) => { close(); this.clearState(); }} />


                                <div className="header" align="left">
                                    Static Content Upload
                                </div>
                                <br />

                                <div className="content">
                                    <div>Please select a PDF to upload:</div>
                                    <Upload autoUpload={true}
                                        multiple={false}
                                        files={this.state.files || this.getEditField('reportName')}
                                        onAdd={(e) => this.onAdd(e)}
                                        restrictions={{
                                            allowedExtensions: [".pdf"],
                                        }}
                                        onRemove={(e) => this.onRemove(e)}
                                        onProgress={(e) => this.onStaticContentProgress(e)}
                                        onStatusChange={(e) => this.onStaticContentChange(e)}
                                        // onBeforeUpload={this.onBeforeUpload}
                                        saveUrl={"api/preupload?company="+this.props.company}
                                        saveHeaders={{ 'authorization': 'Bearer '.concat(this.props.idToken) }}
                                    /><br /><br />
                                    <label>Title: </label><input type="text" name="Title" value={this.state.title || this.getEditField('title')} placeholder={"Title"} onChange={(e) => this.onFieldChange(e, 'title')} /><br /><br />
                                    <div>Description:</div>
                                    <textarea className='textbox' placeholder='Enter a description' value={this.state.description || this.getEditField('description')} onChange={(e) => this.onFieldChange(e, 'description')} /> <br /><br />
                                </div>
                                <div className='actions'>
                                    <Button className='button' onClick={(e) => { close(); this.clearState(); }}>Cancel</Button>
                                    <Button className='upload' disabled={!this.props.repEdit && !this.state.files} onClick={(e) => { this.onStaticContentUpload(e, close) }}>Confirm Upload</Button>
                                </div>
                            </div>
                        )}
                    </Popup>
                </span>
            );
        }

        else if (this.props.type === 'reportStaticContentEdit') {
            return (
                <span className='uploadForm'>
                    <Popup closeOnDocumentClick={false} onClose={this.clearState} trigger={this.props.button} overlayStyle={{ zIndex: 5 }} modal>
                        {close => (
                            <div className="modal">
                                <Button className="close" icon="close" onClick={(e) => { close(); this.clearState(); }} />


                                <div className="header" align="left">
                                    Static Content Edit
                                </div>
                                <br />

                                <div className="content">
                                    <div>Please select a PDF to upload:</div>
                                    <Upload autoUpload={true}
                                        multiple={false}
                                        files={this.state.files || this.getEditField('contentName')}
                                        onAdd={(e) => this.onAdd(e)}
                                        restrictions={{
                                            allowedExtensions: [".pdf"],
                                        }}
                                        onRemove={(e) => this.onRemove(e)}
                                        onProgress={(e) => this.onStaticContentProgress(e)}
                                        onStatusChange={(e) => this.onStaticContentChange(e)}
                                        // onBeforeUpload={this.onBeforeUpload}
                                        saveUrl={"api/preupload?company="+this.props.company}
                                        saveHeaders={{ 'authorization': 'Bearer '.concat(this.props.idToken) }}
                                    /><br /><br />
                                    <label>Title: </label><input type="text" name="Title" value={this.state.title || this.getEditField('title')} placeholder={"Title"} onChange={(e) => this.onFieldChange(e, 'title')} /><br /><br />
                                    <div>Description:</div>
                                    <textarea className='textbox' placeholder='Enter a description' value={this.state.description || this.getEditField('description')} onChange={(e) => this.onFieldChange(e, 'description')} /> <br /><br />
                                </div>
                                <div className='actions'>
                                    <Button className='button' onClick={(e) => { close(); this.clearState(); }}>Cancel</Button>
                                    <Button className='upload' disabled={!this.props.repEdit && !this.state.files} onClick={(e) => { this.onStaticContentUpload(e, close) }}>Save Changes</Button>
                                </div>
                            </div>
                        )}
                    </Popup>
                </span>
            );
        }

        else if (this.props.type === 'pdfTerms') {
            return (
                <span className='uploadForm'>
                    <Popup closeOnDocumentClick={false} onClose={this.clearState} trigger={this.props.button} overlayStyle={{ zIndex: 5 }} modal>
                        {close => (
                            <div className="modal">
                                <Button className="close" icon="close" onClick={(e) => { close(); this.clearState(); }} />


                                <div className="header" align="left">
                                    PDF Terms Upload
                                </div>
                                <br />

                                <div className="content">
                                    <div>Please select a PDF to upload:</div>
                                    <Upload autoUpload={true}
                                        multiple={false}
                                        files={this.state.files || this.getEditField('reportName')}
                                        onAdd={(e) => this.onAdd(e)}
                                        restrictions={{
                                            allowedExtensions: [".pdf"],
                                        }}
                                        onRemove={(e) => this.onRemove(e)}
                                        onProgress={(e) => this.onStaticContentProgress(e)}
                                        onStatusChange={(e) => this.onStaticContentChange(e)}
                                        // onBeforeUpload={this.onBeforeUpload}
                                        saveUrl={"api/preupload?company="+this.props.company}
                                        saveHeaders={{ 'authorization': 'Bearer '.concat(this.props.idToken) }}
                                    /><br /><br />
                                    <label>Title: </label><input type="text" name="Title" value={this.state.title || this.getEditField('title')} placeholder={"Title"} onChange={(e) => this.onFieldChange(e, 'title')} /><br /><br />
                                    <div>Hyperlink Text:</div>
                                    <textarea className='textbox' name="Description" placeholder='Enter text to be used for the hyperlink' value={this.state.description || this.getEditField('description')} onChange={(e) => this.onFieldChange(e, 'description')} /> <br /><br />
                                </div>
                                <div className='actions'>
                                    <Button className='button' onClick={(e) => { close(); this.clearState(); }}>Cancel</Button>
                                    <Button className='upload' disabled={!this.props.repEdit && !this.state.files} onClick={(e) => { this.onPdfTermsUpload(e, close) }}>Confirm Upload</Button>
                                </div>
                            </div>
                        )}
                    </Popup>
                </span>
            );
        }

        else if (this.props.type === 'pdfTermsEdit') {
            return (
                <span className='uploadForm'>
                    <Popup closeOnDocumentClick={false} onClose={this.clearState} trigger={this.props.button} overlayStyle={{ zIndex: 5 }} modal>
                        {close => (
                            <div className="modal">
                                <Button className="close" icon="close" onClick={(e) => { close(); this.clearState(); }} />
                        
                        
                                <div className="header" align="left">
                                    PDF Terms Edit
                                </div>
                                <br />
                        
                                <div className="content">
                                    <div>Please select a PDF to upload:</div>
                                    <Upload autoUpload={true}
                                        multiple={false}
                                        files={this.state.files || this.getEditField('contentName')}
                                        onAdd={(e) => this.onAdd(e)}
                                        restrictions={{
                                            allowedExtensions: [".pdf"],
                                        }}
                                        onRemove={(e) => this.onRemove(e)}
                                        onProgress={(e) => this.onStaticContentProgress(e)}
                                        onStatusChange={(e) => this.onStaticContentChange(e)}
                                        // onBeforeUpload={this.onBeforeUpload}
                                        saveUrl={"api/preupload?company="+this.props.company}
                                        saveHeaders={{ 'authorization': 'Bearer '.concat(this.props.idToken) }}
                                    /><br /><br />
                                    <label>Title: </label><input type="text" name="Title" value={this.state.title || this.getEditField('title')} placeholder={"Title"} onChange={(e) => this.onFieldChange(e, 'title')} /><br /><br />
                                    <div>Hyperlink Text:</div>
                                    <textarea className='textbox' name="Description" placeholder='Enter text to be used for the hyperlink' value={this.state.description || this.getEditField('description')} onChange={(e) => this.onFieldChange(e, 'description')} /> <br /><br />
                                </div>
                                <div className='actions'>
                                    <Button className='button' onClick={(e) => { close(); this.clearState(); }}>Cancel</Button>
                                    <Button className='upload' disabled={!this.props.repEdit && !this.state.files} onClick={(e) => { this.onPdfTermsUpload(e, close) }}>Save Changes</Button>
                                </div>
                            </div>
                        )}
                    </Popup>
                </span>
            );
        }

    };

}


export default UploadForm;