import React from 'react';
import axios from 'axios';
import { parseDate } from '@telerik/kendo-intl';
import loading from '../../Callback/loading.svg';

class RealizedGains extends React.Component {

  constructor(props) {
    super(props);

    const valObj = this.getValues(this.props.width);

    this.state = {
      data: null,
      fromDate: new Date(),
      toDate: new Date(),
      col1: valObj.col1,
      col2: valObj.col2,
      graphHeight: valObj.graphHeight,
      loading: true,
    };

  }

  getValues = (width) => {
    let col1 = 0, col2 = 0, graphHeight = 0;
    switch (width) {
      case 4:
        col1 = 12; col2 = 12; graphHeight = 350;
        break;
      case 6:
        col1 = 12; col2 = 12; graphHeight = 350;
        break;
      case 12:
        col1 = 12; col2 = 12; graphHeight = 350;
        break;
      default:
    }
    return { col1: col1, col2: col2, graphHeight: graphHeight }
  }

  componentDidMount() {
    this.getStyleSettings(() => {
      this.props.callback((width) => {
        const CBvalObj = this.getValues(width);
        this.setState({
          graphHeight: CBvalObj.graphHeight,
          col1: CBvalObj.col1,
          col2: CBvalObj.col2
        })
      });
      if (this.props.onWidgetLoad) {
        this.props.onWidgetLoad();
        this.props.onAddCallback((result, err) => {
          this.setState({ loading: true }, () => {
            this.onSubmit();
          })
        })
      }
    })
  }

  getStyleSettings(cb) {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
      'rgainDefaultPeriod'
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
      .then(response => {
        this.setState({ styleSettings: response.data.styleSettings }, () => {
          cb()
        })
      })
      .catch(err => {
        console.error(err);
      })
  }

  onSubmit = () => {
    this.getRGains();
  }

  getRGains(cb) {
    this.setState({ loading: true });
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var household = this.props.selectedHousehold.household
    var from = this.getPeriod()
    var to = new Date()

    var params = {
      company: this.props.user.company,
      household: household,
      from: from,
      to: to,
    }
    axios.get(`api/rgainsSnapshot`, {
      method: 'GET',
      params: params,
      headers: headers,
    })
      .then(response => {
        if (response.data.code === 200 && response.data.data.length === 1) {
          console.log(response)
          var data = response.data.data[0]
          var rGainData = {
            shortTerm: data.shortTerm,
            longTerm: data.longTerm,
            percentRGL: data.percentRGL,
            totalRGains: (data.shortTerm + data.longTerm),
          }

          if (!rGainData.shortTerm && !rGainData.longTerm && !rGainData.percentRGL) {
            rGainData = null
          }

          this.setState({ loading: false, data: rGainData, fromDate: from || this.getDate(data.fromDate, true), toDate: to })

        }
      }).catch(err => {
        this.setState({ data: null, loading: false });
        console.log(err);
      });
  }

  getDate(sqlDate, isUTC) {
    var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
    if (d) {
      var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
      if (isUTC)
        return utc;
      else
        return d;
    }
    else
      return null;
  }

  getPeriod() {
    var period = this.state.styleSettings.rgainDefaultPeriod;
    var date = new Date();

    if (period === "Month to Date")
      date = new Date(date.getFullYear(), date.getMonth(), 1);
    else if (period === 'Quarter to Date') {
      var quarter = Math.floor((date.getMonth() / 3));
      date = new Date(date.getFullYear(), quarter * 3, 1);
    }
    else if (period === 'Year to Date')
      date = new Date(date.getFullYear(), 0, 1);
    else if (period === '6 Month')
      date = new Date(date.getFullYear(), date.getMonth() - 6, date.getDate());
    else if (period === '12 Month')
      date = new Date(date.getFullYear() - 1, date.getMonth(), date.getDate());
    else date = null;

    return date;
  }

  numberWithCommas(x) {
    return "$" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  formatCurrency(val) {
    if (isNaN(val))
      val = 0;

    var formatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(val);
    return formatted;
  }

  getDateHeader() {
    return (
      (this.state.fromDate ? this.state.fromDate.toLocaleDateString("en-US") : 'N/A')
      + ' to ' +
      (this.state.toDate ? this.state.toDate.toLocaleDateString("en-US") : 'N/A')
    )
  }

  render() {
    return (<div>
      <div className="widget">
        <div className={'widgetWidth' + this.state.col2}>
          {this.state.loading && <img className="loadingIcon" src={loading} alt="loading" />}
          {!this.state.loading ?
            <>
              <div className='rGainSnapshotHeader' ><label>{this.getDateHeader()}</label></div>
              {this.state.data ?
                <div style={{ height: this.state.graphHeight + 'px' }}>
                  <table className='rGainSnapshot'>
                    <tbody>
                      <tr>
                        <td className='totalRGainLabel'>
                          <label style={{ fontWeight: 'bold' }}>Total Realized Gain/Loss</label>
                        </td>
                        <td className='totalRGainValue'>
                          <label style={{ fontWeight: 'bold' }}>{this.formatCurrency(this.state.data.totalRGains)}</label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className='rGainSnapshot'>
                    <tbody>
                      <tr>
                        <td className='rGainLabel' style={{ paddingTop: '1.2em' }}>
                          <label>% RGL</label>
                        </td>
                        <td className='rGainValue' style={{ paddingTop: '1.2em' }}>
                          <label>{(Math.round((this.state.data.percentRGL * 100) * 100) / 100).toFixed(2)}%</label>
                        </td>
                      </tr>
                      <tr>
                        <td className='rGainLabel'>
                          <label>Short-Term</label>
                        </td>
                        <td className='rGainValue'>
                          <label>{this.formatCurrency(this.state.data.shortTerm)}</label>
                        </td>
                      </tr>
                      <tr>
                        <td className='rGainLabel'>
                          <label>Long-Term</label>
                        </td>
                        <td className='rGainValue'>
                          <label>{this.formatCurrency(this.state.data.longTerm)}</label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                : <div className='widgetNoDataWithDate'>No transactions during the period</div>
              }
            </>
            : <div className='widgetNoData'></div>}
        </div>
      </div>
    </div>
    );
  }
}

export default RealizedGains;