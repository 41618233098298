import React, { Component } from "react";
import { Grid, GridColumn, GridToolbar, GridNoRecords } from '@progress/kendo-react-grid';
import { aggregateBy, process } from '@progress/kendo-data-query';
import GridLoading from '../../Loaders/GridLoading';
import { parseDate } from '@telerik/kendo-intl';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Button } from "@progress/kendo-react-buttons";
import axios from 'axios';
import { PDFExport } from '@progress/kendo-react-pdf';

const ADJUST_PADDING = 4;
const COLUMN_MIN = 4;



class AccountHoldings extends Component {
    pdfExportComponent;
    grid;

    constructor(props) {
        super(props);
        this.counter = 0;
        this.minGridWidth = 0;

        var columns = [];

        // const data = process(this.state.accountHoldings, {
        //   group: this.state.dataState.group
        // }).data;
        // const total = aggregateBy(this.state.accountHoldings, this.state.aggregates);

        // const CustomGroupHeader = (props) => (`Discontinued: ${props.value}`);

        // const CustomGroupFooter = (props) => (`${(props.group.value)} SUM: \$ ${(props.aggregates[props.column.field].sum).toFixed(2)}`);

        // const CustomFooter = (props) =>
        //     (`Total ${props.column.field}: \$ ${total.UnitPrice.sum}`);


        //old columns begin
        // if (props.compSettings.showHoldSecType)
        //   columns.push({ field: 'Type', title: 'Type', headerClass: 'gridHeader', minWidth: 150, show: true });

        // columns.push({ field: 'securitySymbol', title: 'Symbol', headerClass: 'gridHeader', minWidth: 100, show: true });
        // columns.push({ field: 'securityName', title: 'Security Name', headerClass: 'gridHeader', minWidth: 300, show: true });

        // if (props.compSettings.ShowHoldingsAcqDate)
        //   columns.push({ field: 'acqDate', title: 'Acq. Date', format: "{0:d}", excelFormat: "m/d/yyyy", headerClass: 'gridHeader', minWidth: 150, show: true });
        // if (props.compSettings.ShowHoldingsQuantity)
        //   columns.push({ field: 'quantity', title: 'Quantity', format: "{0:n2}", excelFormat: "#,##0.00", headerClass: 'gridHeader', minWidth: 85, show: true });
        // if (props.compSettings.ShowHoldingsUnitCost)
        //   columns.push({ field: 'unitCost', title: 'Unit Cost', format: "{0:c}", excelFormat: "$#,##0.00", headerClass: 'gridHeader', minWidth: 85, show: true });
        // if (props.compSettings.ShowHoldingsTotalCost)
        //   columns.push({ field: 'totalCost', title: 'Total Cost', format: "{0:c}", excelFormat: "$#,##0.00", headerClass: 'gridHeader', minWidth: 110, show: true });
        // if (props.compSettings.ShowHoldingsPrice)
        //   columns.push({ field: 'appMarketPrice', title: 'Price', format: "{0:c}", excelFormat: "$#,##0.00", headerClass: 'gridHeader', minWidth: 85, show: true });
        // if (props.compSettings.ShowHoldingsMarketValue)
        //   columns.push({ field: 'marketValue', title: 'Market Value', format: "{0:c}", excelFormat: "$#,##0.00", headerClass: 'gridHeader', minWidth: 115, show: true });

        // columns.push({ field: 'AccountID', title: 'Account ID', headerClass: 'gridHeader', minWidth: 110, show: true });
        // columns.push({ field: 'Name1', title: 'Account ID', headerClass: 'gridHeader', minWidth: 110, show: true });
        // columns.push({ field: 'Name2', title: 'Account ID', headerClass: 'gridHeader', minWidth: 110, show: true });
        // columns.push({ field: 'Name3', title: 'Account ID', headerClass: 'gridHeader', minWidth: 110, show: true });

        // if (props.compSettings.ShowHoldingsPctOfAccount)
        //   columns.push({ field: 'percentage', title: '% of Account', format: "{0:p2}", excelFormat: "0.00%", headerClass: 'gridHeader', minWidth: 115, show: true });
        // if (props.compSettings.ShowHoldingsUGain)
        //   columns.push({ field: 'unrealized', title: 'Unrealized Gain/Loss', format: "{0:c}", excelFormat: "$#,##0.00", headerClass: 'gridHeader', minWidth: 155, show: true });
        // if (props.compSettings.ShowHoldingsEstAnnIncome)
        //   columns.push({ field: 'estAnnIncome', title: 'Est. Ann. Income', format: "{0:c}", excelFormat: "$#,##0.00", headerClass: 'gridHeader', minWidth: 165, show: true });
        // if (props.compSettings.ShowHoldingsYield)
        //   columns.push({ field: 'yield', title: 'Yield', format: "{0:p2}", excelFormat: "0.00%", headerClass: 'gridHeader', minWidth: 85, show: true });
        // if (props.compSettings.showHoldAsset)
        //   columns.push({ field: 'Asset Class', title: 'Asset Class', headerClass: 'gridHeader', minWidth: 150, show: true });
        // if (props.compSettings.showHoldSector)
        //   columns.push({ field: 'Sector', title: 'Sector', headerClass: 'gridHeader', minWidth: 150, show: true });
        // if (props.compSettings.showHoldInd)
        //   columns.push({ field: 'Industry', title: 'Industry', headerClass: 'gridHeader', minWidth: 150, show: true });

        // if (props.compSettings.showHoldUser1)
        //   columns.push({ field: this.props.compSettings.userDef1, title: this.props.compSettings.userDef1, headerClass: 'gridHeader', minWidth: 165, show: true });
        // if (props.compSettings.showHoldUser2)
        //   columns.push({ field: this.props.compSettings.userDef2, title: this.props.compSettings.userDef2, headerClass: 'gridHeader', minWidth: 165, show: true });
        // if (props.compSettings.showHoldUser3)
        //   columns.push({ field: this.props.compSettings.userDef3, title: this.props.compSettings.userDef3, headerClass: 'gridHeader', minWidth: 165, show: true });
        // if (props.compSettings.showHoldUser4)
        //   columns.push({ field: this.props.compSettings.userDef4, title: this.props.compSettings.userDef4, headerClass: 'gridHeader', minWidth: 165, show: true });
        // if (props.compSettings.showHoldUser5)
        //   columns.push({ field: this.props.compSettings.userDef5, title: this.props.compSettings.userDef5, headerClass: 'gridHeader', minWidth: 165, show: true });
        //old columns end









        var defaultAggs = [{ field: 'quantity', aggregate: 'sum' },
        { field: 'marketValue', aggregate: 'sum' },
        { field: 'totalCost', aggregate: 'sum' },
        { field: 'percentage', aggregate: 'sum' },
        { field: 'percentUnrealized', aggregate: 'sum'},
        { field: 'unrealized', aggregate: 'sum' },
        { field: 'estAnnIncome', aggregate: 'sum' }];

        var defaultGroup = [];


        this.state = {
            accountHoldings: [],
            result: [],
            dataState: { group: defaultGroup/*, sort:[{field:'Name1',dir:'asc'},{field:'Name2',dir:'asc'},{field:'Name3',dir:'asc'}]*/ },
            aggregates: defaultAggs,
            origAggregates: defaultAggs,
            household: this.props.household,
            portfolio: this.props.portfolio,
            dates: [],
            selectedDate: '',
            columns: columns,
            loading: true,
            IndvTaxLots: 0,
            showTaxLots: false,
            showAccountBreakdown: false,
            styleSettings: {
                holdingsShowAccountID: null,
                holdingsShowName1: null,
                holdingsShowName2: null,
                holdingsShowName3: null,
                holdingsDataRowColor: null,
                holdingsSubtotalColor1: null,
                holdingsSubtotalColor2: null,
                holdingsSubtotalColor3: null,
                holdingsGrandTotalColor: null,
                columnLabelAssetClass: null,
                columnLabelSecurityType: null,
                columnLabelSector: null,
                columnLabelIndustry: null,
                columnLabelAcqDate: null,
                columnLabelQuantity: null,
                columnLabelUnitCost: null,
                columnLabelTotalCost: null,
                columnLabelUnitPrice: null,
                columnLabelMarketValue: null,
                columnLabelPercentOfAccount: null,
                columnLabelEstAnnualIncome: null,
                columnLabelYield: null,
                columnLabelSymbol: null,
                columnLabelSecurityName: null,
                columnLabelUnrealized: null,
                holdingsShowAssetClass: null,
                holdingsShowSecType: null,
                holdingsShowSector: null,
                holdingsShowIndustry: null,
                holdingsShowUserDef1: null,
                holdingsShowUserDef2: null,
                holdingsShowUserDef3: null,
                holdingsShowUserDef4: null,
                holdingsShowUserDef5: null,
                holdingsShowAcqDate: null,
                holdingsShowQuantity: null,
                holdingsShowUnitCost: null,
                holdingsShowTotalCost: null,
                holdingsShowPrice: null,
                holdingsShowMarketValue: null,
                holdingsShowPercentage: null,
                holdingsShowEstAnnIncome: null,
                holdingsShowYield: null,
                holdingsShowUnrealized: null,
                holdingsShowPercentUnrealized: null,
                holdingsShowLumpedPositions: null,
                holdingsClassificationGrouping1: null,
                holdingsClassificationGrouping2: null,
                holdingsClassificationGrouping3: null,
                holdingsColumnOrder: null,
                holdingsColumnWidthSecType: null,
                holdingsColumnWidthSymbol: null,
                holdingsColumnWidthSecName: null,
                holdingsColumnWidthAcqDate: null,
                holdingsColumnWidthQuantity: null,
                holdingsColumnWidthUnitCost: null,
                holdingsColumnWidthTotalCost: null,
                holdingsColumnWidthPrice: null,
                holdingsColumnWidthMarketValue: null,
                holdingsColumnWidthAccountID: null,
                holdingsColumnWidthPercentage: null,
                holdingsColumnWidthUnrealized: null,
                holdingsColumnWidthPercentUnrealized: null,
                holdingsColumnWidthEstAnnIncome: null,
                holdingsColumnWidthYield: null,
                holdingsColumnWidthAssetClass: null,
                holdingsColumnWidthSector: null,
                holdingsColumnWidthIndustry: null,
                holdingsColumnWidthUserDef1: null,
                holdingsColumnWidthUserDef2: null,
                holdingsColumnWidthUserDef3: null,
                holdingsColumnWidthUserDef4: null,
                holdingsColumnWidthUserDef5: null,
                holdingsSortOrderDefault: null,
                holdingsSortOrderTaxLot: null,
                householdName: null,
                showHouseholdName: null,
                holdingsHeaderDisplay: null,
                chartShowAsOf: null,
            },
            portNames: [],
            sort: []//[{field:'Name1',dir:'asc'},{field:'Name2',dir:'asc'},{field:'Name3',dir:'asc'}]
        }

        this.getAccountHoldings = this.getAccountHoldings.bind(this);
        this.getHoldDates = this.getHoldDates.bind(this);
        this.changeDataState = this.changeDataState.bind(this);
        this.formatCurrency = this.formatCurrency.bind(this);
        this.formatPercent = this.formatPercent.bind(this);
        this.cellRender = this.cellRender.bind(this);
        this.headerCellRender = this.headerCellRender.bind(this);
        this.getDate = this.getDate.bind(this);
        this.getStyleSettings = this.getStyleSettings.bind(this);
        this.CustomGroupHeader = this.CustomGroupHeader.bind(this);
        this.CustomGroupFooter = this.CustomGroupFooter.bind(this);
        this.CustomFooter = this.CustomFooter.bind(this);
        this.excelTotal = this.excelTotal.bind(this);
        this.exportPDFWithComponent = this.exportPDFWithComponent.bind(this);
        this.expand = this.expand.bind(this);
        this.expandCollapse = this.expandCollapse.bind(this);
        this.addSortOrderToResult = this.addSortOrderToResult.bind(this);
        this.sortClassification = this.sortClassification.bind(this);
    }

    componentDidMount() {
        this.getStyleSettings(() => {
            this.setColumns(() => {
                this.getAccountHoldings(null, () => {
                    this.setMinGridWidth();
                });
            });
        });
        this.getHoldDates();
        this.getShowHouseholdNameSetting()
    }

    componentDidUpdate() {
        if (this.props.portfolio !== this.state.portfolio) {
            this.setState({ portfolio: this.props.portfolio }, () => {
                this.getAccountHoldings(null, () => {
                    this.setMinGridWidth();

                })
            });
        }
    }


    excelTotal = () => { return aggregateBy(this.state.accountHoldings, this.state.aggregates) }

    getShowHouseholdNameSetting() {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        axios(`api/superAdminSettings`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers
        })
            .then(response => {
                response.data.data.forEach(setting => {
                    if (setting.settingName === 'showHouseholdName') {
                        this.setState({ showHouseholdName: setting.settingValue.toLowerCase() === 'true' ? true : false })
                    }
                })

            })
            .catch(err => console.log(err));
    }

    CustomGroupHeader = (props) => {
        return (props.value.replace(/&/g, '+'))
    }

    CustomGroupFooter = (props) => {
        if (props.column.field === 'securityName') {
            return (props.group.value.replace(/&/g, '+') + " Totals:")
        }
        else if (props.aggregates[props.column.field].sum !== undefined) {
            return props.aggregates[props.column.field].sum
        }
        else if (props.aggregates[props.column.field].min !== undefined) {
            return props.aggregates[props.column.field].min
        }
        else if (props.aggregates[props.column.field].max !== undefined) {
            return props.aggregates[props.column.field].max
        }
        else {
            return " "
        }
    }

    CustomFooter = (props) => { return ('Total ' + props.column.field + ': ' + this.excelTotal()[props.column.field].sum) }

    setColumns(cb) {

        var columns = [];

        if (this.state.styleSettings.holdingsShowSecType)
            columns.push({ field: 'Type', title: 'Type', headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthSecType || 150, show: true });

        columns.push({ field: 'securitySymbol', title: 'Symbol', headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthSymbol || 100, show: true });
        columns.push({ field: 'securityName', title: 'Security Name', headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthSecName || 300, show: true });

        if (this.state.styleSettings.holdingsShowAcqDate)
            columns.push({ field: 'acqDate', title: 'Acq. Date', format: "{0:d}", excelFormat: "m/d/yyyy", headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthAcqDate || 150, show: true });
        if (this.state.styleSettings.holdingsShowQuantity)
            columns.push({ field: 'quantity', title: 'Quantity', format: "{0:n2}", excelFormat: "#,##0.00", headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthQuantity || 85, show: true });
        if (this.state.styleSettings.holdingsShowUnitCost)
            columns.push({ field: 'unitCost', title: 'Unit Cost', format: "{0:c}", excelFormat: "$#,##0.00", headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthUnitCost || 85, show: true });
        if (this.state.styleSettings.holdingsShowTotalCost)
            columns.push({ field: 'totalCost', title: 'Total Cost', format: "{0:c}", excelFormat: "$#,##0.00", headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthTotalCost || 110, show: true });
        if (this.state.styleSettings.holdingsShowPrice)
            columns.push({ field: 'appMarketPrice', title: 'Price', format: "{0:c}", excelFormat: "$#,##0.00", headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthPrice || 85, show: true });
        if (this.state.styleSettings.holdingsShowMarketValue)
            columns.push({ field: 'marketValue', title: 'Market Value', format: "{0:c}", excelFormat: "$#,##0.00", headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthMarketValue || 115, show: true });

        columns.push({ field: 'AccountID', title: 'Account ID', headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthAccountID || 110, show: true });
        columns.push({ field: 'Name1', title: 'Account ID', headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthAccountID || 110, show: true });
        columns.push({ field: 'Name2', title: 'Account ID', headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthAccountID || 110, show: true });
        columns.push({ field: 'Name3', title: 'Account ID', headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthAccountID || 110, show: true });

        if (this.state.styleSettings.holdingsShowPercentage)
            columns.push({ field: 'percentage', title: '% of Account', format: "{0:p2}", excelFormat: "0.00%", headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthPercentage || 115, show: true });
        
        if (this.state.styleSettings.holdingsShowUnrealized)
            columns.push({ field: 'unrealized', title: 'Unrealized Gain/Loss', format: "{0:c}", excelFormat: "$#,##0.00", headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthUnrealized || 155, show: true });

        if (this.state.styleSettings.holdingsShowPercentUnrealized)
            columns.push({ field: 'percentUnrealized', title: '% Unrealized Gain/Loss', format: "{0:p2}", excelFormat: "0.00%", headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthPercentUnrealized || 175, show: true });

        if (this.state.styleSettings.holdingsShowEstAnnIncome)
            columns.push({ field: 'estAnnIncome', title: 'Est. Ann. Income', format: "{0:c}", excelFormat: "$#,##0.00", headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthEstAnnIncome || 165, show: true });
        if (this.state.styleSettings.holdingsShowYield)
            columns.push({ field: 'yield', title: 'Yield', format: "{0:p2}", excelFormat: "0.00%", headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthYield || 85, show: true });
        if (this.state.styleSettings.holdingsShowAssetClass)
            columns.push({ field: 'Asset Class', title: 'Asset Class', headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthAssetClass || 150, show: true });
        if (this.state.styleSettings.holdingsShowSector)
            columns.push({ field: 'Sector', title: 'Sector', headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthSector || 150, show: true });
        if (this.state.styleSettings.holdingsShowIndustry)
            columns.push({ field: 'Industry', title: 'Industry', headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthIndustry || 150, show: true });

        if (this.state.styleSettings.holdingsShowUserDef1)
            columns.push({ field: this.props.styleSettings.userDef1, title: this.props.styleSettings.userDef1, headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthUserDef1 || 165, show: true });
        if (this.state.styleSettings.holdingsShowUserDef2)
            columns.push({ field: this.props.styleSettings.userDef2, title: this.props.styleSettings.userDef2, headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthUserDef2 || 165, show: true });
        if (this.state.styleSettings.holdingsShowUserDef3)
            columns.push({ field: this.props.styleSettings.userDef3, title: this.props.styleSettings.userDef3, headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthUserDef3 || 165, show: true });
        if (this.state.styleSettings.holdingsShowUserDef4)
            columns.push({ field: this.props.styleSettings.userDef4, title: this.props.styleSettings.userDef4, headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthUserDef4 || 165, show: true });
        if (this.state.styleSettings.holdingsShowUserDef5)
            columns.push({ field: this.props.styleSettings.userDef5, title: this.props.styleSettings.userDef5, headerClass: 'gridHeader', minWidth: this.state.styleSettings.holdingsColumnWidthUserDef5 || 165, show: true });

        var sortedColumns = []
        this.state.styleSettings.holdingsColumnOrder.split('|').forEach(columnField => {
            var index = -1

            switch (columnField) {
                case 'userDef1':
                    index = columns.findIndex(column => {
                        return ((column.field === this.props.styleSettings.userDef1))
                    })
                    break;
                case 'userDef2':
                    index = columns.findIndex(column => {
                        return ((column.field === this.props.styleSettings.userDef2))
                    })
                    break;
                case 'userDef3':
                    index = columns.findIndex(column => {
                        return ((column.field === this.props.styleSettings.userDef3))
                    })
                    break;
                case 'userDef4':
                    index = columns.findIndex(column => {
                        return ((column.field === this.props.styleSettings.userDef4))
                    })
                    break;
                case 'userDef5':
                    index = columns.findIndex(column => {
                        return ((column.field === this.props.styleSettings.userDef5))
                    })
                    break;
                case 'AccountID':
                    if (this.state.styleSettings.holdingsShowAccountID) {
                        index = columns.findIndex(column => {
                            return ((column.field === 'AccountID'))
                        })
                    }
                    else if (this.state.styleSettings.holdingsShowName1) {
                        index = columns.findIndex(column => {
                            return ((column.field === 'Name1'))
                        })
                    }
                    else if (this.state.styleSettings.holdingsShowName2) {
                        index = columns.findIndex(column => {
                            return ((column.field === 'Name2'))
                        })
                    }
                    else if (this.state.styleSettings.holdingsShowName3) {
                        index = columns.findIndex(column => {
                            return ((column.field === 'Name3'))
                        })
                    }
                    else {
                        index = columns.findIndex(column => {
                            return ((column.field === columnField))
                        })
                    }
                    break;
                default:
                    index = columns.findIndex(column => {
                        return ((column.field === columnField))
                    })
            }

            if (index >= 0)
                sortedColumns.push(columns[index])
        })



        this.setState({ columns: sortedColumns }, () => {
            cb()
        });

    }

    getStyleSettings(cb) {
        var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        var styleNames = [
            'holdingsHouseholdName',
            'holdingsHeaderDisplay',
            'holdingsShowAccountID',
            'holdingsShowName1',
            'holdingsShowName2',
            'holdingsShowName3',
            'holdingsIncludeAI',
            'holdingsDataRowColor',
            'holdingsSubtotalColor1',
            'holdingsSubtotalColor2',
            'holdingsSubtotalColor3',
            'holdingsGrandTotalColor',
            'columnLabelAssetClass',
            'columnLabelSecurityType',
            'columnLabelSector',
            'columnLabelIndustry',
            'columnLabelAcqDate',
            'columnLabelQuantity',
            'columnLabelUnitCost',
            'columnLabelTotalCost',
            'columnLabelUnitPrice',
            'columnLabelMarketValue',
            'columnLabelPercentOfAccount',
            'columnLabelEstAnnualIncome',
            'columnLabelYield',
            'columnLabelSymbol',
            'columnLabelSecurityName',
            'columnLabelUnrealized',
            'holdingsShowAssetClass',
            'holdingsShowSecType',
            'holdingsShowSector',
            'holdingsShowIndustry',
            'holdingsShowUserDef1',
            'holdingsShowUserDef2',
            'holdingsShowUserDef3',
            'holdingsShowUserDef4',
            'holdingsShowUserDef5',
            'holdingsShowAcqDate',
            'holdingsShowQuantity',
            'holdingsShowUnitCost',
            'holdingsShowTotalCost',
            'holdingsShowPrice',
            'holdingsShowMarketValue',
            'holdingsShowPercentage',
            'holdingsShowEstAnnIncome',
            'holdingsShowYield',
            'holdingsShowUnrealized',
            'holdingsShowPercentUnrealized',
            'holdingsShowLumpedPositions',
            'holdingsClassificationGrouping1',
            'holdingsClassificationGrouping2',
            'holdingsClassificationGrouping3',
            'holdingsColumnOrder',
            'holdingsColumnWidthSecType',
            'holdingsColumnWidthSymbol',
            'holdingsColumnWidthSecName',
            'holdingsColumnWidthAcqDate',
            'holdingsColumnWidthQuantity',
            'holdingsColumnWidthUnitCost',
            'holdingsColumnWidthTotalCost',
            'holdingsColumnWidthPrice',
            'holdingsColumnWidthMarketValue',
            'holdingsColumnWidthAccountID',
            'holdingsColumnWidthPercentage',
            'holdingsColumnWidthUnrealized',
            'holdingsColumnWidthPercentUnrealized',
            'holdingsColumnWidthEstAnnIncome',
            'holdingsColumnWidthYield',
            'holdingsColumnWidthAssetClass',
            'holdingsColumnWidthSector',
            'holdingsColumnWidthIndustry',
            'holdingsColumnWidthUserDef1',
            'holdingsColumnWidthUserDef2',
            'holdingsColumnWidthUserDef3',
            'holdingsColumnWidthUserDef4',
            'holdingsColumnWidthUserDef5',
            'holdingsSortOrderDefault',
            'holdingsSortOrderTaxLot',
            'chartShowAsOf'
        ]

        axios(`api/getStyleSettings`, {
            method: 'GET',
            params: { company: this.props.user.company, styleNames: styleNames },
            headers: headers
        })
            .then(response => {
                this.setState({ styleSettings: response.data.styleSettings }, () => {
                    cb()
                });
            })
            .catch(err => {
                console.error(err);
            })


    }

    exportPDFWithComponent = () => {
        this.pdfExportComponent.save();
    }

    setMinGridWidth = () => {
        this.grid = document.querySelector('.k-grid');
        window.addEventListener('resize', this.handleResize);
        this.minGridWidth = 0;
        this.state.columns.map(item => this.minGridWidth += item.minWidth);
        if (this.state.dataState.group)
            this.minGridWidth += 32 * this.state.dataState.group.length;
        this.setState({
            gridCurrent: this.grid.offsetWidth,
            setMinWidth: this.grid.offsetWidth < this.minGridWidth
        });
    }

    handleResize = () => {
        if (this.grid.offsetWidth != null && this.grid.offsetWidth < this.minGridWidth && !this.state.setMinWidth) {
            this.setState({
                setMinWidth: true
            });
        } else if (this.grid.offsetWidth != null && this.grid.offsetWidth > this.minGridWidth) {
            this.setState({
                gridCurrent: this.grid.offsetWidth,
                setMinWidth: false
            });
        }
    }

    setWidth = (minWidth) => {
        let width = this.state.setMinWidth ? minWidth : minWidth + (this.state.gridCurrent - this.minGridWidth) / this.state.columns.length;
        if (width >= COLUMN_MIN)
            width = width - ADJUST_PADDING;
        return width;
    }

    getAccountHoldings(date, cb) {
        this.setState({ loading: true });
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        const x = [...this.state.columns];
        x.forEach((column, key) => {
            if (column.field === "acqDate" && this.state.showTaxLots === false) {
                column.show = false;
                this.setState({ columns: x });
            }
            else {
                column.show = true;
                this.setState({ columns: x });
            }
        });


        var unmapped = '';
        if (this.props.user.unmapped)
            unmapped = '&unmapped=true';

        if (date === null)
            date = '';

        var port = this.state.portfolio.account
        if (this.state.portfolio.consolidated) {
            port = this.props.household
        }


        var breakOutTaxLotAccounts = false;
        if ((this.state.styleSettings.holdingsShowAccountID ||
            this.state.styleSettings.holdingsShowName1 ||
            this.state.styleSettings.holdingsShowName2 ||
            this.state.styleSettings.holdingsShowName3) &&
            this.state.showAccountBreakdown) {
            breakOutTaxLotAccounts = true;
        }


        axios.get('api/accountHoldings',
            {

                method: 'GET',
                params: {
                    company: this.props.user.company,
                    role: this.props.user.role,
                    port: port,
                    household: this.state.household,
                    consolidated: this.state.portfolio.consolidated,
                    date: date,
                    lumpLots: this.state.showTaxLots + unmapped,
                    breakOutConsolidatedPorts: breakOutTaxLotAccounts,
                    includeAI: this.state.styleSettings.holdingsIncludeAI

                },
                headers: headers,
            }).then(response => {
                if (response.data.code === 200) {
                    var IndvTaxLots = 0;
                    var householdName = null
                    for (let i = 0; i < 1; i++) {
                        householdName = response.data.data[i].householdName
                    }


                    var portNames = []

                    //response.data.data = response.data.data.filter(row => String(row.industry).toLowerCase() !== 'not applicable' && String(row.sector).toLowerCase() !== 'not applicable');

                    for (let i = 0; i < response.data.data.length; i++) {

                        portNames.push({ code: response.data.data[i].displayName, name: response.data.data[i].Name1 })


                        IndvTaxLots = response.data.data[i].IndvTaxLots;
                        response.data.data[i].acqDate = this.getDate(response.data.data[i].acqDate, true);
                        response.data.data[i]["Portfolio"] = response.data.data[i].displayName;
                        response.data.data[i]["Type"] = response.data.data[i].securityType;
                        response.data.data[i]["Asset Class"] = response.data.data[i].assetAllocation;
                        response.data.data[i]["Sector"] = response.data.data[i].sector;
                        response.data.data[i]["Industry"] = response.data.data[i].industry;
                        response.data.data[i][this.props.styleSettings.userDef1] = response.data.data[i].userDef1;
                        response.data.data[i][this.props.styleSettings.userDef2] = response.data.data[i].userDef2;
                        response.data.data[i][this.props.styleSettings.userDef3] = response.data.data[i].userDef3;
                        response.data.data[i][this.props.styleSettings.userDef4] = response.data.data[i].userDef4;
                        response.data.data[i][this.props.styleSettings.userDef5] = response.data.data[i].userDef5;
                    }
                    //Change the "dataState"
                    var defaultAggs = [{ field: 'quantity', aggregate: 'sum' },
                    { field: 'marketValue', aggregate: 'sum' },
                    { field: 'totalCost', aggregate: 'sum' },
                    { field: 'percentage', aggregate: 'sum' },
                    { field: 'yieldWeighted', aggregate: 'sum' },
                    { field: 'unrealized', aggregate: 'sum' },
                    { field: 'percentUnrealized', aggregate: 'sum' },
                    { field: 'estAnnIncome', aggregate: 'sum' },
                    { field: 'appMarketPrice', aggregate: 'min' },
                    { field: 'appMarketPrice', aggregate: 'max' },
                    { field: 'SecurityCount', aggregate: 'min' },
                    { field: 'SecurityCount', aggregate: 'max' },
                    { field: 'IndvTaxLots', aggregate: 'max' }
                    ];


                    var defaultGroup = [];
                    if (!this.state.portfolio.consolidated)
                        defaultGroup.push({ field: "Portfolio", aggregates: defaultAggs });
                    if (this.state.portfolio.consolidated)
                        defaultGroup.push({ field: "Household", aggregates: defaultAggs });
                    if (this.state.styleSettings.holdingsClassificationGrouping1 && this.state.styleSettings.holdingsClassificationGrouping1 !== 'None') {
                        if (this.state.styleSettings.holdingsClassificationGrouping1 === 'user def 1')
                            defaultGroup.push({ field: this.props.styleSettings.userDef1, aggregates: defaultAggs });
                        else if (this.state.styleSettings.holdingsClassificationGrouping1 === 'user def 2')
                            defaultGroup.push({ field: this.props.styleSettings.userDef2, aggregates: defaultAggs });
                        else if (this.state.styleSettings.holdingsClassificationGrouping1 === 'user def 3')
                            defaultGroup.push({ field: this.props.styleSettings.userDef3, aggregates: defaultAggs });
                        else if (this.state.styleSettings.holdingsClassificationGrouping1 === 'user def 4')
                            defaultGroup.push({ field: this.props.styleSettings.userDef4, aggregates: defaultAggs });
                        else if (this.state.styleSettings.holdingsClassificationGrouping1 === 'user def 5')
                            defaultGroup.push({ field: this.props.styleSettings.userDef5, aggregates: defaultAggs });
                        else
                            defaultGroup.push({ field: (this.state.styleSettings.holdingsClassificationGrouping1 === 'Security Type' ? 'Type' : this.state.styleSettings.holdingsClassificationGrouping1), aggregates: defaultAggs });
                    }
                    if (this.state.styleSettings.holdingsClassificationGrouping2 && this.state.styleSettings.holdingsClassificationGrouping2 !== 'None') {
                        if (this.state.styleSettings.holdingsClassificationGrouping2 === 'user def 1')
                            defaultGroup.push({ field: this.props.styleSettings.userDef1, aggregates: defaultAggs });
                        else if (this.state.styleSettings.holdingsClassificationGrouping2 === 'user def 2')
                            defaultGroup.push({ field: this.props.styleSettings.userDef2, aggregates: defaultAggs });
                        else if (this.state.styleSettings.holdingsClassificationGrouping2 === 'user def 3')
                            defaultGroup.push({ field: this.props.styleSettings.userDef3, aggregates: defaultAggs });
                        else if (this.state.styleSettings.holdingsClassificationGrouping2 === 'user def 4')
                            defaultGroup.push({ field: this.props.styleSettings.userDef4, aggregates: defaultAggs });
                        else if (this.state.styleSettings.holdingsClassificationGrouping2 === 'user def 5')
                            defaultGroup.push({ field: this.props.styleSettings.userDef5, aggregates: defaultAggs });
                        else
                            defaultGroup.push({ field: (this.state.styleSettings.holdingsClassificationGrouping2 === 'Security Type' ? 'Type' : this.state.styleSettings.holdingsClassificationGrouping2), aggregates: defaultAggs });
                    }
                    if (this.state.styleSettings.holdingsClassificationGrouping3 && this.state.styleSettings.holdingsClassificationGrouping3 !== 'None') {
                        if (this.state.styleSettings.holdingsClassificationGrouping3 === 'user def 1')
                            defaultGroup.push({ field: this.props.styleSettings.userDef1, aggregates: defaultAggs });
                        else if (this.state.styleSettings.holdingsClassificationGrouping3 === 'user def 2')
                            defaultGroup.push({ field: this.props.styleSettings.userDef2, aggregates: defaultAggs });
                        else if (this.state.styleSettings.holdingsClassificationGrouping3 === 'user def 3')
                            defaultGroup.push({ field: this.props.styleSettings.userDef3, aggregates: defaultAggs });
                        else if (this.state.styleSettings.holdingsClassificationGrouping3 === 'user def 4')
                            defaultGroup.push({ field: this.props.styleSettings.userDef4, aggregates: defaultAggs });
                        else if (this.state.styleSettings.holdingsClassificationGrouping3 === 'user def 5')
                            defaultGroup.push({ field: this.props.styleSettings.userDef5, aggregates: defaultAggs });
                        else
                            defaultGroup.push({ field: (this.state.styleSettings.holdingsClassificationGrouping3 === 'Security Type' ? 'Type' : this.state.styleSettings.holdingsClassificationGrouping3), aggregates: defaultAggs });
                    }
                    if (IndvTaxLots.toString() === "1" || this.state.showAccountBreakdown) {
                        defaultGroup.push({ field: "securityName", aggregates: defaultAggs });
                    }
                    var sort = []
                    if (this.state.styleSettings.holdingsShowName1) {
                        sort = [{ field: "Name1", dir: "asc" }]
                    }
                    if (this.state.styleSettings.holdingsShowName2) {
                        sort = [{ field: "Name2", dir: "asc" }]
                    }
                    if (this.state.styleSettings.holdingsShowName3) {
                        sort = [{ field: "Name3", dir: "asc" }]
                    }
                    if (this.state.styleSettings.holdingsShowAccountID) {
                        sort = [{ field: "AccountID", dir: "asc" }]
                    }


                    if (this.state.showTaxLots) {
                        switch (this.state.styleSettings.holdingsSortOrderTaxLot) {
                            case 'FIFO':
                                sort = [{ field: 'acqDate', dir: 'asc' }]
                                break
                            case 'LIFO':
                                sort = [{ field: 'acqDate', dir: 'desc' }]
                                break
                            case 'High Cost':
                                sort = [{ field: 'unitCost', dir: 'desc' }]
                                break
                            case 'Low Cost':
                                sort = [{ field: 'unitCost', dir: 'asc' }]
                                break
                            default:
                                sort = [{ field: 'securitySymbol', dir: 'asc' }]
                                break
                        }
                    }
                    else {
                        switch (this.state.styleSettings.holdingsSortOrderDefault) {
                            case 'Symbol':
                                sort = [{ field: 'securitySymbol', dir: 'asc' }]
                                break
                            case 'Security Name':
                                sort = [{ field: 'securityName', dir: 'asc' }]
                                break
                            case 'Market Value (desc)':
                                sort = [{ field: 'marketValue', dir: 'desc' }]
                                break
                            default:
                                sort = [{ field: 'securitySymbol', dir: 'asc' }]
                                break
                        }
                    }
                    //setup the table grouping/totals
                    console.log('prep', response.data.data, this.state.dataState)
                    this.setState({ dataState: { group: defaultGroup, sort: sort }, aggregates: defaultAggs, householdName: householdName, portNames: portNames }, () => {
                        this.setState({ accountHoldings: response.data.data, result: this.addPropertyToItems(this.setHouseholdOrConsolidated(this.reprocess(process(this.preprocess(response.data.data), this.state.dataState)))), loading: false, IndvTaxLots: IndvTaxLots, classificationSortOrder: response.data.classificationSortOrder }, () => {
                            if (response.data.classificationSortOrder && response.data.classificationSortOrder.length > 0) {
                                this.addSortOrderToResult()
                                this.sortClassification(sort[0])
                            }
                            else {
                                this.sortClassification(sort[0])
                            }
                            if (cb) {
                                cb();
                            }
                        });
                    });




                }
            }).catch(err => {
                console.log(err);
            });
    }

    setHouseholdOrConsolidated = (data) => {
        if (this.state.styleSettings.holdingsHouseholdName != null && this.state.styleSettings.holdingsHouseholdName === 'Consolidated'
            && data.data != null && data.data[0] != null && data.data[0].value != null) {
            data.data[0].value = 'Consolidated';
        }
        return data;
    }

    getDate(sqlDate, isUTC) {
        var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
        if (d) {
            var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
            if (isUTC)
                return utc;
            else
                return d;
        }
        else
            return null;
    }

    getHoldDates() {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var payload = {
            company: this.props.user.company,
            port: this.props.portfolio.account,
            household: this.props.household
        }
        axios.post('api/posDates', payload, { headers }).then(response => {
            if (response.data.code === 200) {
                if (response.data.data.length > 0)
                    this.setState({ dates: response.data.data, selectedDate: response.data.data[0].fDate });
                else
                    this.setState({ dates: [] });
            }
        }).catch(err => {
            console.log(err);
        });
    }



    _export;
    export = () => {
        this._export.save();
    }

    sortClassification = (sort) => {
        this.state.result.data.filter(row => row.items != null).forEach(row => {

            if (sort) {
                if (sort.dir === "asc") {
                    row.items.sort((a, b) => ((a.sortOrder || (typeof a.value === 'string' ? a.value.toLowerCase() : a.value)) > (b.sortOrder || (typeof b.value === 'string' ? b.value.toLowerCase() : b.value))) ? 1 : (a.securitySymbol ? a.securitySymbol.toLowerCase() : a[sort.field] > b[sort.field] ? b[sort.field].toLowerCase() : b[sort.field]) ? 1 : -1)
                }
                else {
                    row.items.sort((a, b) => ((a.sortOrder || (typeof a.value === 'string' ? a.value.toLowerCase() : a.value)) > (b.sortOrder || (typeof b.value === 'string' ? b.value.toLowerCase() : b.value))) ? 1 : (a.securitySymbol ? a.securitySymbol.toLowerCase() : a[sort.field] < b[sort.field] ? b[sort.field].toLowerCase() : b[sort.field]) ? 1 : -1)
                }
            }
            else {
                row.items.sort((a, b) => ((a.sortOrder || (typeof a.value === 'string' ? a.value.toLowerCase() : a.value)) > (b.sortOrder || (typeof b.value === 'string' ? b.value.toLowerCase() : b.value))) ? 1 : (a.securitySymbol ? a.securitySymbol.toLowerCase() : a.securitySymbol > b.securitySymbol ? b.securitySymbol.toLowerCase() : b.securitySymbol) ? 1 : -1)
            }


            if (row.field === 'Portfolio') {
                switch (this.state.styleSettings.holdingsHeaderDisplay) {
                    case 'Portfolio Code Only':
                        row.value = this.state.portNames[0].code
                        break
                    case 'Portfolio Name Only':
                        row.value = this.state.portNames[0].name
                        break
                    case 'Portfolio Name and Code':
                        row.value = this.state.portNames[0].name + ' (' + this.state.portNames[0].code + ')'
                        break
                    default:
                        row.value = this.state.portNames[0].code
                        break
                }

            }


            this.sortClassificationChild(row, sort);
        });
        this.forceUpdate();
    }

    sortClassificationChild = (child, sort) => {
        if (child.items) {
            if (sort) {
                if (sort.dir === "asc") {
                    child.items.sort((a, b) => ((a.sortOrder || (typeof a.value === 'string' ? a.value.toLowerCase() : a.value)) > (b.sortOrder || (typeof b.value === 'string' ? b.value.toLowerCase() : b.value))) ? 1 : (a.securitySymbol ? a.securitySymbol.toLowerCase() : a[sort.field] > b[sort.field] ? b[sort.field].toLowerCase() : b[sort.field]) ? 1 : -1)
                }
                else {
                    child.items.sort((a, b) => ((a.sortOrder || (typeof a.value === 'string' ? a.value.toLowerCase() : a.value)) > (b.sortOrder || (typeof b.value === 'string' ? b.value.toLowerCase() : b.value))) ? 1 : (a.securitySymbol ? a.securitySymbol.toLowerCase() : a[sort.field] < b[sort.field] ? b[sort.field].toLowerCase() : b[sort.field]) ? 1 : -1)
                }
            }
            else {
                child.items.sort((a, b) => ((a.sortOrder || (typeof a.value === 'string' ? a.value.toLowerCase() : a.value)) > (b.sortOrder || (typeof b.value === 'string' ? b.value.toLowerCase() : b.value))) ? 1 : (a.securitySymbol ? a.securitySymbol.toLowerCase() : a.securitySymbol > b.securitySymbol ? b.securitySymbol.toLowerCase() : b.securitySymbol) ? 1 : -1)
            }

            if (child.field === 'Portfolio') {
                switch (this.state.styleSettings.holdingsHeaderDisplay) {
                    case 'Portfolio Code Only':
                        child.value = this.state.portNames[0].code
                        break
                    case 'Portfolio Name Only':
                        child.value = this.state.portNames[0].name
                        break
                    case 'Portfolio Name and Code':
                        child.value = this.state.portNames[0].name + ' (' + this.state.portNames[0].code + ')'
                        break
                    default:
                        child.value = this.state.portNames[0].code
                        break
                }
            }

            child.items.forEach(item => {
                this.sortClassificationChild(item, sort);
            })
        }
    }

    addSortOrderToResult = (field) => {
        this.state.result.data.forEach(row => {
            //asdf

            var index = this.state.classificationSortOrder.findIndex(classification => {
                return ((classification.ClassificationDescription === row.field || (classification.ClassificationDescription === 'Industry Group' && row.field === 'Industry'))
                    && classification.ClassificationMemberDescription === row.value);
            })
            if (index >= 0)
                row.sortOrder = this.state.classificationSortOrder[index].ClassificationMemberSortOrder

            this.addSortOrderToResultChild(row, field);
        });
        this.forceUpdate();
    }

    addSortOrderToResultChild = (child, field) => {
        if (child.items) {
            child.items.forEach(item => {
                var index = this.state.classificationSortOrder.findIndex(classification => {
                    return ((classification.ClassificationDescription === item.field || (classification.ClassificationDescription === 'Industry Group' && item.field === 'Industry'))
                        && classification.ClassificationMemberDescription === item.value);
                })
                if (index >= 0)
                    item.sortOrder = this.state.classificationSortOrder[index].ClassificationMemberSortOrder

                this.addSortOrderToResultChild(item, field);
            })
        }
    }

    getFieldTitle = (field) => {

        switch (field) {
            case 'Portfolio':
                return 'All'
            case 'Household':
                return 'All'
            case 'Asset Class':
                return this.state.styleSettings.columnLabelAssetClass
            case 'Type':
                return this.state.styleSettings.columnLabelSecurityType
            case 'Sector':
                return this.state.styleSettings.columnLabelSector
            case ('Industry' || 'Industry Group' || 'industry'):
                return this.state.styleSettings.columnLabelIndustry
            case 'acqDate':
                return this.state.styleSettings.columnLabelAcqDate
            case 'quantity':
                return this.state.styleSettings.columnLabelQuantity
            case 'unitCost':
                return this.state.styleSettings.columnLabelUnitCost
            case 'totalCost':
                return this.state.styleSettings.columnLabelTotalCost
            case 'appMarketPrice':
                return this.state.styleSettings.columnLabelUnitPrice
            case 'marketValue':
                return this.state.styleSettings.columnLabelMarketValue
            case 'percentage':
                return this.state.styleSettings.columnLabelPercentOfAccount
            case 'estAnnIncome':
                return this.state.styleSettings.columnLabelEstAnnualIncome
            case 'yield':
                return this.state.styleSettings.columnLabelYield
            case 'securitySymbol':
                return this.state.styleSettings.columnLabelSymbol
            case 'securityName':
                return this.state.styleSettings.columnLabelSecurityName
            case 'unrealized':
                return this.state.styleSettings.columnLabelUnrealized
            case 'percentUnrealized':
                return this.state.styleSettings.columnLabelPercentUnrealized
            default:
                return null
        }
    }



    render() {

        return (
            <>
                <div className='accountHoldings'>

                    <ExcelExport
                        data={this.state.result.data}
                        collapsible={true}
                        group={this.state.dataState.group}
                        paddingCellOptions={{ background: '#ffffff' }}
                        fileName="Holdings.xlsx"
                        ref={(exporter) => { this._export = exporter; }}
                    >

                        {
                            this.state.aggregates.map((agg, key) => {
                                if (this.state.columns.findIndex(column => {
                                    return column.field === agg.field;
                                }) < 0) {
                                    return (
                                        <ExcelExportColumn
                                            field={agg.field}
                                            title={agg.field}
                                            key={'agg' + key}
                                            //cellOptions={{ format: column.excelFormat }}
                                            hidden={true}
                                            footerCellOptions={{ background: '#ffffff' }}
                                            groupFooterCellOptions={{ textAlign: 'right' /*format: column.excelFormat*/ }}
                                            groupFooter={(e) => this.CustomGroupFooter(e)}
                                        //footer={(e) => this.CustomFooter(e)} 
                                        //width={this.setWidth(column.minWidth)} 
                                        />)
                                } else {
                                    return (<></>)
                                }


                            })
                        }
                        {
                            this.state.dataState.group.map((group, key) => {
                                if (this.state.columns.findIndex(column => {
                                    return column.field === group.field;
                                }) < 0) {
                                    return (
                                        <ExcelExportColumn
                                            field={group.field}
                                            title={this.getFieldTitle(group.field) || group.title}
                                            key={(key + 1) * 10000}
                                            hidden={true}
                                            //cellOptions={{ format: column.excelFormat }}
                                            footerCellOptions={{ background: '#ffffff' }}
                                            groupHeader={(e) => this.CustomGroupHeader(e)}
                                            groupHeaderCellOptions={{ background: '#ffffff' }}
                                        //groupFooterCellOptions={{ background:'#ffffff' }}
                                        //width={this.setWidth(column.minWidth)} 
                                        />)
                                } else {
                                    return (<></>)
                                }


                            })
                        }
                        {
                            this.state.columns.map((column, key) => {
                                if (column.field === 'AccountID' && !this.state.styleSettings.holdingsShowAccountID)
                                    return <></>
                                if (column.field === 'Name1' && !this.state.styleSettings.holdingsShowName1)
                                    return <></>
                                if (column.field === 'Name2' && !this.state.styleSettings.holdingsShowName2)
                                    return <></>
                                if (column.field === 'Name3' && !this.state.styleSettings.holdingsShowName3)
                                    return <></>
                                if (column.field === 'securityName') {
                                    return column.show &&
                                        <ExcelExportColumn
                                            field={column.field}
                                            title={this.getFieldTitle(column.field) || column.title}
                                            key={(key + 1000)}
                                            cellOptions={{ format: column.excelFormat, background: '#ffffff' }}
                                            //footerCellOptions={{ wrap: true, textAlign: 'center' }}
                                            groupHeader={(e) => this.CustomGroupHeader(e)}
                                            groupHeaderCellOptions={{ background: '#ffffff' }}
                                            groupFooterCellOptions={{ textAlign: 'left', }}
                                            groupFooter={(e) => this.CustomGroupFooter(e)}
                                            footerCellOptions={{ background: '#ffffff' }}
                                            //footer={(e) => this.CustomFooter(e)} 
                                            width={this.setWidth(isNaN(parseInt(column.minWidth))) ? 50 : parseInt(column.minWidth)}
                                        />
                                }

                                var aggIndex = this.state.origAggregates.findIndex(agg => {
                                    return agg.field === column.field;
                                });



                                if (aggIndex >= 0 && column.field !== 'quantity') {
                                    return column.show &&
                                        <ExcelExportColumn
                                            field={column.field}
                                            title={this.getFieldTitle(column.field) || column.title}
                                            key={(key + 1) * 100}
                                            cellOptions={{ format: column.excelFormat, background: '#ffffff' }}
                                            //footerCellOptions={{ wrap: true, textAlign: 'center' }}
                                            groupFooterCellOptions={{ textAlign: 'right', format: column.excelFormat, }}
                                            groupFooter={(e) => this.CustomGroupFooter(e)}
                                            footerCellOptions={{ background: '#ffffff' }}
                                            //footer={(e) => this.CustomFooter(e)} 
                                            width={this.setWidth(isNaN(parseInt(column.minWidth))) ? 50 : parseInt(column.minWidth)}
                                        />
                                }

                                var groupIndex = this.state.dataState.group.findIndex(group => {
                                    return group.field === column.field;
                                });

                                if (groupIndex >= 0) {
                                    return column.show &&
                                        <ExcelExportColumn
                                            field={column.field}
                                            title={this.getFieldTitle(column.field) || column.title}
                                            key={(key + 1) * 10}
                                            cellOptions={{ format: column.excelFormat, background: '#ffffff' }}
                                            groupHeader={(e) => this.CustomGroupHeader(e)}
                                            groupHeaderCellOptions={{ background: '#ffffff' }}
                                            footerCellOptions={{ background: '#ffffff' }}
                                            //groupFooterCellOptions={{ background:'#ffffff' }}
                                            width={this.setWidth(isNaN(parseInt(column.minWidth))) ? 50 : parseInt(column.minWidth)}
                                        />
                                }



                                // this.state.aggregates.forEach(thisAgg =>{
                                //   if (column.field === thisAgg.field){
                                //     return column.show && 
                                //       <ExcelExportColumn 
                                //         field={column.field} 
                                //         title={column.title} 
                                //         key={key} 
                                //         //cellOptions={{ format: column.format }}
                                //         footerCellOptions={{ wrap: true, textAlign: 'center' }}
                                //         groupFooterCellOptions={{ textAlign: 'right' }}
                                //         groupFooter={(e) => this.CustomGroupFooter(e)}
                                //         footer={(e) => this.CustomFooter(e)} 
                                //         width={this.setWidth(column.minWidth)} 
                                //       />
                                //   }
                                // })



                                return column.show &&
                                    <ExcelExportColumn
                                        field={column.field}
                                        title={this.getFieldTitle(column.field) || column.title}
                                        key={key}
                                        cellOptions={{ format: column.excelFormat, background: '#ffffff' }}
                                        footerCellOptions={{ background: '#ffffff' }}
                                    //groupFooterCellOptions={{ background:'#ffffff' }}
                                    // footerCellOptions={{ wrap: true, textAlign: 'center' }}
                                    // groupFooterCellOptions={{ textAlign: 'right' }}
                                    // groupFooter={CustomGroupFooter}
                                    // footer={CustomFooter} 
                                    // width={this.setWidth(column.minWidth)} 
                                    />


                            })
                        }
                    </ExcelExport>
                    {/* <ExcelExport data={this.state.accountHoldings} fileName={'Holdings.xlsx'} ref={(exporter) => { this._export = exporter; }}> */}
                    <Grid data={this.state.result}
                        groupable={{ footer: 'always' }}
                        className='noGroupGridLines'
                        resizable={true}
                        reorderable={true}
                        filterable={false}
                        sortable={true}
                        sort={this.state.sort}
                        onDataStateChange={this.changeDataState}
                        onExpandChange={this.expandChange}
                        expandField="expanded"
                        cellRender={this.cellRender}
                        headerCellRender={this.headerCellRender}
                        {...this.state.dataState}>
                        <GridNoRecords>
                            {this.state.loading && 'Loading...'}
                            {!this.state.loading && 'No records available'}
                        </GridNoRecords>
                        <GridToolbar>
                            {this.state.styleSettings.chartShowAsOf && <><label>As Of: </label><select onChange={(e) => { this.setState({ selectedDate: e.target.value }); this.getAccountHoldings(e.target.value, () => this.setMinGridWidth()) }}>
                                {this.state.dates.map((date, i) => (
                                    <option key={i} value={date.fDate}>{date.fDate}</option>
                                ))}
                            </select></>}
                            {!this.state.styleSettings.chartShowAsOf && <label>As Of: {this.state.selectedDate}</label>}
                            <Button icon="excel" title='Export to Excel' onClick={this.export}>Export</Button>
                            {/* <Button icon="pdf" title='Export to PDF' onClick={this.exportPDFWithComponent}>Export</Button> */}
                            {/* <Button title='Expand' onClick={(e) => this.expand()}>Expand All</Button>
              <Button title='Collapse' onClick={(e) => this.collapse()}>Collapse All</Button> */}
                            <Button title='Expand' onClick={(e) => this.expand()}>Expand All</Button>
                            {this.state.dataState.group.map(group => (<>
                                {/* <Button title='Expand' onClick={(e) => this.expand(group.field)}>Expand {group.field}</Button> */}
                                {group.field !== 'Portfolio' && group.field !== 'Household' &&
                                    <Button title='Collapse' onClick={(e) => this.expandCollapse(group.field)}>Collapse/Expand {this.getFieldTitle(group.field) || group.field}</Button>
                                }
                            </>
                            ))}
                            {!(this.props.user.role === 'user' && this.state.styleSettings.holdingsShowLumpedPositions) &&
                                <label><input className='bigger' type="checkbox" name="checkbox" value="value" checked={this.state.showTaxLots} onChange={(e) => { this.setState({ showTaxLots: !this.state.showTaxLots }, () => { this.getAccountHoldings(this.state.selectedDate, () => this.setMinGridWidth()) }); }} />Show Individual Tax Lots</label>}
                            {(this.state.styleSettings.holdingsShowAccountID ||
                                this.state.styleSettings.holdingsShowName1 ||
                                this.state.styleSettings.holdingsShowName2 ||
                                this.state.styleSettings.holdingsShowName3) &&
                                <label><input className='bigger' type="checkbox" name="checkbox" value="value" checked={this.state.showAccountBreakdown} onChange={(e) => { this.setState({ showAccountBreakdown: !this.state.showAccountBreakdown }, () => { this.getAccountHoldings(this.state.selectedDate, () => this.setMinGridWidth()) }); }} />Show Account Breakdown</label>
                            }


                        </GridToolbar>
                        {

                            this.state.columns.map((column, key) => {
                                if (column.field === 'AccountID' && (!this.state.styleSettings.holdingsShowAccountID || !this.state.showAccountBreakdown))
                                    return <></>
                                if (column.field === 'Name1' && (!this.state.styleSettings.holdingsShowName1 || !this.state.showAccountBreakdown))
                                    return <></>
                                if (column.field === 'Name2' && (!this.state.styleSettings.holdingsShowName2 || !this.state.showAccountBreakdown))
                                    return <></>
                                if (column.field === 'Name3' && (!this.state.styleSettings.holdingsShowName3 || !this.state.showAccountBreakdown))
                                    return <></>
                                else
                                    return column.show && <GridColumn field={column.field} title={this.getFieldTitle(column.field) || column.title} template={column.template} sortable={column.sortable} key={key} format={column.format} headerClassName={column.headerClass} width={this.setWidth(column.minWidth)} />
                            })
                        }
                    </Grid>
                    {/* </ExcelExport> */}
                    {this.state.loading && <GridLoading />}

                </div>
                {!this.state.loading &&
                    <div style={{ position: "absolute", left: "-9001px", top: 0 }}>
                        <PDFExport ref={(component) => this.pdfExportComponent = component} paperSize="A4" landscape={true} scale={0.5} repeatHeaders={false}>
                            <Grid
                                ref={(grid) => this.grid = grid}
                                data={this.state.result}
                                groupable={{ footer: 'always' }}
                                className='noGroupGridLines'
                                resizable={true}
                                reorderable={true}
                                filterable={false}
                                sortable={true}
                                sort={this.state.sort}
                                onDataStateChange={this.changeDataState}
                                onExpandChange={this.expandChange}
                                expandField="expanded"
                                cellRender={this.cellRender}
                                headerCellRender={this.headerCellRender}
                                {...this.state.dataState}>
                                <GridNoRecords>
                                    {this.state.loading && 'Loading...'}
                                    {!this.state.loading && 'No records available'}
                                </GridNoRecords>
                                {

                                    this.state.columns.map((column, key) => {
                                        if (column.field === 'AccountID' && !this.state.styleSettings.holdingsShowAccountID)
                                            return <></>
                                        if (column.field === 'Name1' && !this.state.styleSettings.holdingsShowName1)
                                            return <></>
                                        if (column.field === 'Name2' && !this.state.styleSettings.holdingsShowName2)
                                            return <></>
                                        if (column.field === 'Name3' && !this.state.styleSettings.holdingsShowName3)
                                            return <></>
                                        else
                                            return column.show && <GridColumn field={column.field} title={column.title} template={column.template} sortable={column.sortable} key={key} format={column.format} headerClassName={column.headerClass} width={this.setWidth(column.minWidth)} />
                                    })
                                }
                            </Grid>
                        </PDFExport>
                    </div>
                }
            </>
        );
    }

    recalculatePercentages = (data, pctMultiplier, level) => {
        if (data.aggregates != null) {
            // recalculate percentages;
            data.aggregates.percentage.sum *= pctMultiplier;
            if (data.aggregates.marketValue.sum > 0 && data.aggregates.marketValue.sum < .000000001) data.aggregates.marketValue.sum = '';
            if (data.aggregates.totalCost.sum > 0 && data.aggregates.totalCost.sum < .000000001) data.aggregates.totalCost.sum = '';
            if (data.aggregates.unrealized.sum > 0 && data.aggregates.unrealized.sum < .000000001) data.aggregates.unrealized.sum = '';
            if (level > 1 && data.aggregates.percentage.sum === 0) {
                data.aggregates.percentage.sum = '';
            }
        }
        if (data.items != null && data.items.length > 0 && data.items.findIndex(item => item.field != null) > -1) {
            data.items.forEach(item => {
                this.recalculatePercentages(item, pctMultiplier, level + 1);
            })
        } else if (data.items != null && data.items.length > 0) {
            data.items = data.items.map(row => {
                let obj = { ...row }
                if (
                    (String(row.Industry).toLowerCase() === 'not applicable' ||
                        String(row.Sector).toLowerCase() === 'not applicable' ||
                        String(row.industry).toLowerCase() === 'not applicable' ||
                        String(row.sector).toLowerCase() === 'not applicable') &&
                    obj.marketValue > 0 && obj.marketValue < .000000001
                ) {
                    obj['marketValue'] *= 100000000000000000000;
                    obj['totalCost'] *= 100000000000000000000;
                    obj['unrealized'] *= 100000000000000000000;
                    obj['Industry'] = '';
                    obj['industry'] = '';
                    obj['Sector'] = '';
                    obj['sector'] = '';
                }
                return obj;
            })
        }
        return data;
    }

    preprocess = (data) => {
        // data = data.map(row => {
        //     let obj = {...row}
        // console.log(obj.Industry,obj.Sector,obj.industry,obj.sector,obj)
        // if (
        //     String(obj.Industry).toLowerCase() === 'not applicable' ||
        //     String(obj.industry).toLowerCase() === 'not applicable')
        //     {
        //         (obj.Industry) = '';
        //         (obj.industry) = '';
        //         // obj['marketValue'] /= 100000000000000000000;
        //         // obj['totalCost'] /= 100000000000000000000;
        //         // obj['unrealized'] /= 100000000000000000000;
        //         // obj['percentage'] = 0;
        // } 
        // if (
        //     String(obj.Sector).toLowerCase() === 'not applicable' ||
        //     String(obj.sector).toLowerCase() === 'not applicable') {
        //         (obj.Sector) = '';
        //         (obj.sector) = '';
        //         // obj['marketValue'] /= 100000000000000000000;
        //         // obj['totalCost'] /= 100000000000000000000;
        //         // obj['unrealized'] /= 100000000000000000000;
        //         // obj['percentage'] = 0;
        // } 
        //     if (obj.items != null && obj.items.length > 0) {
        //         obj.items = this.preprocess(obj.items);
        //     }

        //     if (((String(obj.Sector).toLowerCase() === "not applicable" || String(obj.sector).toLowerCase() === "not applicable")
        //         )) {
        //             // obj.Sector = '';
        //             obj.sector = '';
        //     }
        //     if (((String(obj.Industry).toLowerCase() === "not applicable" || String(obj.industry).toLowerCase() === "not applicable")  
        //         )) {
        //             obj.Industry = '';
        //             obj.industry = '';
        //     }
        //     return obj;
        // })
        return data;
    }

    reprocess = (data) => {
        // let pctMultiplier = data.data[0].aggregates != null ? (1 / data.data[0].aggregates.percentage.sum) : 1;
        // data.data[0] = this.recalculatePercentages(data.data[0],pctMultiplier,0);
        return data;
    }

    changeDataState = (e) => {
        console.log('changeDataState')
        const groups = e.dataState.group;
        if (groups) {
            groups.map(group => group.aggregates = this.state.aggregates);
        }
        var newData = this.reprocess(process(this.preprocess(this.state.accountHoldings), e.dataState));
        var sort = null
        if (e.dataState.sort && e.dataState.sort.length > 0) {
            sort = e.dataState.sort[0]
        }
        this.setState({ dataState: e.dataState, result: this.addPropertyToItems(this.setHouseholdOrConsolidated(newData)) }, () => {
            if (this.state.classificationSortOrder && this.state.classificationSortOrder.length > 0) {
                this.addSortOrderToResult();
                this.sortClassification(sort);
            }
        });
    }

    formatCurrency(val) {
        var formatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(val);
        return formatted;
    }

    formatQuantity(val) {
        var nfObject = new Intl.NumberFormat('en-US');
        var formatted = nfObject.format(val);
        return formatted;
    }

    formatPercent(val) {
        //im not sure why the decimal place ends up in a different position when you run with show tax lots vs not show...This if statement fixes the issue but there must be a better solution...
        if (!this.state.showTaxLots)
            return (val * 100).toFixed(2) + "%";
        else
            return (val).toFixed(2) + "%";
    }

    cellRender(tdElement, cellProps) {
        // if (((cellProps.field === 'Industry' && (String(cellProps.dataItem.Industry).toLowerCase() === "not applicable" || String(cellProps.dataItem.industry).toLowerCase() === "not applicable")) || 
        //     (cellProps.field === 'Sector' && (String(cellProps.dataItem.Sector).toLowerCase() === "not applicable" || String(cellProps.dataItem.sector).toLowerCase() === "not applicable"))
        //     ) && cellProps.rowType === 'data' ) {
        //         return <td colSpan='1' style={{ backgroundColor: color }}></td>;
        // }
        if (cellProps.dataItem.aggregates != null && cellProps.dataItem.aggregates.marketValue != null && cellProps.dataItem.aggregates.marketValue.sum > 0 && cellProps.dataItem.aggregates.marketValue.sum < .00000000001) {
            this.counter++;
        }
        if (tdElement && tdElement.props.children && cellProps.rowType === "groupHeader") {
            if (cellProps.dataItem.field === 'Household' && this.state.showHouseholdName && this.props.user.role === 'user') {
                let children = (
                    <span>
                        <div tabindex="-1" class="k-i-collapse k-icon"></div>
                        {this.state.householdName}
                    </span>
                );
                return React.cloneElement(tdElement, tdElement.props, children);
            }
        }
        if (cellProps.rowType === 'groupFooter') {
            var color = this.state.styleSettings.holdingsSubtotalColor3

            if (cellProps.level === 0) {//grand total row
                color = this.state.styleSettings.holdingsGrandTotalColor
            }
            if (cellProps.level === 1) {//subtotal1 row
                color = this.state.styleSettings.holdingsSubtotalColor1
            }
            if (cellProps.level === 2) {//subtotal2 row
                color = this.state.styleSettings.holdingsSubtotalColor2
            }
            if (cellProps.level === 3) {//subtotal3 row
                color = this.state.styleSettings.holdingsSubtotalColor3
            }
            if (cellProps.level === this.state.dataState.group.length - 1
                && cellProps.dataItem.items.length <= 1
                && cellProps.dataItem.field === 'securityName'
                && cellProps.dataItem.expanded !== false
                && this.state.showTaxLots) {
                return <></>
            }
            if (cellProps.field !== 'value' && cellProps.field !== 'Portfolio' && cellProps.field !== 'securityName' && cellProps.field !== 'Asset Class' && cellProps.field !== 'Sector'
                && cellProps.field !== 'Type' && cellProps.field !== 'Industry' && cellProps.field !== this.props.styleSettings.userDef1
                && cellProps.field !== this.props.styleSettings.userDef2 && cellProps.field !== this.props.styleSettings.userDef3 && cellProps.field !== this.props.styleSettings.userDef4
                && cellProps.field !== this.props.styleSettings.userDef5 && cellProps.field !== 'acqDate') {
                var val = '';

                if (cellProps.field === 'yield') {
                    if (cellProps.dataItem.aggregates["marketValue"].sum !== 0) {
                        val = (cellProps.dataItem.aggregates["yieldWeighted"].sum / cellProps.dataItem.aggregates["marketValue"].sum * 100);
                    }
                }

                else if (cellProps.field === 'appMarketPrice') {
                    if (cellProps.dataItem.aggregates["SecurityCount"].min === cellProps.dataItem.aggregates["SecurityCount"].max && cellProps.dataItem.aggregates["IndvTaxLots"].max === 1) {
                        val = cellProps.dataItem.aggregates[cellProps.field].min;
                        if (val === 0) {
                            val = '';
                        }
                    }
                }
                else if (cellProps.field === 'quantity') {
                    if (cellProps.dataItem.aggregates["SecurityCount"].min === cellProps.dataItem.aggregates["SecurityCount"].max && cellProps.dataItem.aggregates["IndvTaxLots"].max === 1) {
                        val = cellProps.dataItem.aggregates[cellProps.field].sum;
                        if (val === 0) {
                            val = '';
                        }
                    }
                }
                else if (cellProps.field === 'unitCost') {
                    if (cellProps.dataItem.aggregates["SecurityCount"].min === cellProps.dataItem.aggregates["SecurityCount"].max && cellProps.dataItem.aggregates["IndvTaxLots"].max === 1) {
                        if (cellProps.dataItem.aggregates["quantity"].sum !== 0) {
                            val = cellProps.dataItem.aggregates["totalCost"].sum / cellProps.dataItem.aggregates["quantity"].sum;
                        }
                        if (val === 0) {
                            val = '';
                        }
                    }
                }

                else if (cellProps.field === 'percentUnrealized'){
                    val = cellProps.dataItem.aggregates.unrealized.sum / cellProps.dataItem.aggregates.totalCost.sum;
                }

                else if (cellProps.dataItem.aggregates[cellProps.field]) {
                    val = cellProps.dataItem.aggregates[cellProps.field].sum;
                }


                if (val === null) {
                    val = '';
                }

                if (val !== '') {
                    if (cellProps.field === 'percentage' || cellProps.field === 'yield') {
                        if (this.state.showTaxLots) {
                            val = val * 100
                        }
                        val = this.formatPercent(val, 2);
                    }
                    else if (cellProps.field === 'percentUnrealized') {
                        val = this.formatPercent(val, 2);
                    }
                    else if (cellProps.field === 'quantity') {
                        val = this.formatQuantity(val);
                    }
                    else {
                        val = this.formatCurrency(val);
                    }
                }


                return (
                    <td style={{ textAlign: 'right', backgroundColor: color }}>
                        <strong>{val}</strong>
                    </td>
                );

            }
            else if (cellProps.field === 'Portfolio') {
                return (<></>);
            }
            else if (cellProps.field === 'securityName') {
                if ((cellProps.dataItem.field === 'Industry' || cellProps.dataItem.field === 'Sector') && cellProps.dataItem.value === '') {
                    return (<td colSpan='1' style={{ backgroundColor: color }}></td>)
                }
                if (cellProps.dataItem.field === 'Household' && this.state.showHouseholdName && this.props.user.role === 'user') {
                    return (
                        <td colSpan='1' style={{ backgroundColor: color }}>
                            <strong>{this.state.householdName} Totals:</strong>
                        </td>
                    );
                }
                return (
                    <td colSpan='1' style={{ backgroundColor: color }}>
                        <strong>{cellProps.dataItem.value} Totals:</strong>
                    </td>
                );
            }
            if (cellProps.dataIndex === -1 && cellProps.field !== 'value') {
                return (
                    <td colSpan='1' style={{ backgroundColor: color }}>
                        <></>
                    </td>
                );
            }

        }
        if (tdElement !== null) {
            var style = { backgroundColor: this.state.styleSettings.holdingsDataRowColor };
            if (cellProps.field === 'acqDate') {
                var acqDate = new Date(cellProps.dataItem[cellProps.field])
                if (acqDate.getUTCFullYear() === 1901) {
                    return (
                        <td {...tdElement.props} style={style}> </td>
                    );
                }
            }

            else if (cellProps.field === 'quantity' && cellProps.dataItem[cellProps.field] === 0) {
                return (
                    <td {...tdElement.props} style={style}> </td>
                );
            }

            if (cellProps.field === 'Industry' && cellProps.dataItem[cellProps.field] === "Not Applicable") {
                return (
                    <td colSpan='1'></td>
                );
            }

            if (cellProps.field === 'Sector' && cellProps.dataItem[cellProps.field] === "Not Applicable") {
                return (
                    <td colSpan='1'></td>
                );
            }

            if (cellProps.field === 'quantity' || cellProps.field === 'marketValue' || cellProps.field === 'percentage'
                || cellProps.field === 'totalCost' || cellProps.field === 'unrealized' || cellProps.field === 'yield' || cellProps.field === 'percentUnrealized'
                || cellProps.field === 'estAnnIncome' || cellProps.field === 'appMarketPrice' || cellProps.field === 'unitCost')
                style = { textAlign: 'right', backgroundColor: this.state.styleSettings.holdingsDataRowColor };


            if (cellProps.field === 'percentUnrealized' && (!cellProps.dataItem.totalCost || cellProps.dataItem.totalCost === 0)){
                return (
                    <td {...tdElement.props} style={style} >{''}</td>
                );
            }
            

            return (
                <td {...tdElement.props} style={style} />
            );
        }
        else {
            return tdElement;
        }
    }

    headerCellRender(thElement, cellProps) {
        if (thElement !== null) {
            var style = {};
            if (cellProps.field === 'quantity' || cellProps.field === 'marketValue' || cellProps.field === 'percentage'
                || cellProps.field === 'totalCost' || cellProps.field === 'unrealized' || cellProps.field === 'yield' || cellProps.field === 'percentUnrealized'
                || cellProps.field === 'estAnnIncome' || cellProps.field === 'appMarketPrice' || cellProps.field === 'unitCost')
                style = { justifyContent: 'right' };

            return (
                <div {...thElement.props} style={style} />
            );
        }
        else {
            return thElement;
        }
    }

    // expandChange = (event) => {
    //     event.dataItem[event.target.props.expandField] = event.value;
    //     this.forceUpdate();
    // }

    expandChange = (event) => {
        const expandField = event.target.props.expandField;
        const itemId = event.dataItem.id;

        const updateItemsRecursive = (items) => {
            return items.map(item => {
                let updatedItem = item;
                if (item.id === itemId) {
                    updatedItem = {
                        ...item,
                        [expandField]: !item[expandField],
                    };
                }
                if (Array.isArray(item.items)) updatedItem.items = updateItemsRecursive(item.items);
                return updatedItem;
            });
        };

        const updatedData = updateItemsRecursive(this.state.result.data);

        this.setState((prevState) => ({
            ...prevState,
            result: {
                ...prevState.result,
                data: updatedData
            }
        }));
    }

    addPropertyToItems = (obj) => {
        // Usage: (call in both initial data set and prior to any datagrid result state change)
        // result = this.addPropertyToItems(result);

        const addPropertyToNestedItems = (item) => {
            item.id = Math.random();
            item.expanded = true;

            // Recursively call this function for each item in the 'items' array
            if (Array.isArray(item.items)) item.items.forEach(subItem => addPropertyToNestedItems(subItem));
            return item;
        }

        // Traverse the array and add properties
        if (Array.isArray(obj.data)) obj.data.forEach(item => addPropertyToNestedItems(item));
        return obj;
    }

    expand = () => {
        const newData = this.state.result.data.map(row => {
            const newRow = { ...row };
            newRow.expanded = true;
            newRow.items = this.expandItems(newRow.items);
            return newRow;
        });
        this.setState(prevState => ({
            result: {
                ...prevState.result,
                data: newData
            }
        }));
    };

    expandItems = (items) => {
        if (!items) return [];
        return items.map(item => {
            const newItem = { ...item };
            newItem.expanded = true;
            newItem.items = this.expandItems(newItem.items);
            return newItem;
        });
    };

    expandCollapse = (field) => {
        const newData = this.state.result.data.map(row =>
            this.expandCollapseItems(row, field)
        );
        this.setState(prevState => ({
            result: {
                ...prevState.result,
                data: newData
            }
        }));
    };

    expandCollapseItems = (item, field) => {
        const newItem = { ...item };
        if (!field || field === newItem.field) {
            newItem.expanded = !newItem.expanded;
        }
        if (newItem.items) {
            newItem.items = newItem.items.map(child => this.expandCollapseItems(child, field));
        }
        return newItem;
    };

}

export default AccountHoldings;





