import React, { Component } from "react";
import { Button } from '@progress/kendo-react-buttons';
import {Grid, GridColumn, GridToolbar} from '@progress/kendo-react-grid';
import axios from 'axios';
import { Upload } from '@progress/kendo-react-upload';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { process } from '@progress/kendo-data-query';
import MapUserHHPopUp from "./MapUserHHPopUp";

class HouseholdUsers extends Component {

  constructor(props) {
    super(props);
    this.state = {
        files:[],
        previewUsers:[],
        users:[],
        result:[],
        dataState:{sort:[{field:'household', dir:'asc'}], skip: 0, take: 25 },
        loading:false
    }

    this.getHouseholdUsers=this.getHouseholdUsers.bind(this);
    this.clearState=this.clearState.bind(this);
    this.refresh=this.refresh.bind(this);
  }

  componentDidMount(){
    this.getHouseholdUsers();
  }

  clearState(){
    this.setState({
        files:[],
        previewUsers:[]
    });
  };

  refresh(){
    this.getHouseholdUsers();
  }

  onAdd = (e)=>{
      this.setState({files:e.newState});
  }

  onRemove = (e)=>{
    this.setState({files:e.newState});
  }

  onStatusChange = (e)=>{
      if(e.response.response.code===200){
        e.newState[0].progress=100;
        e.newState[0].status=4;
        this.setState({previewUsers:e.response.response.users});
      }
      else{
        e.newState[0].progress=0;
        e.newState[0].status=0;
        e.newState[0].error = e.response.response.error;
      }
      this.setState({files:e.newState});
  }

  onBeforeUpload = (e)=>{
    e.additionalData.company=this.props.user.company;
  }

  getHouseholdUsers(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    this.setState({loading:true});

    var payload = {
      company:this.props.user.company
    }

    axios.post('api/householdUsersGet', payload, {headers}).then(response=>{
      var result = process(response.data.users, this.state.dataState);
      this.setState({users:response.data.users,result:result, loading:false});
    }).catch(err=>{
      console.log(err);
      this.setState({users:[], loading:false});
    });
  }

  uploadHouseholdUsers(e){
    
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var formData = new FormData();

    formData.append('company', this.props.user.company);
    formData.append('upload', true);
    formData.append('file' , this.state.files[0].getRawFile());

    axios.post('api/householdUsers?company='+this.props.user.company, formData, {headers}).then(response=>{
        if(response.data.code===200){
            alert("Household User mappings have been successfully uploaded.");
            this.getHouseholdUsers();
            this.clearState();
        }
        else{
            alert("An error has occurred while uploading. Please try again.");
            this.getHouseholdUsers();
            this.clearState();
        }
    }).catch(err=>{
        alert("An error has occurred while uploading. Please try again.");
        this.getHouseholdUsers();
        this.clearState();
    });

  }

  renderFile = (props)=>{
    const { files } = props;

      return (
      <ul>
          {
            files.map(file =>{
            return file.error ?
            <>
            <div style={{color:'red'}}key={file.name}>
                <div>{file.name}</div>
                <div>Error: {file.error}</div>
            </div>
            </>
            :
            <div style={{color:'green'}}key={file.name}>
                <div>{file.name}</div>
                <div>{this.state.previewUsers.length} user mappings found.</div>
                <Button style={{textAlign:'right', marginLeft:'10px'}} onClick={e=>this.uploadHouseholdUsers(e)}>Confirm Upload</Button>
                </div>
            })          
          }
      </ul>);
  }

  _export;
  export = () =>{
    this._export.save();
  }



  changeDataState = (e)=>{
    var newData = process(this.state.users, e.dataState);
    this.setState({dataState:e.dataState, result:newData});
  }

  render() {
    return(
        <div>
          <ExcelExport data={this.state.users} fileName={'HouseholdUsers_Export.xlsx'} ref={(exporter)=>{this._export = exporter;}}>
            <ExcelExportColumn field='household' title='Household'/>          
            <ExcelExportColumn field='nickname' title='User'/>
            <ExcelExportColumn field='email' title='Email'/>
          </ExcelExport>



            <table className='fullWidth'>
                <tbody>
                    <tr>
                        <td><h3>Upload Tab-Delimited Household User Mapping:</h3>
                          <div>Format should be household [tab] email</div>
                          <div><strong>Note:</strong> This will overwrite the current household user mappings.</div>
                        </td>
                        <td style={{textAlign: 'right'}}><Upload autoUpload={true} 
                                    multiple={false} 
                                    files={this.state.files}
                                    listItemUI={this.renderFile}
                                    onAdd={this.onAdd}
                                    onRemove={this.onRemove}
                                    onStatusChange={this.onStatusChange}
                                    onBeforeUpload={this.onBeforeUpload}
                                    saveUrl={'api/householdUsers?company='+this.props.user.company}
                                    saveHeaders={{'authorization': 'Bearer '.concat(this.props.idToken)}}/></td>
                    </tr>
                </tbody>
            </table>
            <br/>
            
            
            <div className='houseMappingList'>
                <Grid 
                data={this.state.result}
                sortable={true}
                filterable={true}
                pageable={{ pageSizes: [10, 25, 50, 100, 250, 500] }}
                onDataStateChange={this.changeDataState}
                {...this.state.dataState}
                >

                <GridToolbar>
              <Button icon="excel" title='Export to Excel' onClick={this.export}>Export</Button>
              <MapUserHHPopUp user={this.props.user} householdUsers={this.state.users} refresh={this.refresh} idToken={this.props.idToken} company={this.props.user.company} button={<Button icon="plus" className='docButton'>Add Mapping</Button>}/>
            </GridToolbar>


                    <GridColumn headerClassName='gridHeader' field='household' title='Household'/>
                    <GridColumn headerClassName='gridHeader' field='nickname' title='User'/>
                    <GridColumn headerClassName='gridHeader' field='email' title='Email'/>

                </Grid>
            </div>
        </div>
    );
  }

}

export default HouseholdUsers;