import React, { Component } from "react";
import { Grid, GridColumn, GridToolbar, GridNoRecords } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import GridLoading from '../../Loaders/GridLoading';
import { parseDate } from '@telerik/kendo-intl';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Button } from "@progress/kendo-react-buttons";
import axios from 'axios';

const ADJUST_PADDING = 4;
const COLUMN_MIN = 4;

class FixedIncomeAccountHoldings extends Component {

  constructor(props) {
    super(props);

    this.minGridWidth = 0;

    var columns = [];

    // if (props.compSettings.ShowFixedHoldQuantity)
    //   columns.splice(0, 0, { field: 'quantity', title: 'Par', format: "{0:n}", headerClass: 'gridHeader', minWidth: 100 });
    // if (props.compSettings.ShowFixedHoldMoodyRating)
    //   columns.splice(0, 0, { field: 'MoodyRating', title: 'Moody Rating', headerClass: 'gridHeader', minWidth: 100 });
    // if (props.compSettings.ShowFixedHoldSPRating)
    //   columns.splice(0, 0, { field: 'SPRating', title: 'S&P Rating', headerClass: 'gridHeader', minWidth: 100 });
    // if (props.compSettings.ShowFixedHoldCusip)
    //   columns.splice(0, 0, { field: 'securitySymbol', title: 'Cusip', headerClass: 'gridHeader', minWidth: 90 });



    // if (props.compSettings.ShowFixedHoldSector)
    //   columns.splice(0, 0, { field: 'Sector', title: 'Sector', headerClass: 'gridHeader', minWidth: 150 });
    // if (props.compSettings.ShowFixedHoldInd)
    //   columns.splice(0, 0, { field: 'Industry', title: 'Industry Group', headerClass: 'gridHeader', minWidth: 150 });
    // if (props.compSettings.ShowFixedHoldSecType)
    //   columns.splice(0, 0, { field: 'Type', title: 'Security Type', headerClass: 'gridHeader', minWidth: 150 });
    // if (props.compSettings.ShowFixedHoldAsset)
    //   columns.splice(0, 0, { field: 'Asset Class', title: 'Asset Class', headerClass: 'gridHeader', minWidth: 150 });

    // if (props.compSettings.ShowFixedHoldInterestDividendRate)
    //   columns.splice(9, 0, { field: 'InterestDividendRate', title: 'Coupon', format: "{0:p2}", headerClass: 'gridHeader', minWidth: 75 });
    // if (props.compSettings.ShowFixedHoldNextCallDate)
    //   columns.splice(10, 0, { field: 'CallDate1', title: 'Next Call Date', format: "{0:d}", headerClass: 'gridHeader', minWidth: 110 });
    // if (props.compSettings.ShowFixedHoldMaturityDate)
    //   columns.splice(11, 0, { field: 'MaturityDate', title: 'Maturity Date', format: "{0:d}", headerClass: 'gridHeader', minWidth: 110 });
    // if (props.compSettings.ShowFixedHoldTotalCost)
    //   columns.splice(12, 0, { field: 'totalCost', title: 'Total Cost', format: "{0:c}", headerClass: 'gridHeader', minWidth: 150 });
    // if (props.compSettings.ShowFixedHoldMarketValue)
    //   columns.splice(13, 0, { field: 'marketValue', title: 'Market Value', format: "{0:c}", headerClass: 'gridHeader', minWidth: 150 });
    // if (props.compSettings.ShowFixedHoldEstAnnIncome)
    //   columns.splice(14, 0, { field: 'estAnnIncome', title: 'Annual Income', format: "{0:c}", headerClass: 'gridHeader', minWidth: 110 });
    // if (props.compSettings.ShowFixedHoldUnrealized)
    //   columns.splice(15, 0, { field: 'unrealized', title: 'Unrealized Gain/Loss', format: "{0:c}", headerClass: 'gridHeader', minWidth: 150 });
    // if (props.compSettings.ShowFixedHoldPercentage)
    //   columns.splice(16, 0, { field: 'percentage', title: '% of Portfolio', format: "{0:p2}", headerClass: 'gridHeader', minWidth: 100 });

    // if (props.compSettings.ShowFixedHoldUser1)
    //   columns.splice(17, 0, { field: 'userDef1', title: this.props.compSettings.userDef1, headerClass: 'gridHeader', minWidth: 150 });
    // if (props.compSettings.ShowFixedHoldUser2)
    //   columns.splice(18, 0, { field: 'userDef2', title: this.props.compSettings.userDef2, headerClass: 'gridHeader', minWidth: 150 });
    // if (props.compSettings.ShowFixedHoldUser3)
    //   columns.splice(19, 0, { field: 'userDef3', title: this.props.compSettings.userDef3, headerClass: 'gridHeader', minWidth: 150 });
    // if (props.compSettings.ShowFixedHoldUser4)
    //   columns.splice(20, 0, { field: 'userDef4', title: this.props.compSettings.userDef4, headerClass: 'gridHeader', minWidth: 150 });
    // if (props.compSettings.ShowFixedHoldUser5)
    //   columns.splice(21, 0, { field: 'userDef5', title: this.props.compSettings.userDef5, headerClass: 'gridHeader', minWidth: 150 });








    var defaultAggs = [{ field: 'quantity', aggregate: 'sum' },
    { field: 'marketValue', aggregate: 'sum' },
    { field: 'totalCost', aggregate: 'sum' },
    { field: 'percentage', aggregate: 'sum' },
    { field: 'unrealized', aggregate: 'sum' },
    { field: 'percentUnrealized', aggregate: 'sum' },
    { field: 'estAnnIncome', aggregate: 'sum' }];

    var defaultGroup = []
    //var defaultGroup = [{ field: "Portfolio", aggregates: defaultAggs }];

    // if (props.compSettings.fixedHoldGrp1)
    //   defaultGroup.push({ field: props.compSettings.fixedHoldGrp1, aggregates: defaultAggs });
    // if (props.compSettings.fixedHoldGrp2)
    //   defaultGroup.push({ field: props.compSettings.fixedHoldGrp2, aggregates: defaultAggs });
    // if (props.compSettings.fixedHoldGrp3)
    //   defaultGroup.push({ field: props.compSettings.fixedHoldGrp3, aggregates: defaultAggs });


    this.state = {
      accountHoldings: [],
      result: [],
      dataState: { sort: [{ field: 'MaturityDate', dir: 'asc' }], group: defaultGroup },
      aggregates: defaultAggs,
      portfolio: this.props.portfolio,
      household: this.props.household,
      dates: [],
      selectedDate: '',
      columns: columns,
      loading: true,
      IndvTaxLots: 0,
      showTaxLots: false,
      showAccountBreakdown: false,
      styleSettings: {
        fiHoldingsShowAccountID: null,
        fiHoldingsShowName1: null,
        fiHoldingsShowName2: null,
        fiHoldingsShowName3: null,
        fiHoldingsIncludeCash: null,
        fiHoldingsShowAssetClass: null,
        fiHoldingsShowSecType: null,
        fiHoldingsShowIndustry: null,
        fiHoldingsShowSector: null,
        fiHoldingsShowCusip: null,
        fiHoldingsShowSP: null,
        fiHoldingsShowMoodys: null,
        fiHoldingsShowPar: null,
        fiHoldingsShowCoupon: null,
        fiHoldingsShowNextCallDate: null,
        fiHoldingsShowMaturityDate: null,
        fiHoldingsShowTotalCost: null,
        fiHoldingsShowMarketValue: null,
        fiHoldingsShowAnnIncome: null,
        fiHoldingsShowUnrealizedGL: null,
        fiHoldingsShowPercentUnrealized: null,
        fiHoldingsShowPercentage: null,
        fiHoldingsShowUserDef1: null,
        fiHoldingsShowUserDef2: null,
        fiHoldingsShowUserDef3: null,
        fiHoldingsShowUserDef4: null,
        fiHoldingsShowUserDef5: null,
        fiHoldingsClassificationGrouping1: null,
        fiHoldingsClassificationGrouping2: null,
        fiHoldingsClassificationGrouping3: null,
        fiHoldingsColumnOrder: null,
        fiHoldingsColumnWidthSecName: null,
        fiHoldingsColumnWidthAssetClass: null,
        fiHoldingsColumnWidthSecType: null,
        fiHoldingsColumnWidthIndustry: null,
        fiHoldingsColumnWidthSector: null,
        fiHoldingsColumnWidthCusip: null,
        fiHoldingsColumnWidthSP: null,
        fiHoldingsColumnWidthMoodys: null,
        fiHoldingsColumnWidthPar: null,
        fiHoldingsColumnWidthCoupon: null,
        fiHoldingsColumnWidthNextCallDate: null,
        fiHoldingsColumnWidthMaturityDate: null,
        fiHoldingsColumnWidthTotalCost: null,
        fiHoldingsColumnWidthMarketValue: null,
        fiHoldingsColumnWidthAnnIncome: null,
        fiHoldingsColumnWidthUnrealizedGL: null,
        fiHoldingsColumnWidthPercentUnrealized: null,
        fiHoldingsColumnWidthPercentage: null,
        fiHoldingsColumnWidthUserDef1: null,
        fiHoldingsColumnWidthUserDef2: null,
        fiHoldingsColumnWidthUserDef3: null,
        fiHoldingsColumnWidthUserDef4: null,
        fiHoldingsColumnWidthUserDef5: null,
        fiHoldingsSortOrderDefault: null,
        fiHoldingsDataRowColor: null,
        fiHoldingsSubtotalColor1: null,
        fiHoldingsSubtotalColor2: null,
        fiHoldingsSubtotalColor3: null,
        fiHoldingsGrandTotalColor: null,
        fiHoldingsIncludeAI: null,
        fiHoldingsSortOrderTaxLot: null,
        columnLabelFiHoldsAssetClass: null,
        columnLabelFiHoldsSecType: null,
        columnLabelFiHoldsCusip: null,
        columnLabelFiHoldsSector: null,
        columnLabelFiHoldsIndustryGroup: null,
        columnLabelFiHoldsSPRating: null,
        columnLabelFiHoldsMoodyRating: null,
        columnLabelFiHoldsPar: null,
        columnLabelFiHoldsCoupon: null,
        columnLabelFiHoldsNextCallDate: null,
        columnLabelFiHoldsMaturityDate: null,
        columnLabelFiHoldsTotalCost: null,
        columnLabelFiHoldsMarketValue: null,
        columnLabelFiHoldsPercentage: null,
        columnLabelFiHoldsAnnIncome: null,
        columnLabelFiHoldsUnrealized: null,
        columnLabelFiHoldsPercentUnrealized: null,
        columnLabelFiHoldsUnitCost: null,
        columnLabelFiHoldsSecurityName: null,
        fiHoldingsColumnWidthAcqDate: null,
        fiHoldingsShowUnitCost: null,
        fiHoldingsColumnWidthUnitCost: null,
        fiHoldingsColumnWidthAccountID: null,
        fiHoldingsAssetClassCodeFilter: null,
        chartShowAsOf: null
      }
    }

    this.getAccountHoldings = this.getAccountHoldings.bind(this);
    this.getHoldDates = this.getHoldDates.bind(this);
    this.changeDataState = this.changeDataState.bind(this);
    this.formatCurrency = this.formatCurrency.bind(this);
    this.formatPercent = this.formatPercent.bind(this);
    this.cellRender = this.cellRender.bind(this);
    this.headerCellRender = this.headerCellRender.bind(this);
    this.getDate = this.getDate.bind(this);
    this.addSortOrderToResult = this.addSortOrderToResult.bind(this);
    this.sortClassification = this.sortClassification.bind(this);
    this.expand = this.expand.bind(this);
    this.expandCollapse = this.expandCollapse.bind(this);
  }

  componentDidMount() {
    this.getStyleSettings(() => {
      this.setColumns(() => {
        this.getAccountHoldings(null, () => {
          this.setMinGridWidth();
        });
      });
    });
    this.getHoldDates();
  }

  componentDidUpdate() {
    if (this.props.portfolio !== this.state.portfolio) {
      this.setState({ portfolio: this.props.portfolio }, () => {
        this.getAccountHoldings(null, () => {
          this.setMinGridWidth();
        })
      });
    }
  }

  setMinGridWidth = () => {
    this.grid = document.querySelector('.k-grid');
    window.addEventListener('resize', this.handleResize);
    this.minGridWidth = 0;
    this.state.columns.map(item => this.minGridWidth += item.minWidth);
    if (this.state.dataState.group)
      this.minGridWidth += 32 * this.state.dataState.group.length;
    this.setState({
      gridCurrent: this.grid.offsetWidth,
      setMinWidth: this.grid.offsetWidth < this.minGridWidth
    });
  }

  handleResize = () => {
    if (this.grid.offsetWidth < this.minGridWidth && !this.state.setMinWidth) {
      this.setState({
        setMinWidth: true
      });
    } else if (this.grid.offsetWidth > this.minGridWidth) {
      this.setState({
        gridCurrent: this.grid.offsetWidth,
        setMinWidth: false
      });
    }
  }

  setWidth = (minWidth) => {
    let width = this.state.setMinWidth ? minWidth : minWidth + (this.state.gridCurrent - this.minGridWidth) / this.state.columns.length;
    if (width >= COLUMN_MIN)
      width = width - ADJUST_PADDING;
    return width;
  }

  setColumns(cb) {

    var columns = [];

    columns.push({ field: 'securityName', title: this.state.styleSettings.columnLabelFiHoldsSecurityName, headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthSecName || 450 });

    if (this.state.styleSettings.fiHoldingsShowPar)
      columns.push({ field: 'quantity', title: this.state.styleSettings.columnLabelFiHoldsPar, format: "{0:n}", headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthPar || 100 });
    if (this.state.styleSettings.fiHoldingsShowMoodys)
      columns.push({ field: 'MoodyRating', title: this.state.styleSettings.columnLabelFiHoldsMoodyRating, headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthMoodys || 100 });
    if (this.state.styleSettings.fiHoldingsShowSP)
      columns.push({ field: 'SPRating', title: this.state.styleSettings.columnLabelFiHoldsSPRating, headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthSP || 100 });
    if (this.state.styleSettings.fiHoldingsShowCusip)
      columns.push({ field: 'securitySymbol', title: this.state.styleSettings.columnLabelFiHoldsCusip, headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthCusip || 90 });

    columns.push({ field: 'acqDate', title: 'Acq. Date', format: "{0:d}", excelFormat: "m/d/yyyy", headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthAcqDate || 150, show: true });




    if (this.state.styleSettings.fiHoldingsShowSector)
      columns.push({ field: 'Sector', title: this.state.styleSettings.columnLabelFiHoldsSector, headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthSector || 150 });
    if (this.state.styleSettings.fiHoldingsShowIndustry)
      columns.push({ field: 'Industry', title: this.state.styleSettings.columnLabelFiHoldsIndustryGroup, headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthIndustry || 150 });
    if (this.state.styleSettings.fiHoldingsShowSecType)
      columns.push({ field: 'Type', title: this.state.styleSettings.columnLabelFiHoldsSecType, headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthSecType || 150 });
    if (this.state.styleSettings.fiHoldingsShowAssetClass)
      columns.push({ field: 'Asset Class', title: this.state.styleSettings.columnLabelFiHoldsAssetClass, headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthAssetClass || 150 });

    columns.push({ field: 'AccountID', title: 'Account ID', headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthAccountID || 110, show: true });
    columns.push({ field: 'Name1', title: 'Account ID', headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthAccountID || 110, show: true });
    columns.push({ field: 'Name2', title: 'Account ID', headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthAccountID || 110, show: true });
    columns.push({ field: 'Name3', title: 'Account ID', headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthAccountID || 110, show: true });

    if (this.state.styleSettings.fiHoldingsShowCoupon)
      columns.push({ field: 'InterestDividendRate', title: this.state.styleSettings.columnLabelFiHoldsCoupon, format: "{0:p2}", headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthCoupon || 75 });
    if (this.state.styleSettings.fiHoldingsShowNextCallDate)
      columns.push({ field: 'CallDate1', title: this.state.styleSettings.columnLabelFiHoldsNextCallDate, format: "{0:d}", headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthNextCallDate || 110 });
    if (this.state.styleSettings.fiHoldingsShowMaturityDate)
      columns.push({ field: 'MaturityDate', title: this.state.styleSettings.columnLabelFiHoldsMaturityDate, format: "{0:d}", headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthMaturityDate || 110 });
    if (this.state.styleSettings.fiHoldingsShowUnitCost)
      columns.push({ field: 'unitCost', title: this.state.styleSettings.columnLabelFiHoldsUnitCost, format: "{0:c}", excelFormat: "$#,##0.00", headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthUnitCost || 85, show: true });
    if (this.state.styleSettings.fiHoldingsShowTotalCost)
      columns.push({ field: 'totalCost', title: this.state.styleSettings.columnLabelFiHoldsTotalCost, format: "{0:c}", headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthTotalCost || 150 });
    if (this.state.styleSettings.fiHoldingsShowMarketValue)
      columns.push({ field: 'marketValue', title: this.state.styleSettings.columnLabelFiHoldsMarketValue, format: "{0:c}", headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthMarketValue || 150 });
    if (this.state.styleSettings.fiHoldingsShowAnnIncome)
      columns.push({ field: 'estAnnIncome', title: this.state.styleSettings.columnLabelFiHoldsAnnIncome, format: "{0:c}", headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthAnnIncome || 110 });
    if (this.state.styleSettings.fiHoldingsShowUnrealizedGL)
      columns.push({ field: 'unrealized', title: this.state.styleSettings.columnLabelFiHoldsUnrealized, format: "{0:c}", headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthUnrealizedGL || 150 });
    if (this.state.styleSettings.fiHoldingsShowPercentUnrealized)
      columns.push({ field: 'percentUnrealized', title: this.state.styleSettings.columnLabelFiHoldsPercentUnrealized, format: "{0:p2}", headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthPercentUnrealized || 100 });
    if (this.state.styleSettings.fiHoldingsShowPercentage)
      columns.push({ field: 'percentage', title: this.state.styleSettings.columnLabelFiHoldsPercentage, format: "{0:p2}", headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthPercentage || 100 });

    if (this.state.styleSettings.fiHoldingsShowUserDef1)
      columns.push({ field: 'userDef1', title: this.props.styleSettings.userDef1, headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthUserDef1 || 150 });
    if (this.state.styleSettings.fiHoldingsShowUserDef2)
      columns.push({ field: 'userDef2', title: this.props.styleSettings.userDef2, headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthUserDef2 || 150 });
    if (this.state.styleSettings.fiHoldingsShowUserDef3)
      columns.push({ field: 'userDef3', title: this.props.styleSettings.userDef3, headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthUserDef3 || 150 });
    if (this.state.styleSettings.fiHoldingsShowUserDef4)
      columns.push({ field: 'userDef4', title: this.props.styleSettings.userDef4, headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthUserDef4 || 150 });
    if (this.state.styleSettings.fiHoldingsShowUserDef5)
      columns.push({ field: 'userDef5', title: this.props.styleSettings.userDef5, headerClass: 'gridHeader', minWidth: this.state.styleSettings.fiHoldingsColumnWidthUserDef5 || 150 });

    /////
    var sortedColumns = []
    this.state.styleSettings.fiHoldingsColumnOrder.split('|').forEach(columnField => {
      var index = -1

      switch (columnField) {
        // case 'userDef1':
        //   index = columns.findIndex(column=>{
        //     return ((column.field === this.props.styleSettings.userDef1)) 
        //   })
        //   break;
        // case 'userDef2':
        //   index = columns.findIndex(column=>{
        //     return ((column.field === this.props.styleSettings.userDef2)) 
        //   })
        //   break;
        // case 'userDef3':
        //   index = columns.findIndex(column=>{
        //     return ((column.field === this.props.styleSettings.userDef3)) 
        //   })
        //   break;
        // case 'userDef4':
        //   index = columns.findIndex(column=>{
        //     return ((column.field === this.props.styleSettings.userDef4)) 
        //   })
        //   break;
        // case 'userDef5':
        //   index = columns.findIndex(column=>{
        //     return ((column.field === this.props.styleSettings.userDef5)) 
        //   })
        //   break;
        case 'AccountID':
          if (this.state.styleSettings.fiHoldingsShowAccountID) {
            index = columns.findIndex(column => {
              return ((column.field === 'AccountID'))
            })
          }
          else if (this.state.styleSettings.fiHoldingsShowName1) {
            index = columns.findIndex(column => {
              return ((column.field === 'Name1'))
            })
          }
          else if (this.state.styleSettings.fiHoldingsShowName2) {
            index = columns.findIndex(column => {
              return ((column.field === 'Name2'))
            })
          }
          else if (this.state.styleSettings.fiHoldingsShowName3) {
            index = columns.findIndex(column => {
              return ((column.field === 'Name3'))
            })
          }
          else {
            index = columns.findIndex(column => {
              return ((column.field === columnField))
            })
          }
          break;
        default:
          index = columns.findIndex(column => {
            return ((column.field === columnField))
          })
      }

      if (index >= 0)
        sortedColumns.push(columns[index])
    })

    console.log(columns)
    console.log(sortedColumns)


    this.setState({ columns: sortedColumns }, () => {
      cb()
    });

  }

  getStyleSettings(cb) {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
      'holdingsHouseholdName',
      'fiHoldingsShowAccountID',
      'fiHoldingsShowName1',
      'fiHoldingsShowName2',
      'fiHoldingsShowName3',
      "fiHoldingsIncludeCash",
      "fiHoldingsShowAssetClass",
      "fiHoldingsShowSecType",
      "fiHoldingsShowIndustry",
      "fiHoldingsShowSector",
      "fiHoldingsShowCusip",
      "fiHoldingsShowSP",
      "fiHoldingsShowMoodys",
      "fiHoldingsShowPar",
      "fiHoldingsShowCoupon",
      "fiHoldingsShowNextCallDate",
      "fiHoldingsShowMaturityDate",
      "fiHoldingsShowTotalCost",
      "fiHoldingsShowMarketValue",
      "fiHoldingsShowAnnIncome",
      "fiHoldingsShowUnrealizedGL",
      "fiHoldingsShowPercentUnrealized",
      "fiHoldingsShowPercentage",
      "fiHoldingsShowUserDef1",
      "fiHoldingsShowUserDef2",
      "fiHoldingsShowUserDef3",
      "fiHoldingsShowUserDef4",
      "fiHoldingsShowUserDef5",
      "fiHoldingsClassificationGrouping1",
      "fiHoldingsClassificationGrouping2",
      "fiHoldingsClassificationGrouping3",
      "fiHoldingsColumnOrder",
      "fiHoldingsColumnWidthSecName",
      "fiHoldingsColumnWidthAssetClass",
      "fiHoldingsColumnWidthSecType",
      "fiHoldingsColumnWidthIndustry",
      "fiHoldingsColumnWidthSector",
      "fiHoldingsColumnWidthCusip",
      "fiHoldingsColumnWidthSP",
      "fiHoldingsColumnWidthMoodys",
      "fiHoldingsColumnWidthPar",
      "fiHoldingsColumnWidthCoupon",
      "fiHoldingsColumnWidthNextCallDate",
      "fiHoldingsColumnWidthMaturityDate",
      "fiHoldingsColumnWidthTotalCost",
      "fiHoldingsColumnWidthMarketValue",
      "fiHoldingsColumnWidthAnnIncome",
      "fiHoldingsColumnWidthUnrealizedGL",
      "fiHoldingsColumnWidthPercentUnrealized",
      "fiHoldingsColumnWidthPercentage",
      "fiHoldingsColumnWidthUserDef1",
      "fiHoldingsColumnWidthUserDef2",
      "fiHoldingsColumnWidthUserDef3",
      "fiHoldingsColumnWidthUserDef4",
      "fiHoldingsColumnWidthUserDef5",
      "fiHoldingsSortOrderDefault",
      "fiHoldingsDataRowColor",
      "fiHoldingsSubtotalColor1",
      "fiHoldingsSubtotalColor2",
      "fiHoldingsSubtotalColor3",
      "fiHoldingsGrandTotalColor",
      "fiHoldingsIncludeAI",
      "fiHoldingsSortOrderTaxLot",
      "columnLabelFiHoldsAssetClass",
      "columnLabelFiHoldsSecType",
      "columnLabelFiHoldsCusip",
      "columnLabelFiHoldsSector",
      "columnLabelFiHoldsIndustryGroup",
      "columnLabelFiHoldsSPRating",
      "columnLabelFiHoldsMoodyRating",
      "columnLabelFiHoldsPar",
      "columnLabelFiHoldsCoupon",
      "columnLabelFiHoldsNextCallDate",
      "columnLabelFiHoldsMaturityDate",
      "columnLabelFiHoldsTotalCost",
      "columnLabelFiHoldsMarketValue",
      "columnLabelFiHoldsPercentage",
      "columnLabelFiHoldsAnnIncome",
      "columnLabelFiHoldsUnrealized",
      "columnLabelFiHoldsPercentUnrealized",
      "columnLabelFiHoldsUnitCost",
      "columnLabelFiHoldsSecurityName",
      "fiHoldingsColumnWidthAcqDate",
      "fiHoldingsShowUnitCost",
      "fiHoldingsColumnWidthUnitCost",
      "fiHoldingsColumnWidthAccountID",
      "fiHoldingsAssetClassCodeFilter",
      "chartShowAsOf"
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
      .then(response => {
        this.setState({ styleSettings: response.data.styleSettings }, () => {
          cb()
        });
      })
      .catch(err => {
        console.error(err);
      })


  }

  setHouseholdOrConsolidated = (data) => {
    if (this.state.styleSettings.holdingsHouseholdName != null && this.state.styleSettings.holdingsHouseholdName === 'Consolidated'
      && data.data != null && data.data[0] != null && data.data[0].value != null) {
      data.data[0].value = 'Consolidated';
    }
    return data;
  }

  getAccountHoldings(date, cb) {
    this.setState({ loading: true });
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    const x = [...this.state.columns];
    x.forEach((column, key) => {
      if (column.field === "acqDate" && this.state.showTaxLots === false) {
        column.show = false;
        this.setState({ columns: x });
      }
      else {
        column.show = true;
        this.setState({ columns: x });
      }
    });

    var unmapped = false;
    if (this.props.user.unmapped)
      unmapped = true;

    if (date === null)
      date = '';

    var port = this.state.portfolio.account
    if (this.state.portfolio.consolidated) {
      port = this.props.household
    }

    var breakOutTaxLotAccounts = false;
    if ((this.state.styleSettings.fiHoldingsShowAccountID ||
      this.state.styleSettings.fiHoldingsShowName1 ||
      this.state.styleSettings.fiHoldingsShowName2 ||
      this.state.styleSettings.fiHoldingsShowName3) &&
      this.state.showAccountBreakdown) {
      breakOutTaxLotAccounts = true;
    }

    axios.get('api/fixedIncomeAccountHoldings',
      {
        method: 'GET',
        params: {
          company: this.props.user.company,
          role: this.props.user.role,
          port: port,
          date: date,
          household: this.state.household,
          consolidated: this.state.portfolio.consolidated,
          breakOutConsolidatedPorts: breakOutTaxLotAccounts,
          IncludeCash: this.state.styleSettings.fiHoldingsIncludeCash,
          lumpLots: this.state.showTaxLots,
          unmapped: unmapped,
          includeAI: this.state.styleSettings.fiHoldingsIncludeAI,
          assetClassCodeFilter: this.state.styleSettings.fiHoldingsAssetClassCodeFilter
        },
        headers: headers,
      }).then(response => {
        if (response.data.code === 200) {
          var IndvTaxLots = 0;
          for (let i = 0; i < response.data.data.length; i++) {
            IndvTaxLots = response.data.data[i].IndvTaxLots;
            response.data.data[i].acqDate = this.getDate(response.data.data[i].acqDate, true);
            response.data.data[i].MaturityDate = this.getDate(response.data.data[i].MaturityDate, true);
            // response.data.data[i]["Household"] = response.data.data[i].Household;
            response.data.data[i]["Portfolio"] = response.data.data[i].displayName;
            response.data.data[i]["Type"] = response.data.data[i].securityType;
            response.data.data[i]["Asset Class"] = response.data.data[i].assetAllocation;
            response.data.data[i]["Sector"] = response.data.data[i].sector;
            response.data.data[i]["Industry"] = response.data.data[i].industry;
            response.data.data[i][this.props.styleSettings.userDef1] = response.data.data[i].userDef1;
            response.data.data[i][this.props.styleSettings.userDef2] = response.data.data[i].userDef2;
            response.data.data[i][this.props.styleSettings.userDef3] = response.data.data[i].userDef3;
            response.data.data[i][this.props.styleSettings.userDef4] = response.data.data[i].userDef4;
            response.data.data[i][this.props.styleSettings.userDef5] = response.data.data[i].userDef5;
            // response.data.data[i]["estAnnIncome"] = response.data.data[i].estAnnIncome;
            // response.data.data[i]["SPRating"] = response.data.data[i].SPRating;
            // response.data.data[i]["MoodyRating"] = response.data.data[i].MoodyRating;
            response.data.data[i].CallDate1 = this.getDate(response.data.data[i].CallDate1, true);
          }

          //Change the "dataState"
          var defaultAggs = [{ field: 'quantity', aggregate: 'sum' },
          { field: 'marketValue', aggregate: 'sum' },
          { field: 'totalCost', aggregate: 'sum' },
          { field: 'percentage', aggregate: 'sum' },
          { field: 'yieldWeighted', aggregate: 'sum' },
          { field: 'unrealized', aggregate: 'sum' },
          { field: 'percentUnrealized', aggregate: 'sum' },
          { field: 'estAnnIncome', aggregate: 'sum' },
          { field: 'appMarketPrice', aggregate: 'min' },
          { field: 'appMarketPrice', aggregate: 'max' },
          { field: 'SecurityCount', aggregate: 'min' },
          { field: 'SecurityCount', aggregate: 'max' },
          { field: 'IndvTaxLots', aggregate: 'max' }
          ];

          var defaultGroup = [];
          if (!this.state.portfolio.consolidated)
            defaultGroup.push({ field: "Portfolio", aggregates: defaultAggs });
          if (this.state.portfolio.consolidated)
            defaultGroup.push({ field: "Household", aggregates: defaultAggs });

          if (this.state.styleSettings.fiHoldingsClassificationGrouping1 && this.state.styleSettings.fiHoldingsClassificationGrouping1 !== 'None') {
            if (this.state.styleSettings.fiHoldingsClassificationGrouping1 === 'user def 1')
              defaultGroup.push({ field: this.props.styleSettings.userDef1, aggregates: defaultAggs });
            else if (this.state.styleSettings.fiHoldingsClassificationGrouping1 === 'user def 2')
              defaultGroup.push({ field: this.props.styleSettings.userDef2, aggregates: defaultAggs });
            else if (this.state.styleSettings.fiHoldingsClassificationGrouping1 === 'user def 3')
              defaultGroup.push({ field: this.props.styleSettings.userDef3, aggregates: defaultAggs });
            else if (this.state.styleSettings.fiHoldingsClassificationGrouping1 === 'user def 4')
              defaultGroup.push({ field: this.props.styleSettings.userDef4, aggregates: defaultAggs });
            else if (this.state.styleSettings.fiHoldingsClassificationGrouping1 === 'user def 5')
              defaultGroup.push({ field: this.props.styleSettings.userDef5, aggregates: defaultAggs });
            else
              defaultGroup.push({ field: (this.state.styleSettings.fiHoldingsClassificationGrouping1 === 'Security Type' ? 'Type' : this.state.styleSettings.fiHoldingsClassificationGrouping1), aggregates: defaultAggs });
          }
          if (this.state.styleSettings.fiHoldingsClassificationGrouping2 && this.state.styleSettings.fiHoldingsClassificationGrouping2 !== 'None') {
            if (this.state.styleSettings.fiHoldingsClassificationGrouping2 === 'user def 1')
              defaultGroup.push({ field: this.props.styleSettings.userDef1, aggregates: defaultAggs });
            else if (this.state.styleSettings.fiHoldingsClassificationGrouping2 === 'user def 2')
              defaultGroup.push({ field: this.props.styleSettings.userDef2, aggregates: defaultAggs });
            else if (this.state.styleSettings.fiHoldingsClassificationGrouping2 === 'user def 3')
              defaultGroup.push({ field: this.props.styleSettings.userDef3, aggregates: defaultAggs });
            else if (this.state.styleSettings.fiHoldingsClassificationGrouping2 === 'user def 4')
              defaultGroup.push({ field: this.props.styleSettings.userDef4, aggregates: defaultAggs });
            else if (this.state.styleSettings.fiHoldingsClassificationGrouping2 === 'user def 5')
              defaultGroup.push({ field: this.props.styleSettings.userDef5, aggregates: defaultAggs });
            else
              defaultGroup.push({ field: (this.state.styleSettings.fiHoldingsClassificationGrouping2 === 'Security Type' ? 'Type' : this.state.styleSettings.fiHoldingsClassificationGrouping2), aggregates: defaultAggs });
          }
          if (this.state.styleSettings.fiHoldingsClassificationGrouping3 && this.state.styleSettings.fiHoldingsClassificationGrouping3 !== 'None') {
            if (this.state.styleSettings.fiHoldingsClassificationGrouping3 === 'user def 1')
              defaultGroup.push({ field: this.props.styleSettings.userDef1, aggregates: defaultAggs });
            else if (this.state.styleSettings.fiHoldingsClassificationGrouping3 === 'user def 2')
              defaultGroup.push({ field: this.props.styleSettings.userDef2, aggregates: defaultAggs });
            else if (this.state.styleSettings.fiHoldingsClassificationGrouping3 === 'user def 3')
              defaultGroup.push({ field: this.props.styleSettings.userDef3, aggregates: defaultAggs });
            else if (this.state.styleSettings.fiHoldingsClassificationGrouping3 === 'user def 4')
              defaultGroup.push({ field: this.props.styleSettings.userDef4, aggregates: defaultAggs });
            else if (this.state.styleSettings.fiHoldingsClassificationGrouping3 === 'user def 5')
              defaultGroup.push({ field: this.props.styleSettings.userDef5, aggregates: defaultAggs });
            else
              defaultGroup.push({ field: (this.state.styleSettings.fiHoldingsClassificationGrouping3 === 'Security Type' ? 'Type' : this.state.styleSettings.fiHoldingsClassificationGrouping3), aggregates: defaultAggs });
          }


          if (IndvTaxLots.toString() === "1" || this.state.showAccountBreakdown) {
            defaultGroup.push({ field: "securityName", aggregates: defaultAggs });
          }

          var sort = []
          if (this.state.styleSettings.fiHoldingsShowName1) {
            sort = [{ field: "Name1", dir: "asc" }]
          }
          if (this.state.styleSettings.fiHoldingsShowName2) {
            sort = [{ field: "Name2", dir: "asc" }]
          }
          if (this.state.styleSettings.fiHoldingsShowName3) {
            sort = [{ field: "Name3", dir: "asc" }]
          }
          if (this.state.styleSettings.fiHoldingsShowAccountID) {
            sort = [{ field: "AccountID", dir: "asc" }]
          }

          //var sort = {}
          if (this.state.showTaxLots) {
            switch (this.state.styleSettings.fiHoldingsSortOrderTaxLot) {
              case 'FIFO':
                sort = [{ field: 'acqDate', dir: 'asc' }]
                break
              case 'LIFO':
                sort = [{ field: 'acqDate', dir: 'desc' }]
                break
              case 'High Cost':
                sort = [{ field: 'unitCost', dir: 'desc' }]
                break
              case 'Low Cost':
                sort = [{ field: 'unitCost', dir: 'asc' }]
                break
              default:
                sort = [{ field: 'securitySymbol', dir: 'asc' }]
                break
            }
          }
          else {
            switch (this.state.styleSettings.fiHoldingsSortOrderDefault) {
              case 'Maturity Date (asc)':
                sort = [{ field: 'MaturityDate', dir: 'asc' }]//asdf
                break
              case 'Symbol':
                sort = [{ field: 'securitySymbol', dir: 'asc' }]
                break
              case 'Security Name':
                sort = [{ field: 'securityName', dir: 'asc' }]
                break
              case 'Market Value (desc)':
                sort = [{ field: 'marketValue', dir: 'desc' }]
                break
              default:
                sort = [{ field: 'securitySymbol', dir: 'asc' }]
                break
            }
          }
          //sort = [{ field: "securitySymbol", dir: "asc" }]


          // if (IndvTaxLots.toString() === "1") {
          //   defaultGroup.push({ field: "securityName", aggregates: defaultAggs });
          // }

          //setup the table grouping/totals
          this.setState({ dataState: { sort: sort, group: defaultGroup, aggregates: defaultAggs } }, () => {
            this.setState({ accountHoldings: response.data.data, result: this.addPropertyToItems(this.setHouseholdOrConsolidated(process(response.data.data, this.state.dataState))), loading: false, IndvTaxLots: IndvTaxLots, classificationSortOrder: response.data.classificationSortOrder }, () => {

              if (response.data.classificationSortOrder && response.data.classificationSortOrder.length > 0) {
                this.addSortOrderToResult()

                this.sortClassification(sort[0])
              }
              else {
                this.sortClassification(sort[0])
              }


              if (cb) {
                cb();
              }
            });
          })



        }
      }).catch(err => {
        console.log(err);
      });
  }

  getDate(sqlDate, isUTC) {
    var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
    if (d) {
      var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
      if (isUTC)
        return utc;
      else
        return d;
    }
    else
      return null;
  }

  getHoldDates() {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      company: this.props.user.company,
      port: this.props.portfolio.account,
      user: this.props.user.user,
      household: this.props.household
    }
    axios.post('api/posDates', payload, { headers }).then(response => {
      if (response.data.code === 200) {
        if (response.data.data.length > 0)
          this.setState({ dates: response.data.data, selectedDate: response.data.data[0].fDate });
        else
          this.setState({ dates: [] });
      }
    }).catch(err => {
      console.log(err);
    });
  }

  _export;
  export = () => {
    this._export.save();
  }

  sortClassification = (sort) => {
    this.state.result.data.forEach(row => {

      if (row.items) {
        if (sort) {
          if (sort.dir === "asc") {
            row.items.sort((a, b) => ((a.sortOrder || (typeof a.value === 'string' ? a.value.toLowerCase() : a.value)) > (b.sortOrder || (typeof b.value === 'string' ? b.value.toLowerCase() : b.value))) ? 1 : (a.securitySymbol ? a.securitySymbol.toLowerCase() : a[sort.field] > b[sort.field] ? b[sort.field].toLowerCase() : b[sort.field]) ? 1 : -1)
          }
          else {
            row.items.sort((a, b) => ((a.sortOrder || (typeof a.value === 'string' ? a.value.toLowerCase() : a.value)) > (b.sortOrder || (typeof b.value === 'string' ? b.value.toLowerCase() : b.value))) ? 1 : (a.securitySymbol ? a.securitySymbol.toLowerCase() : a[sort.field] < b[sort.field] ? b[sort.field].toLowerCase() : b[sort.field]) ? 1 : -1)
          }
        }
        else {
          row.items.sort((a, b) => ((a.sortOrder || (typeof a.value === 'string' ? a.value.toLowerCase() : a.value)) > (b.sortOrder || (typeof b.value === 'string' ? b.value.toLowerCase() : b.value))) ? 1 : (a.securitySymbol ? a.securitySymbol.toLowerCase() : a.securitySymbol > b.securitySymbol ? b.securitySymbol.toLowerCase() : b.securitySymbol) ? 1 : -1)
        }
      }

      this.sortClassificationChild(row, sort);
    });
    this.forceUpdate();
  }

  sortClassificationChild = (child, sort) => {
    if (child.items) {
      if (sort) {
        if (sort.dir === "asc") {
          child.items.sort((a, b) => ((a.sortOrder || (typeof a.value === 'string' ? a.value.toLowerCase() : a.value)) > (b.sortOrder || (typeof b.value === 'string' ? b.value.toLowerCase() : b.value))) ? 1 : (a.securitySymbol ? a.securitySymbol.toLowerCase() : a[sort.field] > b[sort.field] ? b[sort.field].toLowerCase() : b[sort.field]) ? 1 : -1)
        }
        else {
          child.items.sort((a, b) => ((a.sortOrder || (typeof a.value === 'string' ? a.value.toLowerCase() : a.value)) > (b.sortOrder || (typeof b.value === 'string' ? b.value.toLowerCase() : b.value))) ? 1 : (a.securitySymbol ? a.securitySymbol.toLowerCase() : a[sort.field] < b[sort.field] ? b[sort.field].toLowerCase() : b[sort.field]) ? 1 : -1)
        }
      }
      else {
        child.items.sort((a, b) => ((a.sortOrder || (typeof a.value === 'string' ? a.value.toLowerCase() : a.value)) > (b.sortOrder || (typeof b.value === 'string' ? b.value.toLowerCase() : b.value))) ? 1 : (a.securitySymbol ? a.securitySymbol.toLowerCase() : a.securitySymbol > b.securitySymbol ? b.securitySymbol.toLowerCase() : b.securitySymbol) ? 1 : -1)
      }

      child.items.forEach(item => {
        this.sortClassificationChild(item, sort);
      })
    }
  }

  addSortOrderToResult = (field) => {
    this.state.result.data.forEach(row => {

      var index = this.state.classificationSortOrder.findIndex(classification => {
        return ((classification.ClassificationDescription === row.field || (classification.ClassificationDescription === 'Industry Group' && row.field === 'Industry'))
          && classification.ClassificationMemberDescription === row.value);
      })
      if (index >= 0)
        row.sortOrder = this.state.classificationSortOrder[index].ClassificationMemberSortOrder

      this.addSortOrderToResultChild(row, field);
    });
    this.forceUpdate();
  }

  addSortOrderToResultChild = (child, field) => {
    if (child.items) {
      child.items.forEach(item => {
        var index = this.state.classificationSortOrder.findIndex(classification => {
          return ((classification.ClassificationDescription === item.field || (classification.ClassificationDescription === 'Industry Group' && item.field === 'Industry'))
            && classification.ClassificationMemberDescription === item.value);
        })
        if (index >= 0)
          item.sortOrder = this.state.classificationSortOrder[index].ClassificationMemberSortOrder

        this.addSortOrderToResultChild(item, field);
      })
    }
  }

  getFieldTitle = (field) => {

    switch (field) {
      case 'Portfolio':
        return 'All'
      case 'Household':
        return 'All'
      case 'Asset Class':
        return this.state.styleSettings.columnLabelFiHoldsAssetClass
      case 'Type':
        return this.state.styleSettings.columnLabelFiHoldsSecType
      case 'InterestDividendRate':
        return this.state.styleSettings.columnLabelFiHoldsCoupon
      case 'Sector':
        return this.state.styleSettings.columnLabelFiHoldsSector
      case ('Industry' || 'Industry Group' || 'industry'):
        return this.state.styleSettings.columnLabelFiHoldsIndustryGroup
      case 'acqDate':
        return this.state.styleSettings.columnLabelFiHoldsAcqDate
      case 'quantity':
        return this.state.styleSettings.columnLabelFiHoldsPar
      case 'unitCost':
        return this.state.styleSettings.columnLabelFiHoldsUnitCost
      case 'totalCost':
        return this.state.styleSettings.columnLabelFiHoldsTotalCost
      case 'appMarketPrice':
        return this.state.styleSettings.columnLabelFiHoldsUnitPrice
      case 'marketValue':
        return this.state.styleSettings.columnLabelFiHoldsMarketValue
      case 'percentage':
        return this.state.styleSettings.columnLabelFiHoldsPercentage
      case 'estAnnIncome':
        return this.state.styleSettings.columnLabelFiHoldsAnnIncome
      case 'yield':
        return this.state.styleSettings.columnLabelFiHoldsYield
      case 'securitySymbol':
        return this.state.styleSettings.columnLabelFiHoldsCusip
      case 'securityName':
        return this.state.styleSettings.columnLabelFiHoldsSecurityName
      case 'CallDate1':
        return this.state.styleSettings.columnLabelFiHoldsNextCallDate
      case 'MaturityDate':
        return this.state.styleSettings.columnLabelFiHoldsMaturityDate
      case 'SPRating':
        return this.state.styleSettings.columnLabelFiHoldsSPRating
      case 'MoodyRating':
        return this.state.styleSettings.columnLabelFiHoldsMoodyRating
      case 'unrealized':
        return this.state.styleSettings.columnLabelFiHoldsUnrealized
      case 'percentUnrealized':
        return this.state.styleSettings.columnLabelFiHoldsPercentUnrealized
      case 'userDef1':
        return this.props.styleSettings.userDef1
      case 'userDef2':
        return this.props.styleSettings.userDef2
      case 'userDef3':
        return this.props.styleSettings.userDef3
      case 'userDef4':
        return this.props.styleSettings.userDef4
      case 'userDef5':
        return this.props.styleSettings.userDef5
      default:
        return null
    }
  }


  render() {
    return (
      <div className='fiHoldings'>
        <ExcelExport data={this.state.accountHoldings} fileName={'Holdings.xlsx'} ref={(exporter) => { this._export = exporter; }}>
          <Grid data={this.state.result}
            groupable={{ footer: 'always' }}
            className='noGroupGridLines'
            resizable={true}
            reorderable={true}
            filterable={false}
            sortable={true}
            onDataStateChange={this.changeDataState}
            onExpandChange={this.expandChange}
            expandField="expanded"
            cellRender={this.cellRender}
            headerCellRender={this.headerCellRender}
            {...this.state.dataState}>
            <GridNoRecords>
              {this.state.loading && 'Loading...'}
              {!this.state.loading && 'No records available'}
            </GridNoRecords>
            <GridToolbar>
              {this.state.styleSettings.chartShowAsOf && <><label>As Of: </label><select onChange={(e) => { this.setState({ selectedDate: e.target.value }); this.getAccountHoldings(e.target.value, () => this.setMinGridWidth()) }}>
                {this.state.dates.map((date, i) => (
                  <option key={i} value={date.fDate}>{date.fDate}</option>
                ))}
              </select></>}
              {!this.state.styleSettings.chartShowAsOf && <label>As Of: {this.state.selectedDate}</label>}
              <Button icon="excel" title='Export to Excel' onClick={this.export}>Export</Button>
              <Button title='Expand' onClick={this.expand}>Expand All</Button>
              {/* <Button title='Collapse' onClick={this.collapse}>Collapse All</Button> */}
              {this.state.dataState.group.map(group => (<>
                {/* <Button title='Expand' onClick={(e) => this.expand(group.field)}>Expand {group.field}</Button> */}
                {group.field !== 'Portfolio' && group.field !== 'Household' &&
                  <Button title='Collapse' onClick={(e) => this.expandCollapse(group.field)}>Collapse/Expand {this.getFieldTitle(group.field) || group.field}</Button>
                }
              </>
              ))}

              <label><input className='bigger' type="checkbox" name="checkbox" value="value" checked={this.state.showTaxLots} onChange={(e) => { this.setState({ showTaxLots: !this.state.showTaxLots }, () => { this.getAccountHoldings(this.state.selectedDate, () => this.setMinGridWidth()) }); }} />Show Individual Tax Lots</label>

              {(this.state.styleSettings.fiHoldingsShowAccountID ||
                this.state.styleSettings.fiHoldingsShowName1 ||
                this.state.styleSettings.fiHoldingsShowName2 ||
                this.state.styleSettings.fiHoldingsShowName3) &&
                <label><input className='bigger' type="checkbox" name="checkbox" value="value" checked={this.state.showAccountBreakdown} onChange={(e) => { this.setState({ showAccountBreakdown: !this.state.showAccountBreakdown }, () => { this.getAccountHoldings(this.state.selectedDate, () => this.setMinGridWidth()) }); }} />Show Account Breakdown</label>
              }
            </GridToolbar>
            {
              this.state.columns.map((column, key) => {
                if (column.field === 'AccountID' && (!this.state.styleSettings.fiHoldingsShowAccountID || !this.state.showAccountBreakdown))
                  return <></>
                if (column.field === 'Name1' && (!this.state.styleSettings.fiHoldingsShowName1 || !this.state.showAccountBreakdown))
                  return <></>
                if (column.field === 'Name2' && (!this.state.styleSettings.fiHoldingsShowName2 || !this.state.showAccountBreakdown))
                  return <></>
                if (column.field === 'Name3' && (!this.state.styleSettings.fiHoldingsShowName3 || !this.state.showAccountBreakdown))
                  return <></>
                else
                  return column.show && <GridColumn field={column.field} title={this.getFieldTitle(column.field) || column.title} key={key} format={column.format} headerClassName={column.headerClass} width={this.setWidth(column.minWidth)} />
              })
            }
          </Grid>
        </ExcelExport>
        {this.state.loading && <GridLoading />}
      </div>
    );
  }

  changeDataState = (e) => {
    const groups = e.dataState.group;
    if (groups) {
      groups.map(group => group.aggregates = this.state.aggregates);
    }
    var newData = process(this.state.accountHoldings, e.dataState);
    var sort = null
    if (e.dataState.sort && e.dataState.sort.length > 0) {
      sort = e.dataState.sort[0]
    }
    this.setState({ dataState: e.dataState, result: this.addPropertyToItems(this.setHouseholdOrConsolidated(newData)) }, () => {
      if (this.state.classificationSortOrder && this.state.classificationSortOrder.length > 0) {
        this.addSortOrderToResult();
        this.sortClassification(sort);
      }
    });
  }

  formatCurrency(val) {
    var formatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(val);

    return formatted;
  }

  formatQuantity(val) {
    var nfObject = new Intl.NumberFormat('en-US');
    var formatted = nfObject.format(val);
    return formatted;
  }

  formatPercent(val) {
    return (val * 100).toFixed(2) + "%";
  }

  formatDateString(date) {
    var dateString = '' + (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
    return dateString;
  }

  cellRender(tdElement, cellProps) {
    if (cellProps.rowType === 'groupFooter') {
      //var color = this.state.styleSettings.fiHoldingsSubtotalColor
      var color = this.state.styleSettings.fiHoldingsSubtotalColor3
      if (cellProps.level === 0) {//grand total row
        color = this.state.styleSettings.fiHoldingsGrandTotalColor
      }
      if (cellProps.level === 1) {//subtotal1 row
        color = this.state.styleSettings.fiHoldingsSubtotalColor1
      }
      if (cellProps.level === 2) {//subtotal2 row
        color = this.state.styleSettings.fiHoldingsSubtotalColor2
      }
      if (cellProps.level >= 3) {//subtotal3 row
        color = this.state.styleSettings.fiHoldingsSubtotalColor3
      }
      if (cellProps.field !== 'value' && cellProps.field !== 'Portfolio' && cellProps.field !== 'securityName' && cellProps.field !== 'Asset Class' && cellProps.field !== 'Sector'
        && cellProps.field !== 'Type' && cellProps.field !== 'Industry' && cellProps.field !== this.props.styleSettings.userDef1
        && cellProps.field !== this.props.styleSettings.userDef2 && cellProps.field !== this.props.styleSettings.userDef3 && cellProps.field !== this.props.styleSettings.userDef4
        && cellProps.field !== this.props.styleSettings.userDef5 && cellProps.field !== 'acqDate') {
        var val = '';

        if (cellProps.field === 'percentUnrealized'){
          val = cellProps.dataItem.aggregates.unrealized.sum / cellProps.dataItem.aggregates.totalCost.sum;
        }
        else if (cellProps.dataItem.aggregates[cellProps.field]) {
          val = cellProps.dataItem.aggregates[cellProps.field].sum;
        }


        if (val === null) {
          val = '';
        }

        if (val !== '') {
          if (cellProps.field === 'percentage' || cellProps.field === 'percentUnrealized' || cellProps.field === 'yield') {
            val = this.formatPercent(val, 2);
          }
          else if (cellProps.field === 'quantity') {
            if (val === 0) {
              val = '';
            }
            else {
              val = this.formatQuantity(val);
            }
          }
          else {
            val = this.formatCurrency(val);
          }
        }


        return (
          <td style={{ textAlign: 'right', backgroundColor: color }}>
            <strong>{val}</strong>
          </td>
        );
      }
      else if (cellProps.field === 'Portfolio') {
        return (<></>);
      }
      else if (cellProps.field === 'securityName') {
        if (cellProps.dataItem.field === 'MaturityDate' || cellProps.dataItem.field === 'CallDate1') {
          if (cellProps.dataItem.value) {
            return (
              <td colSpan='1' style={{ backgroundColor: color }}>
                <strong>{this.formatDateString(cellProps.dataItem.value)} Totals:</strong>
              </td>
            );
          }
          else {
            return (
              <td colSpan='1' style={{ backgroundColor: color }}>
                <strong>Totals:</strong>
              </td>
            );
          }
        }
        else {
          return (
            <td colSpan='1' style={{ backgroundColor: color }}>
              <strong>{cellProps.dataItem.value} Totals:</strong>
            </td>
          );
        }

      }
      if (cellProps.dataIndex === -1 && cellProps.field !== 'value') {
        return (
          <td colSpan='1' style={{ backgroundColor: color }}>
            <></>
          </td>
        );
      }
    }
    if (tdElement !== null) {
      var style = { backgroundColor: this.state.styleSettings.fiHoldingsDataRowColor };
      if (cellProps.field === 'acqDate') {
        var acqDate = new Date(cellProps.dataItem[cellProps.field])
        if (acqDate.getUTCFullYear() === 1901) {
          return (
            <td {...tdElement.props} style={style}> </td>
          );
        }
      }
      else if (cellProps.field === 'quantity' && cellProps.dataItem[cellProps.field] === 0) {
        return (
          <td {...tdElement.props} style={style}> </td>
        );
      }
      if (cellProps.field === 'quantity' || cellProps.field === 'marketValue' || cellProps.field === 'percentage'
        || cellProps.field === 'totalCost' || cellProps.field === 'unrealized' || cellProps.field === 'percentUnrealized' || cellProps.field === 'yield'
        || cellProps.field === 'estAnnIncome' || cellProps.field === 'appMarketPrice' || cellProps.field === 'unitCost')
        style = { textAlign: 'right', backgroundColor: this.state.styleSettings.fiHoldingsDataRowColor };

      if (cellProps.field === 'percentUnrealized'){
        if (!cellProps.dataItem.totalCost || cellProps.dataItem.totalCost === 0){
          return (
            <td {...tdElement.props} style={style} >{''}</td>
          );
        }
      }

      return (
        <td {...tdElement.props} style={style} />
      );
    }
    else {
      return tdElement;
    }
  }

  headerCellRender(thElement, cellProps) {
    if (thElement !== null) {
      var style = {};
      if (cellProps.field === 'quantity' || cellProps.field === 'marketValue' || cellProps.field === 'percentage'
        || cellProps.field === 'totalCost' || cellProps.field === 'unrealized' || cellProps.field === 'percentUnrealized' || cellProps.field === 'yield'
        || cellProps.field === 'estAnnIncome' || cellProps.field === 'appMarketPrice' || cellProps.field === 'unitCost')
        style = { justifyContent: 'right' };

      return (
        <div {...thElement.props} style={style} />
      );
    }
    else {
      return thElement;
    }
  }

  expandChange = (event) => {
    const expandField = event.target.props.expandField;
    const itemId = event.dataItem.id;

    const updateItemsRecursive = (items) => {
      return items.map(item => {
        let updatedItem = item;
        if (item.id === itemId) {
          updatedItem = {
            ...item,
            [expandField]: !item[expandField],
          };
        }
        if (Array.isArray(item.items)) updatedItem.items = updateItemsRecursive(item.items);
        return updatedItem;
      });
    };

    const updatedData = updateItemsRecursive(this.state.result.data);

    this.setState((prevState) => ({
      ...prevState,
      result: {
        ...prevState.result,
        data: updatedData
      }
    }));
  }

  addPropertyToItems = (obj) => {
    // Usage: (call in both initial data set and prior to any datagrid result state change)
    // result = this.addPropertyToItems(result);

    const addPropertyToNestedItems = (item) => {
      item.id = Math.random();
      item.expanded = true;

      // Recursively call this function for each item in the 'items' array
      if (Array.isArray(item.items)) item.items.forEach(subItem => addPropertyToNestedItems(subItem));
      return item;
    }

    // Traverse the array and add properties
    if (Array.isArray(obj.data)) obj.data.forEach(item => addPropertyToNestedItems(item));
    return obj;
  }

  expand = () => {
    const newData = this.state.result.data.map(row => {
      const newRow = { ...row };
      newRow.expanded = true;
      newRow.items = this.expandItems(newRow.items);
      return newRow;
    });
    this.setState(prevState => ({
      result: {
        ...prevState.result,
        data: newData
      }
    }));
  };

  expandItems = (items) => {
    if (!items) return [];
    return items.map(item => {
      const newItem = { ...item };
      newItem.expanded = true;
      newItem.items = this.expandItems(newItem.items);
      return newItem;
    });
  };

  expandCollapse = (field) => {
    const newData = this.state.result.data.map(row =>
      this.expandCollapseItems(row, field)
    );
    this.setState(prevState => ({
      result: {
        ...prevState.result,
        data: newData
      }
    }));
  };

  expandCollapseItems = (item, field) => {
    const newItem = { ...item };
    if (!field || field === newItem.field) {
      newItem.expanded = !newItem.expanded;
    }
    if (newItem.items) {
      newItem.items = newItem.items.map(child => this.expandCollapseItems(child, field));
    }
    return newItem;
  };




}

export default FixedIncomeAccountHoldings;